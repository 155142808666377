/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_attraction from "../../api/attractions";
import api_supplier from "../../api/supplier";

// initial state
// shape: [{ supplier }]
const state = {
  attraction_list: [],
  scheduling_list: [],
  supplier: undefined,
};

// getters
const getters = {
  attraction_list(state) {
    return state.attraction_list;
  },
  scheduling_list(state) {
    return state.scheduling_list;
  },
};

// actions
const actions = {
  async load_attraction_list({ commit }, supplier_id) {
    try {
      commit("error/clear", undefined, { root: true });
      const attraction_list = await api_attraction.list(supplier_id);
      commit("attraction_list", attraction_list);
      return attraction_list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async schedule_list({ commit }, { offset, limit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const scheduling_list = await api_attraction.scheduling_list(
        offset,
        limit
      );
      commit("scheduling_list", scheduling_list);
      return scheduling_list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async schedule_destroy({ commit }, attraction) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_attraction.destroy(attraction);
      commit("scheduling_destroy", attraction);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async schedule_attraction({ commit }, attraction) {
    try {
      commit("error/clear", undefined, { root: true });
      const result = await api_attraction.scheduling(attraction);
      commit("attraction_schedule", result);
    } catch (error) {
      if (error.status === 422) {
        return error.data.errors;
      }
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_item({ commit }, supplier_id) {
    try {
      commit("error/clear", undefined, { root: true });
      const supplier = await api_supplier.supplier_get(supplier_id);
      commit("supplier", supplier);
      return supplier;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  attraction_schedule(state, item) {
    const source = state.attraction_list;
    switch (item.command) {
      case "delete":
        source.splice(source.indexOf(item), 1);
        break;
      case "create":
        source.unshift(item);
        break;
      case "update":
        source.splice(source.indexOf(item), 1, item);
        break;
    }
  },
  attraction_list(state, list) {
    state.attraction_list = list;
  },
  scheduling_list(state, list) {
    state.scheduling_list = list;
  },
  scheduling_destroy(state, item) {
    const source = state.scheduling_list;
    source.splice(source.indexOf(item), 1);
  },
  supplier(state, supplier) {
    state.supplier = supplier;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
