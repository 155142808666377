/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_payment from "../../api/payment";

// initial state
// shape: [{ supplier }]
const state = {
  payment_list: [],
  list_meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 100,
    to: 15,
    total: 0,
  },
};

// getters
const getters = {
  list_meta(state) {
    return state.list_meta;
  },
  list(state) {
    return state.payment_list;
  },
};

// actions
const actions = {
  async load_list({ commit }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      const payment_list = await api_payment.payment_list(params);
      commit("payment_list", payment_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_payment({ commit }, payment) {
    try {
      commit("error/clear", undefined, { root: true });
      const updated = await api_payment.update_payment(payment);
      commit("update_payment", updated);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  update_payment(state, data) {
    state.document_list.splice(
      state.document_list.findIndex((payment) => payment.id === data.id),
      1,
      data
    );
  },
  payment_list(state, list) {
    state.payment_list = list.data;
    state.list_meta = list.meta;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
