<template>
  <div class="container">
    <h1>{{ $t("Coupons import history") }}</h1>
    <BreadCrumbs />
    <SpinnerBrand v-if="state.processing" centered />
    <template v-else>
      <ErrorBanner :message="state.error" />
      <SimpleTable
        :headers="TABLE_HEADERS"
        :cells="TABLE_CELLS"
        :items="state.importHistoryList"
        :lazy-load-function="lazyLoad"
        lazy-cells="coupons_used"
        show-delete-button
        :processing-items="state.processingDeletion"
        @delete="deleteImportHistory"
      />
    </template>
  </div>
</template>

<script>
import { reactive, onBeforeMount } from "@vue/composition-api";

import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import router from "@/router";
import request from "@/helpers/request";
import URLS from "@/config/urls";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import dateToLocaleString from "@/helpers/date/dateToLocaleString";

const TABLE_HEADERS = ["First Coupon", "Amount", "Used Amount", "Date", "IP-address", "Uploaded By"];
const TABLE_CELLS = ["first_coupon", "coupons_amount", "coupons_used", "uploaded_at", "ip", "uploaded_by"];

export default {
  components: {
    BreadCrumbs,
    ErrorBanner,
    SimpleTable,
    SpinnerBrand,
  },

  setup() {
    const state = reactive({
      processing: false,
      processingDeletion: [],

      error: null,

      importHistoryList: [],
    });

    function onRequestError(xhrError) {
      const { errorMessage } = prepareAxiosErrors(xhrError);
      state.error = errorMessage;
    }

    function deleteHistoryFromList(importHistoryId) {
      state.importHistoryList = state.importHistoryList.filter(({ id }) => id !== importHistoryId);
    }

    async function getImportsHistory() {
      const { couponsGroupId } = router.currentRoute.params;
      const url = URLS.coupons.groups.importHistory.list(couponsGroupId);

      state.processing = true;
      state.error = null;
      state.importHistoryList = [];

      try {
        const result = await request(url);

        state.importHistoryList = result.map(({ uploaded_at, ...item }) => ({
          ...item,
          uploaded_at: dateToLocaleString(uploaded_at),
        }));
      } catch (xhrError) {
        onRequestError(xhrError);
      }

      state.processing = false;
    }

    async function lazyLoad(field, item) {
      if (field === "coupons_used") {
        const url = URLS.coupons.groups.getUsedCouponsCount(item.id);
        const result = await request(url);
        const newImportHistoryList = Array.from(state.importHistoryList);
        const updatedItem = newImportHistoryList.find(({ id }) => id === item.id);

        if (updatedItem) {
          updatedItem.coupons_used = result;
        }

        state.importHistoryList = newImportHistoryList;
      }
    }

    async function deleteImportHistory(importHistoryId) {
      const url = URLS.coupons.groups.importHistory.delete(importHistoryId);

      state.processingDeletion.push(importHistoryId);
      state.error = null;

      try {
        await request({
          url,
          method: "DELETE",
        });

        deleteHistoryFromList(importHistoryId);
      } catch (xhrError) {
        onRequestError(xhrError);
      }

      state.processingDeletion = state.processingDeletion.filter((id) => id !== importHistoryId);
    }

    onBeforeMount(() => {
      getImportsHistory();
    });

    return {
      TABLE_HEADERS,
      TABLE_CELLS,

      state,

      lazyLoad,
      deleteImportHistory,
    };
  },
};
</script>
