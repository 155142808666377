<template>
  <small-layout>
    <div>
      <Clients></Clients>
      <AuthorizedClients></AuthorizedClients>
      <PersonalAccessTokens></PersonalAccessTokens>
    </div>
  </small-layout>
</template>

<script>
import Clients from "./Clients";
import AuthorizedClients from "./AuthorizedClients";
import PersonalAccessTokens from "./PersonalAccessTokens";

export default {
  components: {
    Clients: Clients,
    AuthorizedClients: AuthorizedClients,
    PersonalAccessTokens: PersonalAccessTokens,
  },
};
</script>
