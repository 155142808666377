/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_file from "../../api/supplierfile";
import { isEmpty } from "../../middleware/functions";

// initial state
// shape: [{ supplier }]
const state = {
  supplier_id: 0,
  file_list: [],
  file_list_meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 100,
    to: 15,
    total: 0,
  },
};

// getters
const getters = {
  file_list_meta(state) {
    return state.file_list_meta;
  },
  file_list(state) {
    return state.file_list;
  },
  supplier_id(state) {
    return state.supplier_id;
  },
};

// actions
const actions = {
  async update_file({ commit }, file) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await api_file.file_update(file);
      commit("file_replace", data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete_file({ commit }, { file, force_delete }) {
    try {
      await api_file.file_delete(file.supplier_id, file.id, force_delete);
      commit("file_delete", file.id);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async create_file({ commit, state }, { supplier_id, formData }) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await api_file.file_add(supplier_id, formData);
      commit("file_add", data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async get_file_list_by_supplier({ commit }, { supplier_id, page }) {
    if (state.supplier_id === supplier_id) {
      return state.file_list;
    }
    commit("file_list", { data: [], meta: state.file_list_meta });
    if (isEmpty(state.file_list)) {
      commit("file_list_cache", { supplier_id });
    }
    if (isEmpty(state.file_list)) {
      await actions.load_file_list_by_supplier(
        { commit },
        { supplier_id, page }
      );
    }
  },
  async load_file_list_by_supplier({ commit }, { supplier_id, page }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file_list = await api_file.file_list_by_supplier(supplier_id, page);
      commit("file_list", file_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  file_replace(state, new_file) {
    let file = state.file_list.find((file) => file.id === new_file.id);
    Object.assign(file, new_file);
    localStorage.setItem(
      "app_supplier_file_" + state.supplier_id,
      JSON.stringify(state.file_list)
    );
  },
  file_delete(state, id) {
    state.file_list.splice(
      state.file_list.findIndex((file) => file.id === id),
      1
    );
    localStorage.setItem(
      "app_supplier_file_" + state.supplier_id,
      JSON.stringify(state.file_list)
    );
  },
  file_add(state, file) {
    state.file_list.push(file);
    localStorage.setItem(
      "app_supplier_file_" + state.supplier_id,
      JSON.stringify(state.file_list)
    );
  },
  file_list_cache(state, { supplier_id }) {
    state.supplier_id = supplier_id;
    const item = localStorage.getItem("app_supplier_file_" + supplier_id);
    if (item) {
      state.file_list = JSON.parse(item);
    }
  },
  file_list(state, list) {
    state.file_list = list.data;
    state.file_list_meta = list.meta;
    localStorage.setItem(
      "app_supplier_file_" + state.supplier_id,
      JSON.stringify(list.data)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
