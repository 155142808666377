/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_message from "../../api/message";

// initial state
const state = {
  messages: [],
};

// getters
const getters = {
  get_message_list(state) {
    return state.messages;
  },
};

// actions
const actions = {
  async store({ commit }, item) {
    try {
      commit("error/clear", undefined, { root: true });
      const message = await api_message.store(item);
      commit("add_message", message);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async get_message_list({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const messages = await api_message.get_message_list();
      commit("messages", messages);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async queue_message_for_all_subsribers({ commit }, id) {
    try {
      commit("error/clear", undefined, { root: true });
      const message = await api_message.queue_message_subscribers(id);
      commit("error/set_error", message, { root: true });
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  add_message(state, item) {
    state.messages.push(item);
  },
  messages(state, list) {
    state.messages = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
