/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_lock_list from "../../api/lock_list";

// initial state
const state = {
  lock_items: [],
};

// getters
const getters = {
  get_lock_list(state) {
    return state.lock_items;
  },
};

// actions
const actions = {
  async store({ commit }, item) {
    try {
      commit("error/clear", undefined, { root: true });
      const lock_item = await api_lock_list.store(item);
      commit("add_lock_item", lock_item);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async get_lock_list({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const lock_items = await api_lock_list.get_lock_list();
      commit("lock_items", lock_items);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete({ commit }, id) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_lock_list.delete(id);
      commit("remove_lock_item", id);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  remove_lock_item(state, id) {
    state.lock_items.splice(
      state.lock_items.findIndex((item) => item.id === id),
      1
    );
  },
  add_lock_item(state, item) {
    state.lock_items.push(item);
  },
  lock_items(state, list) {
    state.lock_items = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
