<template>
  <small-layout>
    <div class="bounds_container">
      <h1>{{ $t("map.title") }}</h1>
      <div class="map-container">
        <div id="map" :style="mapStyle"></div>
        <RadiusSelector v-if="state.showRadiusSelector" v-model="state.visit.radius" class="map-radius-selector" />
      </div>
      <div class="icons_container">
        <Icon ref="visitMarkerRef" icon="visit-marker" :fill="visitMarkerColor" height="34px" width="24px" />
      </div>
    </div>
  </small-layout>
</template>

<script>
import { computed, reactive, ref, onMounted, unref, watch } from "@vue/composition-api";
import mapboxgl from "!mapbox-gl";
import debounce from "lodash/debounce";

import RadiusSelector from "./RadiusSelector.vue";

import store from "@/store";
import router from "@/router";
import useMiddlePoint from "@/composables/map/useMiddlePoint";
import createGeoJSONCircle from "@/helpers/map/createGeoJSONCircle";
import { kmToMeters, metersToKM } from "@/helpers/map/convertDistance";
import { HELPDESK_ACTION_VIEW_VISIT, HELPDESK_ACTION_UPDATE_VISIT_RADIUS } from "@/store/modules/helpdesk";
import { MAPBOX_MAP_STYLES } from "@/config/map";

import "mapbox-gl/dist/mapbox-gl.css";
import Icon from "../icons/Icon.vue";

const RADIUS_AUTOSAVE_TIMEOUT = 1000;
const MAP = {
  size: {
    width: "100%",
    height: "640px",
  },
};

export default {
  components: {
    RadiusSelector,
    Icon,
  },

  setup() {
    const visitMarkerRef = ref(null);
    const isFakeGeoIcon = ref(0); //1 if it is fake;

    const supplierCoordinates = ref([]);
    const visitCoordinates = ref([]);
    const state = reactive({
      map: null,
      showRadiusSelector: false,

      visit: {
        popup: null,
        marker: null,

        radius: null,
      },
    });

    const visitMarkerColor = computed(() => (isFakeGeoIcon.value ? "#D63230" : "#0038FF"));

    const visitRadius = computed(() => state.visit.radius);
    const visitCoordinatesReversed = computed(() => visitCoordinates.value.slice().reverse());

    const mapStyle = MAP.size;

    function initMapboxGl() {
      initMap();
      initSupplierMarker();
      initVisitMarker();
      initVisitPopup();
      initMapNavigationControl();
    }

    function initMap() {
      const { middlePoint } = useMiddlePoint(supplierCoordinates, visitCoordinates);
      const mapCenter = unref(middlePoint);

      state.map = new mapboxgl.Map({
        accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        center: mapCenter,
        container: "map",
        scrollZoom: false,
        style: MAPBOX_MAP_STYLES.streets,
        testMode: process.env.NODE_ENV === "development",
        zoom: getZoom(),
      });

      state.map.on("load", drawRadius);
    }

    function initMapNavigationControl() {
      const navControl = new mapboxgl.NavigationControl({
        showZoom: true,
        showCompass: false,
      });

      state.map.addControl(navControl);
    }

    function initSupplierMarker() {
      const supplierMarkerCoords = supplierCoordinates.value.map(Number).reverse();
      const markerConfig = { color: "black" };

      new mapboxgl.Marker(markerConfig).setLngLat(supplierMarkerCoords).addTo(state.map);
    }

    function initVisitMarker() {
      const visitMarkerCoords = visitCoordinates.value.map(Number).reverse();
      const markerConfig = {
        element: visitMarkerRef.value.$el,
        anchor: "bottom",
      };

      state.visit.marker = new mapboxgl.Marker(markerConfig).setLngLat(visitMarkerCoords).addTo(state.map);
    }

    function initVisitPopup() {
      state.visit.popup = new mapboxgl.Popup({
        focusAfterOpen: false,
      });

      state.visit.marker.setPopup(state.visit.popup);
      state.visit.marker.getElement().classList.add("mapbox-marker-hoverable");

      state.visit.popup.on("open", () => {
        state.showRadiusSelector = true;
      });

      state.visit.popup.on("close", () => {
        state.showRadiusSelector = false;
      });
    }

    function saveVisitRadius(newVal, oldVal) {
      if (state.map) {
        updateRadius();

        if (oldVal !== null) {
          const visitId = router.currentRoute.params.visit_id;
          saveVisitRadiusToBackend(visitId);
        }
      }
    }

    const saveVisitRadiusToBackend = debounce((visitId) => {
      store.dispatch(HELPDESK_ACTION_UPDATE_VISIT_RADIUS, {
        id: visitId,
        radius: kmToMeters(state.visit.radius),
      });
    }, RADIUS_AUTOSAVE_TIMEOUT);

    function drawRadius() {
      state.map.addSource("polygon", createGeoJSONCircle(visitCoordinatesReversed.value, state.visit.radius));
      state.map.addLayer({
        id: "polygon",
        type: "fill",
        source: "polygon",
        layout: {},
        paint: {
          "fill-color": "rgba(0, 56, 255, 0.5)",
        },
      });
    }

    function updateRadius() {
      const polygonSource = state.map.getSource("polygon");

      if (polygonSource) {
        const geoJSONCircle = createGeoJSONCircle(visitCoordinatesReversed.value, state.visit.radius);

        polygonSource.setData(geoJSONCircle.data);
      }
    }

    function getZoom() {
      const ZOOM_MAX = 21;

      function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(fraction) {
        return Math.floor(Math.log(1 / fraction) / Math.LN2);
      }

      const latFraction = Math.abs(
        (latRad(supplierCoordinates.value[0]) - latRad(visitCoordinates.value[0])) / Math.PI
      );
      const lngFraction = Math.abs(
        (latRad(supplierCoordinates.value[1]) - latRad(visitCoordinates.value[1])) / Math.PI
      );

      const latZoom = zoom(latFraction);
      const lngZoom = zoom(lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
    }

    onMounted(async () => {
      const visitId = router.currentRoute.params.visit_id;
      const result = await store.dispatch(HELPDESK_ACTION_VIEW_VISIT, visitId);

      supplierCoordinates.value = result.supplier_center.map(Number);
      // Need to slice result.point array, because last item in array is a fake_geo flag
      visitCoordinates.value = result.point.slice(0, 2).map(Number);
      isFakeGeoIcon.value = result.point[2];
      state.visit.radius = metersToKM(result.user_radius) || 0;

      initMapboxGl();
    });

    watch(visitRadius, saveVisitRadius);

    return {
      mapStyle,
      state,
      visitMarkerRef,

      visitMarkerColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.bounds_container {
  height: auto;
  overflow: hidden;
  padding-bottom: 48px;

  .icons_container {
    display: none;
  }

  .map-container {
    position: relative;

    .map-radius-selector {
      position: absolute;
      bottom: 10%;
      right: 10%;
    }
  }
}
</style>

<style lang="scss">
#map {
  position: relative;

  .mapbox-marker-hoverable {
    cursor: pointer;
  }
}
</style>
