<template>
  <jet-form-section>
    <template #title> Ticket Information </template>

    <template #description> Update ticket if wrong code were added </template>

    <template #form>
      <!-- Ticket Name and Level -->
      <div class="col-span-6">
        <jet-label value="Ticket" />

        <!-- Value -->
        <div
          v-for="(callable, name) in {
            value: (t) => t.value,
            name: (t) => (t.supplier ? t.supplier.name : ''),
            activated: (t) => (t.supplier && t.supplier.activated ? 'ON' : 'OFF'),
          }"
          class="col-span-6"
        >
          <jet-label :value="name" />

          <div class="flex items-center mt-2">
            <div class="ml-4 leading-tight">
              <div>{{ callable(ticket) }}</div>
            </div>
          </div>
        </div>
        <!-- Holder raw -->
        <div class="col-span-6">
          <jet-label value="Raw Holder and When registered manually" />

          <div class="col-span-6 sm:col-span-4">
            <jet-label for="holder_name" value="Holder Name" />
            <jet-input id="holder_name" type="text" class="mt-1 block w-full" v-model="form.holder_name" autofocus />
            <jet-input-error :message="form.errors.holder_name" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <jet-label for="registered_at" value="Registered At" />
            <jet-input
              id="registered_at"
              type="date"
              class="mt-1 block w-full"
              v-model="form.registered_at"
              autofocus
            />
            <jet-input-error :message="form.errors.registered_at" class="mt-2" />
            <div class="text-gray-700 text-sm">{{ form.registered_at }}</div>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <jet-label for="force_lock" value="Save anyway" />
            <jet-checkbox id="force_lock" class="mt-1 block w-full" v-model="form.force_lock" />
            <jet-input-error :message="form.errors.force_lock" class="mt-2" />
          </div>
        </div>
        <!-- Holder -->
        <div class="col-span-6" v-if="ticket.holder">
          <jet-label value="Holder, Level and Phone" />

          <div class="flex items-center mt-2">
            <div class="ml-4 leading-tight">
              <div>{{ ticket.holder.name }}</div>
              <div class="text-gray-700 text-sm">{{ ticket.holder.level }}</div>
              <div class="text-gray-700 text-sm">{{ ticket.holder.phone }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <jet-action-message :on="form.recentlySuccessful" class="mr-3"> Saved. </jet-action-message>
      <jet-button
        @click="updateTicketInformation"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Save
      </jet-button>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetCheckbox from "../../Jetstream/Checkbox";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import JetActionMessage from "../../Jetstream/ActionMessage";
import JetSecondaryButton from "../../Jetstream/SecondaryButton";
import { mapActions } from "vuex";

export default {
  components: {
    JetActionMessage,
    JetButton,
    JetFormSection,
    JetInput,
    JetCheckbox,
    JetInputError,
    JetLabel,
    JetSecondaryButton,
  },

  props: ["ticket"],

  data() {
    return {
      form: {
        id: this.ticket.id,
        value: this.ticket.value,
        attraction_id: this.ticket.attraction_id,
        holder_name: this.ticket.holder_name
          ? this.ticket.holder_name
          : this.ticket.holder
          ? this.ticket.holder.name
          : "",
        registered_at: this.ticket.registered_at,
        force_lock: false,
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      updateTicket: "ticket/update_ticket",
    }),
    async updateTicketInformation() {
      const output = await this.updateTicket(this.form);
      if (output) {
        this.form.errors = output;
      } else {
        this.$router.push({ name: "holder_ticket_list", query: { value: this.form.value } });
      }
    },
  },
};
</script>
