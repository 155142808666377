function copyToClipboard({ value }) {
  navigator.clipboard.writeText(value);
}

function addListener(el, binding) {
  if (navigator?.clipboard?.writeText) {
    removeListener(el);
    el.clipboardClickHandler = copyToClipboard.bind(null, binding);
    el.addEventListener("click", el.clipboardClickHandler);
  }
}

function removeListener(el) {
  if (el.clipboardClickHandler) {
    el.removeEventListener("click", el.clipboardClickHandler);
  }
}

export default {
  bind: addListener,
  update: addListener,
  unbind: removeListener,
};
