<template>
  <div class="container">
    <div class="row-header">
      <h1>Transactions</h1>
      <Button v-if="isDataLoaded" @click="openAddTransactionModal()">
        {{ $t("Add transaction") }}
      </Button>
    </div>
    <ErrorBanner :message="errorMessage" />
    <SpinnerBrand v-if="processing" centered />
    <div v-if="isDataLoaded" class="content">
      <DatePickerInput
        v-model="searchState.date"
        label="Date:"
        class="datepicker"
        placeholder="yyyy-mm-dd"
        type="day"
        :clearable="false"
      />

      <SimpleTable
        :cells="TABLE_CELLS"
        :headers="TABLE_HEADERS"
        :items="countriesList"
        :actions="TABLE_ACTIONS"
        show-actions-button
        @open-additional-modal="openAdditionalInfoModal()"
        @open-link-transaction-modal="openLinkTransactionModal()"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import { ROUTES_NAMES } from "@/router";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";

const TABLE_HEADERS = ["ID", "Date", "Seller", "Debet", "Credit", "Entity", "UNN", "Banking System"];

//TODO: Rewrite this array with actual transaction's object fields(when API will be changed)
const TABLE_CELLS = [
  "id",
  "brand_name",
  "aggregator",
  "debet1",
  "credit1",
  "correspondent_name",
  "correspondent_unn",
  "types_of_subscription",
];

const TABLE_ACTIONS = [
  {
    title: "Additionally",
    key: "open-additional-modal",
    icon: {
      type: "additional",
      color: "#346AED",
    },
  },
  {
    title: "Link",
    key: "open-link-transaction-modal",
    icon: {
      type: "small-chain",
      color: "#346AED",
    },
  },
];

export default {
  components: {
    DatePickerInput,
    Button,
    ErrorBanner,
    SimpleTable,
    SpinnerBrand,
  },

  setup() {
    const {
      data: countriesList,
      processing,
      errorMessage,
    } = useRequest({
      url: URLS.transactions.list,
      hook: "onBeforeMount",
      defaultValue: [],
    });

    const modalsState = reactive({
      showAddTransaction: false,
      showAdditional: false,
      showLinkTransaction: false,
    });

    const searchState = reactive({
      date: getCurrentDate(),
    });

    const isDataLoaded = computed(() => !processing.value && !errorMessage.value);

    function getCurrentDate() {
      const dateObj = new Date();
      const year = dateObj.getFullYear();
      const month = `${dateObj.getMonth() + 1}`.padStart(2, "0");
      const day = `${dateObj.getDate()}`.padStart(2, "0");
      const currentDate = `${year}-${month}-${day}`;
      return currentDate;
    }

    function openAddTransactionModal() {
      modalsState.showAddTransaction = true;
    }

    function openAdditionalInfoModal() {
      modalsState.showAdditional = true;
    }

    function openLinkTransactionModal() {
      modalsState.showAdditional = true;
    }

    return {
      TABLE_CELLS,
      TABLE_HEADERS,
      TABLE_ACTIONS,
      ROUTES_NAMES,

      searchState,

      countriesList,
      errorMessage,
      processing,

      isDataLoaded,

      openAddTransactionModal,
      openAdditionalInfoModal,
      openLinkTransactionModal,
    };
  },
};
</script>

<style lang="scss">
.content {
  .datepicker {
    width: 178px;
    margin-bottom: 6px;
  }
}
</style>
