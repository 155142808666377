<template>
  <div class="container">
    <div class="row-header">
      <h1>Companies</h1>
      <Button @click="openCompanyAddView">Create Company</Button>
    </div>
    <ErrorBanner :message="errorMessage" />
    <SpinnerBrand v-if="processingMethods.GET" centered />
    <div v-if="isDataLoaded" class="content">
      <div class="controls">
        <NewSelectInput
          v-model="state.searchManager"
          class="search"
          label="Manager"
          placeholder="Select manager"
          :options="managersOptions"
        />
        <NewSelectInput
          v-model="selectedCompanyType"
          class="search"
          label="Company type"
          placeholder="Select company type"
          :options="preparedCompanyTypes"
        />
        <BaseInput v-model="state.searchValue" class="search" placeholder="Search" action-icon="search" />
      </div>
      <SimpleTable
        :headers="TABLE_HEADERS"
        :cells="TABLE_CELLS"
        :actions="TABLE_ACTIONS"
        :items="filteredCompaniesList"
        show-actions-button
        @edit="openCompanyEditView"
        @portal-users="openPortalUsersView"
        @delete="openCompanyDeleteModal"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "@vue/composition-api";
import isFunction from "lodash/isFunction";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import { MODAL_ACTION_OPEN, MODAL_TYPES } from "@/store/modules/modal";
import { ROUTES_NAMES } from "@/router";
import openRouteByName from "@/helpers/router/openRouteByName";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";

import { COMPANY_TYPES } from "@/config";

const TABLE_HEADERS = ["ID", "Name", "Manager", COMPANY_TYPES.key, "City"];
const TABLE_CELLS = ["id", "name", ({ manager }) => manager?.name || "–", COMPANY_TYPES.key, "city"];
const TABLE_ACTIONS = [
  {
    title: "Edit",
    key: "edit",
    icon: {
      type: "edit",
      color: "#79C99E",
    },
  },
  {
    title: "Portal Users",
    key: "portal-users",
    icon: {
      type: "people",
      color: "#346AED",
    },
  },
  {
    title: "Delete",
    key: "delete",
    icon: {
      type: "delete",
      color: "#E3001B",
    },
  },
];

export default {
  components: {
    BaseInput,
    Button,
    ErrorBanner,
    NewSelectInput,
    SimpleTable,
    SpinnerBrand,
  },

  setup() {
    const {
      data: companiesList,
      errorMessage,
      fetch,
      processingMethods,
      setData,
    } = useRequest({
      url: URLS.companies.index,
      hook: "onBeforeMount",
      defaultValue: [],
    });

    const state = reactive({
      searchValue: "",
      searchManager: null,
    });

    const selectedCompanyType = ref(null);
    const preparedCompanyTypes = computed(() => {
      return [
        { text: "All types", value: null },
        ...Object.values(COMPANY_TYPES.value).map((item) => ({
          text: item,
          value: item,
        })),
      ];
    });

    const filteredCompaniesList = computed(() => {
      let filteredCompaniesList = companiesList.value;

      if (state.searchValue.length > 0) {
        const isSatisfiesSearchCondition = (company) => {
          const companyFieldsList = TABLE_CELLS.map((tableCell) =>
            isFunction(tableCell) ? tableCell(company) : company[tableCell]
          );
          const fullCompanyInfo = companyFieldsList.join(" ").toLowerCase();

          return fullCompanyInfo.includes(state.searchValue.toLowerCase());
        };

        filteredCompaniesList = filteredCompaniesList.filter(isSatisfiesSearchCondition);
      }

      if (state.searchManager) {
        filteredCompaniesList = filteredCompaniesList.filter(({ manager }) => manager?.name === state.searchManager);
      }

      if (selectedCompanyType.value) {
        const { b2b, b2c, copay } = COMPANY_TYPES.value;

        filteredCompaniesList = filteredCompaniesList.filter(({ registration_type, compensation_amount }) => {
          switch (selectedCompanyType.value) {
            case b2b:
              return registration_type === 0;
            case copay:
              return registration_type === 1 && compensation_amount > 0;
            case b2c:
              return registration_type === 1 && !compensation_amount;
            default:
              return false;
          }
        });
      }

      return filteredCompaniesList;
    });

    const managersOptions = computed(() => {
      const managersList = [];

      companiesList.value.forEach(({ manager }) => {
        if (manager?.name && !managersList.includes(manager.name)) {
          managersList.push(manager.name);
        }
      });

      const managersOptions = managersList.map((v) => ({ text: v, value: v }));
      const allManagersOption = { text: "All managers", value: null };

      return [allManagersOption, ...managersOptions];
    });

    const isDataLoaded = computed(() => companiesList.value.length > 0);

    function openCompanyAddView() {
      openRouteByName(ROUTES_NAMES.companies.add);
    }

    function openCompanyEditView(id) {
      openRouteByName(ROUTES_NAMES.companies.edit, { params: { companyId: id } });
    }

    function openPortalUsersView(id) {
      openRouteByName(ROUTES_NAMES.companies.portalUsers, { params: { companyId: id } });
    }

    function openCompanyDeleteModal(id) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.action,
        payload: {
          onAccept: deleteCompany.bind(null, id),
        },
      });
    }

    async function deleteCompany(companyId) {
      const { error } = await fetch({
        url: URLS.companies.single(companyId),
        method: "DELETE",
        omitData: true,
      });

      if (!error) {
        const newCompaniesList = companiesList.value.filter(({ id }) => id != companyId);
        setData(newCompaniesList);
      }
    }

    return {
      TABLE_HEADERS,
      TABLE_CELLS,
      TABLE_ACTIONS,

      processingMethods,
      errorMessage,

      state,

      filteredCompaniesList,
      isDataLoaded,
      managersOptions,

      openCompanyAddView,
      openCompanyEditView,
      openPortalUsersView,
      openCompanyDeleteModal,

      selectedCompanyType,
      preparedCompanyTypes,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  .controls {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 22px;

    .search {
      width: 278px;
    }
  }
}
</style>
