import Vue from "vue";
import Vuex from "vuex";

import createLogger from "vuex/dist/logger";

import affiliates_update from "./modules/affiliates_update";
import attraction from "./modules/attraction";
import audit from "./modules/audit";
import auth from "./modules/auth";
import card from "./modules/card";
import company from "./modules/company";
import company_client from "./modules/company_client";
import companycard from "./modules/companycard";
import config from "./modules/config";
import control_new_month from "./modules/control_new_month";
import coupons from "./modules/coupons";
import error from "./modules/error";
import helpdesk from "./modules/helpdesk";
import lock_list from "./modules/lock_list";
import mail from "./modules/mail";
import message from "./modules/message";
import modal from "./modules/modal";
import orders from "./modules/orders";
import payment from "./modules/payment";
import supplier from "./modules/supplier";
import supplieract from "./modules/supplieract";
import supplierfile from "./modules/supplierfile";
import suppliers from "./modules/suppliers";
import ticket from "./modules/ticket";
import token from "../module/token";
import user from "./modules/user";
import visit from "./modules/visit";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    affiliates_update,
    attraction,
    audit,
    auth,
    card,
    company_client,
    company,
    companycard,
    config,
    control_new_month,
    coupons,
    error,
    helpdesk,
    lock_list,
    mail,
    message,
    modal,
    orders,
    payment,
    supplier,
    supplieract,
    supplierfile,
    suppliers,
    ticket,
    token,
    user,
    visit,
  },
  strict: debug,
  plugins: [createLogger()],
});
