/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_card from "../../api/card";

// initial state
// shape: [{ supplier }]
const state = {
  card_list: [],
  claim_list: [],
};

// getters
const getters = {
  card_list(state) {
    return state.card_list;
  },
  claim_list(state) {
    return state.claim_list;
  },
};

// actions
const actions = {
  async load_claim_list_by_filter({ commit }, tags) {
    try {
      commit("error/clear", undefined, { root: true });
      const claim_list = await api_card.get_claim_list_by_filter(tags);
      commit("claim_list", claim_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_claim_list_by_filter({ commit }, { command, filter_at, data }) {
    try {
      commit("error/clear", undefined, { root: true });
      const claim_list = await api_card.update_claim_list_by_filter(
        command,
        filter_at,
        data
      );
      commit("claim_list", claim_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_card_list_by_company(
    { commit },
    { company_id, date, with_deleted }
  ) {
    try {
      commit("error/clear", undefined, { root: true });
      const card_list = await api_card.card_list_by_company(
        company_id,
        date,
        with_deleted
      );
      commit("card_list", card_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async patch_phone({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const phone = await api_card.phone_patch(data);
      if (!phone) {
        commit("phone_delete", data);
      } else {
        commit("phone_update", phone);
      }
      return phone;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_card({ commit }, { card }) {
    try {
      commit("error/clear", undefined, { root: true });
      const updated = await api_card.card_update(
        card.company_id,
        card.id,
        card
      );
      commit("card_update", updated);
      return updated;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete_card({ commit }, { company_id, card }) {
    try {
      commit("error/clear", undefined, { root: true });
      const deleted = await api_card.card_delete(card.id);
      commit("card_delete", card);
      return card;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async view_card({ commit }, { card }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_card.card_view(card.company_id, card.id);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async restore_card({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const card = await api_card.card_restore(data.id);
      data.deleted_at = null;
      commit("card_update", data);
      return card;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  card_list(state, list) {
    state.card_list = list;
  },
  card_delete(state, data) {
    state.card_list.splice(state.card_list.indexOf(data), 1);
  },
  bulk_card_add(state, bulk_data) {
    state.card_list.push.apply(state.card_list, bulk_data);
  },
  card_update(state, data) {
    state.card_list.splice(
      state.card_list.findIndex((card) => card.id === data.id),
      1,
      data
    );
  },
  claim_list(state, list) {
    state.claim_list = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
