<template>
  <jet-form-section>
    <template #title>
      <router-link :to="{ name: 'holder_ticket_stats' }">Explore statistics</router-link>
      Search Tickets
    </template>

    <template #description> Find tickets by value, taken status and holder name. </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="value" value="Ticket Value" />
        <jet-input id="value" type="text" class="mt-1 block w-full" v-model="form.value" autofocus />
        <jet-input-error :message="form.errors.value" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="attraction_id" value="Supplier with Tickets Only" />
        <select id="attraction_id" class="mt-1 block w-full" v-model="form.attraction_id" autofocus>
          <option value=""></option>
          <option v-for="attraction in attractions" :value="attraction.id">
            {{ attraction.supplier.name }}/{{ attraction.name }} {{ attraction.price }}
          </option>
        </select>
        <jet-input-error :message="form.errors.attraction_id" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="taken_at" value="Provided At" />
        <jet-input id="taken_at" type="date" class="mt-1 block w-full" v-model="form.taken_at" autofocus />
        <jet-input-error :message="form.errors.taken_at" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="holder" value="Ticket Holder" />
        <jet-input id="holder" type="text" class="mt-1 block w-full" v-model="form.holder" autofocus />
        <jet-input-error :message="form.errors.holder" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-button @click="filterTickets" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Find
      </jet-button>
      <jet-button @click="hide" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Hide
      </jet-button>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetCheckbox from "../../Jetstream/Checkbox";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetCheckbox,
  },

  computed: {
    ...mapGetters({
      attractions: "ticket/attractions",
    }),
  },

  data() {
    return {
      form: {
        value: "",
        attraction_id: "",
        taken_at: "",
        holder: "",
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      loadTickets: "ticket/load_tickets",
    }),
    async filterTickets() {
      const output = await this.loadTickets(this.form);
      if (output && output.errors) {
        this.form.errors = output.errors;
      }
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
