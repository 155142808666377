<template>
  <div class="local-search">
    <input
      v-model="searchValue"
      class="local-search_input"
      type="text"
      placeholder="Local Search here"
      @keyup.esc="clearSearchValue"
    />
    <div class="local-search_icon">
      <Icon icon="search" />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

import Icon from "@/components/icons/Icon.vue";

export default {
  emits: ["input"],

  components: {
    Icon,
  },

  props: {
    value: {
      type: String,
      required: false,
    },
  },

  setup(props, { emit }) {
    const searchValue = computed({
      get() {
        return props.value;
      },
      set(newVal) {
        emit("input", newVal);
      },
    });

    function clearSearchValue() {
      emit("input", "");
    }

    return {
      searchValue,

      clearSearchValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.local-search {
  position: relative;

  &_input {
    width: 380px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 0 40px 0 1em;
    font-weight: 300;
    font-size: 13px;

    &:focus,
    &:active {
      border-color: #000;

      & + .local-search_icon {
        opacity: 0.6;
        transition: opacity 0.3s linear;
      }
    }
  }

  &_icon {
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
