/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api from "../../api/company_client";

const state = {};

// getters
const getters = {};

// actions
const actions = {
  async list({ commit }, { company_id }) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api.index(company_id);
      return list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async upsert({ commit }, { company_id, clients }) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api.store(company_id, clients);
      return list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
