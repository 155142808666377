<template>
  <div class="container view-order">
    <div class="row-header">
      <h1>Order Invoices</h1>
      <Button
        v-if="isOrderInvoicesLoaded"
        :processing="state.creating"
        :disabled="createInvoiceButtonDisabled"
        @click="addInvoice"
      >
        Add Invoice
      </Button>
    </div>
    <p>Order date: {{ orderDate }}</p>
    <ErrorBanner :message="state.errors.errorMessage" />
    <SpinnerBrand v-if="state.fetching" centered />
    <div v-if="isOrderInvoicesLoaded" class="content">
      <SimpleTable
        v-model="state.selectedInvoice"
        selectable
        show-actions-button
        :actions="TABLE_ACTIONS"
        :headers="orderTableHeaders"
        :cells="orderTableCells"
        :items="invoicesList"
        :processing-items="state.deleting"
        @change="resetOrder"
        @delete="deleteInvoice"
        @download="downloadInvoice"
      >
        <template #footer>
          <tr>
            <td>Summary</td>
            <td v-for="(item, index) in orderSummary" :key="`order-summary-${index}`">
              {{ item }}
            </td>
          </tr>
        </template>
      </SimpleTable>
    </div>

    <OrderInvoiceDetails
      v-if="showInvoiceDetails"
      class="invoice"
      v-bind="orderInvoiceDetails"
      @reset="resetOrder"
      @select="selectInvoice"
    />
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount } from "@vue/composition-api";
import get from "lodash/get";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import OrderInvoiceDetails from "./components/OrderInvoiceDetails.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import store from "@/store";
import {
  NEW_ORDER_INVOICE_ID,
  ORDERS_ACTION_ADD_NEW_INVOICE,
  ORDERS_ACTION_DELETE_INVOICE,
  ORDERS_ACTION_GET_ORDER,
  ORDERS_ACTION_RESET_ORDER,
} from "@/store/modules/orders";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";
import URLS from "@/config/urls";
import formatCurrency from "@/helpers/formatters/formatCurrency";
import download from "@/helpers/request/download";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const TABLE_ACTIONS = [
  {
    title: "Скачать",
    key: "download",
    icon: {
      type: "download",
      color: "#0038FF",
    },
  },
  {
    title: "Удалить",
    key: "delete",
    confirmationRequired: true,
    icon: {
      type: "delete",
      color: "#E3001B",
    },
  },
];

export default {
  components: {
    Button,
    ErrorBanner,
    OrderInvoiceDetails,
    SimpleTable,
    SpinnerBrand,
  },

  setup() {
    const state = reactive({
      errors: {},

      creating: false,
      deleting: null,
      fetching: false,

      selectedInvoice: null,
    });

    const showInvoiceDetails = computed(() => state.selectedInvoice !== null);
    const invoices = computed(() => store.state.orders.order?.invoices || []);
    const orderDate = computed(() => {
      const filterAt = store.state.orders.order?.filter_at?.toString();

      if (!filterAt) return "";

      const year = filterAt.slice(0, 2);
      const month = filterAt.slice(2);
      return `${year}-${month}`;
    });
    const createInvoiceButtonDisabled = computed(() => invoices.value.some(({ id }) => id === NEW_ORDER_INVOICE_ID));
    const isOrderInvoicesLoaded = computed(() => !(state.fetching || state.errors.fetchingError));

    const orderInvoiceDetails = computed(() => ({
      invoice: state.selectedInvoice,
      pricingPolicies: pricingPolicies.value,
    }));

    const pricingPoliciesList = computed(() => {
      const pricingPoliciesList = invoices.value.reduce((list, invoice) => {
        const newPolicies = Object.keys(invoice.pricingPolicies);
        const fullPoliciesList = [...list, ...newPolicies];
        const uniqPoliciesList = [...new Set(fullPoliciesList)];
        return uniqPoliciesList;
      }, []);
      return pricingPoliciesList;
    });

    const pricingPolicies = computed(() => {
      const policies = {};
      const group = store.state.orders.pricingGroup || [];

      group.forEach((policy) => {
        policies[policy.key] = policy.value;
      });

      return policies;
    });

    const orderTableHeaders = computed(() => {
      const headers = ["ID"];

      pricingPoliciesList.value.forEach((policy) => {
        headers.push(pricingPolicies.value[policy] ?? policy);
        headers.push("Taxes");
      });

      return headers;
    });

    const orderTableCells = computed(() => {
      const cells = ["id"];

      pricingPoliciesList.value.forEach((policy) => {
        cells.push((item) => formatCurrency(get(item, `pricingPolicies.${policy}.price`)));
        cells.push((item) => formatCurrency(get(item, `pricingPolicies.${policy}.tax`)));
      });

      return cells;
    });

    const invoicesList = computed(() => {
      return store.state.orders.order?.invoices || [];
    });

    const orderSummary = computed(() => {
      const initialSummaryList = pricingPoliciesList.value.map((policy) => [policy, { price: 0, tax: 0 }]);
      const summaryObject = Object.fromEntries(initialSummaryList);

      invoices.value.forEach(({ pricingPolicies }) => {
        pricingPoliciesList.value.forEach((policy) => {
          if (pricingPolicies[policy]) {
            summaryObject[policy].price += Number(pricingPolicies[policy].price);
            summaryObject[policy].tax += Number(pricingPolicies[policy].tax);
          }
        });
      });

      const summary = pricingPoliciesList.value.flatMap((policy) => {
        const { price, tax } = summaryObject[policy];
        return [formatCurrency(price), formatCurrency(tax)];
      });

      return summary;
    });

    async function fetchOrder() {
      state.fetching = true;
      state.errors = {};

      try {
        await store.dispatch(ORDERS_ACTION_GET_ORDER);
        await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, ["portal"]);
      } catch (errors) {
        state.errors = errors;
        state.errors.fetchingError = true;
      }

      state.fetching = false;
    }

    async function addInvoice() {
      if (createInvoiceButtonDisabled.value) {
        return;
      }

      state.creating = true;
      state.errors = {};

      try {
        await store.dispatch(ORDERS_ACTION_ADD_NEW_INVOICE);
        state.selectedInvoice = NEW_ORDER_INVOICE_ID;
      } catch (errors) {
        state.errors = errors;
      }

      state.creating = false;
    }

    async function deleteInvoice(invoiceId) {
      state.deleting = invoiceId;
      state.errors = {};

      state.selectedInvoice = state.selectedInvoice == invoiceId ? null : state.selectedInvoice;

      try {
        await store.dispatch(ORDERS_ACTION_DELETE_INVOICE, { invoiceId });
      } catch (errors) {
        state.errors = errors;
      }

      state.deleting = null;
    }

    async function downloadInvoice(invoiceId) {
      console.log(": downloadInvoice", invoiceId);
      state.errors = {};

      const url = URLS.orders.invoices.download(invoiceId);

      try {
        await download(url);
      } catch (xhrError) {
        state.errors = prepareAxiosErrors(xhrError);
      }
    }

    function selectInvoice(invoiceId) {
      state.selectedInvoice = invoiceId;
    }

    function resetOrder(orderId) {
      if (orderId === NEW_ORDER_INVOICE_ID) {
        state.selectedInvoice = null;
      }

      store.dispatch(ORDERS_ACTION_RESET_ORDER);
    }

    onBeforeMount(() => {
      fetchOrder();
    });

    return {
      TABLE_ACTIONS,

      state,

      createInvoiceButtonDisabled,
      invoicesList,
      isOrderInvoicesLoaded,
      orderDate,
      orderInvoiceDetails,
      orderSummary,
      orderTableCells,
      orderTableHeaders,
      showInvoiceDetails,

      addInvoice,
      deleteInvoice,
      downloadInvoice,
      resetOrder,
      selectInvoice,
    };
  },
};
</script>

<style lang="scss">
.view-order {
  .content {
    display: grid;
    grid-template-columns: 11fr 1fr;
    grid-gap: 24px;

    &.details {
      grid-template-columns: 9fr 2fr;
    }

    .controls {
      button {
        & + button {
          margin-top: 20px;
        }
      }
    }
  }

  .invoice {
    margin-top: 64px;
  }
}
</style>
