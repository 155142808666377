import axios from "axios";

/**
 * Created by Alexander Golovnya on 2019-06-17.
 */
export default {
  card_search(page, tags) {
    let params = [];
    if (page) {
      params.push("page=" + page);
    }
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/cards/search${query}`).then((response) => {
      return response.data;
    });
  },
  card_list(newPage, searchText, rowCount) {
    let page = 1;
    if (newPage) {
      page = newPage;
    }
    return axios
      .get(
        "/api/cards?page=" +
          page +
          "&search=" +
          searchText +
          "&row_count=" +
          rowCount
      )
      .then((response) => {
        return response.data;
      });
  },
  card_list_by_company(company_id, date, with_deleted) {
    return axios
      .get(
        `/api/companies/${company_id}/cards?date=${date}&with_deleted=${with_deleted}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  summary_stats_download(finished_at) {
    return axios
      .get(
        `/api/journal_summary_statistics/cards/?finished_at=${finished_at}`,
        {
          responseType: "blob", //Force to receive data in a Blob Format
        }
      )
      .then((response) => {
        return new Blob([response.data], { type: "application/vnd.ms-excel" });
      });
  },
  card_view(company_id, id) {
    return axios
      .get(`/api/companies/${company_id}/any_cards/${id}`)
      .then((response) => {
        return response.data.data;
      });
  },
  card_update(company_id, id, row) {
    return axios
      .put(`/api/companies/${company_id}/any_cards/${id}`, row)
      .then((response) => {
        return response.data.data;
      });
  },
  card_delete(id) {
    return axios.delete(`/api/cards/${id}`).then((response) => {
      return response.data;
    });
  },
  card_restore(id) {
    return axios.post(`/api/cards/restore`, { id }).then((response) => {
      return response.data;
    });
  },
  get_claim_list_by_filter(tags) {
    let params = [];
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";
    return axios.get(`/api/claim/claim_cards${query}`).then((response) => {
      return response.data.data;
    });
  },
  update_claim_list_by_filter(command, filter_at, data) {
    return axios
      .put(`/api/claim/claim_cards`, {
        filter_at,
        command,
        data,
      })
      .then((response) => {
        return response.data.data;
      });
  },
};
