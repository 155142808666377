var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row-header"},[(_vm.state.processing)?_c('h1',[_vm._v("Link attractions")]):_c('h1',[_vm._v("Link attractions for \""+_vm._s(_vm.currentCouponsGroup.name)+"\"")]),_c('Button',{attrs:{"disabled":!_vm.isLinkAttractionButtonEnabled,"processing":_vm.state.newAttraction.linkingAttraction},on:{"click":_vm.linkAttraction}},[_vm._v(" Add ")])],1),_c('BreadCrumbs'),(_vm.state.processing)?_c('SpinnerAllsports',{attrs:{"wrapped":true,"centered":""}}):[_c('ErrorBanner',{attrs:{"message":_vm.state.error}}),_c('div',{staticClass:"content"},[_c('SimpleTable',{staticClass:"table-simple",attrs:{"cells":_vm.TABLE_CELLS,"headers":_vm.TABLE_HEADERS,"items":_vm.attractionsList,"show-delete-button":true,"processing-items":_vm.state.deletingAttraction},on:{"delete":_vm.onDelete},scopedSlots:_vm._u([{key:"controls",fn:function(){return [_c('tr',{staticClass:"non-selectable"},[_c('td',[_c('SearchInput',{ref:"refSupplierInput",attrs:{"error":_vm.state.newAttraction.error,"search-params":_vm.supplierSearchParams,"search-url":_vm.URLS.suppliers.search,"search-field":"matchByName","model-field":"id","item-text-field":function (ref) {
	var id = ref.id;
	var name = ref.name;

	return (id + " " + name);
}},on:{"select":_vm.onSupplierChange},model:{value:(_vm.state.newAttraction.supplierId),callback:function ($$v) {_vm.$set(_vm.state.newAttraction, "supplierId", $$v)},expression:"state.newAttraction.supplierId"}})],1),_c('td',[_c('SearchInput',{ref:"refAttractionInput",attrs:{"error":_vm.state.newAttraction.error,"offline-items":_vm.state.newAttraction.supplierAttractionsList,"model-field":"id","item-text-field":function (ref) {
	var id = ref.id;
	var name = ref.name;
	var list = ref.list;

	return (id + " " + name + " (" + list + ")");
},"disabled":!_vm.isSearchAttractionEnabled,"processing":_vm.state.newAttraction.processingAttractions},model:{value:(_vm.state.newAttraction.attractionId),callback:function ($$v) {_vm.$set(_vm.state.newAttraction, "attractionId", $$v)},expression:"state.newAttraction.attractionId"}})],1)])]},proxy:true}]),model:{value:(_vm.state.selectedLinkedAttraction),callback:function ($$v) {_vm.$set(_vm.state, "selectedLinkedAttraction", $$v)},expression:"state.selectedLinkedAttraction"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }