<template>
  <div class="container">
    <div class="row-header">
      <h1>{{ $t("Notification content") }}</h1>
      <Button v-if="!processing" @click="openNotificationsListView" auto-width>{{ $t("Back") }}</Button>
    </div>
    <SpinnerBrand v-if="processing" centered />
    <template v-else>
      <ErrorBanner :message="errorMessage" />
      <table v-if="!errorMessage">
        <tbody>
          <tr>
            <td>Name Surname</td>
            <td>{{ data.name }}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{{ data.phone }}</td>
          </tr>
          <tr>
            <td>SentAt</td>
            <td>{{ dateToLocaleString(data.sent_at) }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ data.status }}</td>
          </tr>
          <tr>
            <td>Channel</td>
            <td>{{ data.channel }}</td>
          </tr>
          <tr>
            <td>Content</td>
            <td>
              {{ data.content }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import useRequest from "@/composables/network/useRequest";
import { useRoute } from "@/helpers/router/routeCompositionAPI";
import URLS from "@/config/urls";
import router, { ROUTES_NAMES } from "@/router";
import dateToLocaleString from "@/helpers/date/dateToLocaleString";

export default {
  components: {
    Button,
    ErrorBanner,
    SpinnerBrand,
  },

  setup(props, { root }) {
    const route = useRoute(root);

    const { data, processing, errorMessage } = useRequest({
      url: URLS.notifications.single(route.value.params.notificationId),
      hook: "onBeforeMount",
      defaultValue: {},
    });

    function openNotificationsListView() {
      router.push({ name: ROUTES_NAMES.notifications.list });
    }

    return {
      processing,
      errorMessage,
      data,

      dateToLocaleString,
      openNotificationsListView,
    };
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;

  tr {
    td {
      font-weight: 400;
      font-size: 16px;
      padding: 12px 0;
      vertical-align: top;

      &:nth-child(1) {
        font-weight: 600;
        font-size: 18px;
        width: 25%;
      }
    }
  }
}
</style>
