<template>
  <form action="#" class="container" @submit.prevent="onSubmit">
    <h1 v-if="isEditMode">Edit Country ({{ countryId }})</h1>
    <h1 v-else>Add Country</h1>

    <BreadCrumbs />
    <ErrorBanner :message="errors.errorMessage" />

    <SpinnerBrand v-if="fetching" centered />
    <template v-else-if="state.isDataLoaded">
      <div class="inputs-row">
        <BaseInput
          v-model="state.country.code"
          :label="$t('Country code')"
          :disabled="isEditMode"
          :error="errors.code"
          :tooltip-text="
            $t(TOOLTIPS.code, [`<a target='_blank' href='https://www.iso.org/ru/standards.html'>`, `</a>`])
          "
          class="small"
        />
        <NewSelectInput
          v-model="state.country.locale"
          :label="$t('Locale')"
          :placeholder="$t('Select locale')"
          :error="errors.locale"
          :options="availableLocalesOptions"
          :tooltip-text="
            $t(TOOLTIPS.locale, [
              `<a target='_blank' href='https://www.iso.org/ru/iso-639-language-codes.html'>`,
              `</a>`,
            ])
          "
          class="small"
        />
        <BaseInput
          v-model="state.country.phoneCode"
          :label="$t('Phone code')"
          :error="errors.phone_code"
          :tooltip-text="$t(TOOLTIPS.phoneCode)"
          class="small"
        />
        <BaseInput
          v-model="state.country.phoneLength"
          :label="$t('Phone length')"
          :error="errors.phone_length"
          class="small"
        />
        <BaseInput
          v-model="state.country.phoneMask"
          :label="$t('Phone mask')"
          :error="errors.phone_mask"
          :tooltip-text="$t(TOOLTIPS.phoneMask)"
          class="small"
        />
        <SearchInput
          v-model="state.country.timezone"
          label="Timezone:"
          :item-text-field="({ id }) => id"
          :offline-items="timezonesOptions"
          input-mode
          class="small"
        />
      </div>
      <BaseInput
        v-model="state.country.brandName"
        :disabled="isEditMode"
        :error="errors.brand_name"
        :label="$t('Brand name')"
      />

      <template v-if="isEditMode">
        <template v-if="state.sellers">
          <h2>{{ $t("Sellers") }}</h2>
          <SimpleTable
            :cells="TABLE_CELLS.sellers"
            :headers="TABLE_HEADERS.sellers"
            :items="state.sellers"
            show-edit-button
            class="table"
            primary-key="code"
            @edit="openSellerEditModal"
          />
          <AddInstanceButton class="link-open-modal" @click="openSellerAddModal">
            {{ $t("Add Seller") }}
          </AddInstanceButton>
        </template>

        <h2>{{ $t("Price models") }}</h2>

        <SimpleTable
          :actions="TABLE_PRICE_MODEL_ACTIONS"
          :cells="TABLE_CELLS.priceModel"
          :headers="TABLE_HEADERS.priceModel"
          :items="state.priceModels"
          class="table"
          show-actions-button
          @edit="openPriceModelEditModal"
          @delete="openPriceModelDeleteModal"
        />
        <AddInstanceButton v-if="unusedCurrencies.quantity > 0" class="link-open-modal" @click="openPriceModelAddModal">
          {{ $t("Add price model") }}
        </AddInstanceButton>

        <h2>{{ $t("Locations") }}</h2>
        <div class="location-cards-container">
          <LocationCard
            v-for="location in state.locations"
            :key="`location-${location.id}`"
            :location-info="location"
            @delete="openLocationDeleteModal"
            @edit="openLocationEditModal"
          />
        </div>
        <AddInstanceButton class="link-open-modal" @click="openLocationAddModal">
          {{ $t("Add location") }}
        </AddInstanceButton>
      </template>

      <a href="#" v-scroll-to="'h1'" class="review-reminder">
        {{ $t("For successful use of the Journal, please review the information.") }}
      </a>
      <div class="equalizer">
        <BaseCheckbox v-model="state.isInformationReviewed">{{ $t("Familiar with the information") }}</BaseCheckbox>
        <Button button-type="primary" type="submit" :disabled="!state.isInformationReviewed" :processing="saving">
          <template v-if="isEditMode">{{ $t("Save") }}</template>
          <template v-else>{{ $t("Save and continue") }}</template>
        </Button>
      </div>
    </template>
  </form>
</template>

<script>
import { computed, reactive, onBeforeMount } from "@vue/composition-api";
import cloneDeep from "lodash/cloneDeep";

import AddInstanceButton from "@/components/buttons/AddInstanceButton.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import LocationCard from "@/components/cards/LocationCard.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import { camelCaseObjectKeys, snakeCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import { CONFIG_ACTION_FETCH_CONFIGS, CONFIG_ACTION_UPDATE_CONFIG } from "@/store/modules/config";
import { MODAL_ACTION_OPEN, MODAL_TYPES, MODAL_MODES } from "@/store/modules/modal";
import { ROUTES_NAMES } from "@/router";
import { VALIDATION_ERRORS } from "@/helpers/validators/index";
import { useRoute } from "@/helpers/router/routeCompositionAPI";
import validateRequiredFields from "@/helpers/validators/inputs/validateRequiredFields";
import openRouteByName from "@/helpers/router/openRouteByName";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";

const TABLE_SELLER_ACTIONS = [
  {
    title: "Edit",
    key: "edit",
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
];

const TABLE_PRICE_MODEL_ACTIONS = [
  {
    title: "Edit",
    key: "edit",
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
  {
    title: "Delete",
    key: "delete",
    icon: {
      type: "delete",
      color: "#E3001B",
    },
  },
];

const TABLE_HEADERS = {
  priceModel: ["Seller", "Currency", "Pricing policy", "Available subscriptions"],
  sellers: ["Name", "Registration code:", "Tax rate:"],
};
const TABLE_CELLS = {
  priceModel: [
    ({ seller }) => seller?.name || "–",
    "currency",
    ({ pricing_policy }) => pricing_policy.replaceAll("\n", "<br />"),
    "available_subscriptions",
  ],
  sellers: ["name", "vat_number", "tax_rate"],
};

const TOOLTIPS = {
  code: "Country codes are used in accordance with the international standard {0}ISO 3166-1 alpha-2{1}.",
  locale: "The language designation codes are used in accordance with the international standard {0}ISO 639-1{1}.",
  phoneCode: "Country phone codes are used.",
  phoneMask:
    'Use hash, parentheses, plus or dash symbols for formatting. For example: "+###-##-###-##-##" or "###(##)##-##-#".',
};

const DEFAULT_COUNTRY = {
  brandName: "",
  code: "",
  locale: null,
  phoneCode: "",
  phoneLength: "",
  phoneMask: "",
  timezone: "",
};

const REQUIRED_COUNTRY_KEYS = Object.keys(DEFAULT_COUNTRY).filter((item) => item !== "phoneMask");

export default {
  components: {
    AddInstanceButton,
    BaseCheckbox,
    BaseInput,
    BreadCrumbs,
    Button,
    ErrorBanner,
    LocationCard,
    NewSelectInput,
    SimpleTable,
    SpinnerBrand,
    SearchInput,
  },

  setup(props, { root }) {
    const route = useRoute(root);

    const { errors, fetch, processingMethods, setErrors } = useRequest({
      defaultValue: DEFAULT_COUNTRY,
      errorsFormat: "flat",
    });

    const state = reactive({
      isDataLoaded: false,
      isInformationReviewed: false,
      country: DEFAULT_COUNTRY,
      locations: [],
      priceModels: [],
      sellers: [],
      companies: [],
    });

    const availableLocales = computed(() => store.state.config.portal.availableLocales);
    const availableLocalesOptions = computed(() =>
      availableLocales.value.map((v) => ({ text: v.toUpperCase(), value: v }))
    );
    const availableCurrencies = computed(() => store.state.config.portal.availableCurrencies);

    const countryId = computed(() => route.value.params?.countryId);
    const fetching = computed(() => processingMethods.GET);
    const isEditMode = computed(() => !!countryId.value);

    const saving = computed(() => processingMethods.PUT || processingMethods.POST);

    const timezonesOptions = computed(() => {
      let options = [];

      if (window.Intl) {
        const timezonesList = Intl.supportedValuesOf("timeZone");
        options = timezonesList.map((v) => ({ id: v }));
      }

      return options;
    });

    const unusedCurrencies = computed(() => {
      const unusedCurrencies = {
        country: [],
        sellers: {},
        quantity: 0,
      };

      const countryUsedCurrencies = state.priceModels.filter(({ seller }) => !seller).map(({ currency }) => currency);

      unusedCurrencies.country = availableCurrencies.value.filter(
        (currency) => !countryUsedCurrencies.includes(currency)
      );

      unusedCurrencies.quantity += unusedCurrencies.country.length;

      state.sellers.forEach(({ code }) => {
        const usedSellerCurrencies = state.priceModels
          .filter(({ seller }) => seller?.code === code)
          .map(({ currency }) => currency);

        unusedCurrencies.sellers[code] = availableCurrencies.value.filter(
          (currency) => !usedSellerCurrencies.includes(currency)
        );

        unusedCurrencies.quantity += unusedCurrencies.sellers[code].length;
      });

      return unusedCurrencies;
    });

    async function onSubmit() {
      const errors = formValidation();

      if (errors) {
        return setErrors(snakeCaseObjectKeys(errors));
      }

      const requestData = snakeCaseObjectKeys(state.country);
      const method = isEditMode.value ? "PUT" : "POST";
      const url = isEditMode.value ? URLS.countries.single(countryId.value) : URLS.countries.index;

      const redirectToCountriesList = isEditMode.value;

      const { data, error } = await fetch({
        data: requestData,
        method,
        url,
      });

      if (!error && data.code) {
        if (redirectToCountriesList) {
          openRouteByName(ROUTES_NAMES.countries.index);
        } else {
          openRouteByName(ROUTES_NAMES.countries.edit, {
            params: {
              countryId: data.code,
            },
          });
        }
      }
    }

    function formValidation() {
      let errors = validateRequiredFields(state.country, REQUIRED_COUNTRY_KEYS) || {};

      REQUIRED_COUNTRY_KEYS.forEach((item) => {
        if (item == "locale" && !availableLocales.value.includes(state.country[item])) {
          errors[item] = VALIDATION_ERRORS.invalidLocaleValue;
        }
      });

      return Object.entries(errors).length > 0 ? errors : null;
    }

    async function fetchCountry() {
      const { data, error } = await fetch(URLS.countries.single(countryId.value));

      if (!error && data.code) {
        const { locations, priceModels, sellers, ...country } = camelCaseObjectKeys(data);

        state.country = country;
        state.locations = locations;
        state.priceModels = priceModels;
        state.sellers = sellers;
        state.isDataLoaded = true;
      }
    }

    function addNewLocation(newLocation) {
      state.locations = [...state.locations, newLocation];
    }

    function updateLocation(updatedLocation) {
      const newLocationsList = Array.from(state.locations);
      const oldLocationIndex = newLocationsList.findIndex(({ id }) => id == updatedLocation.id);

      newLocationsList.splice(oldLocationIndex, 1, updatedLocation);
      state.locations = newLocationsList;
    }

    function openLocationAddModal() {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.countryLocation,
        payload: {
          onAccept: addNewLocation,
          locations: state.locations,
        },
      });
    }

    function openLocationEditModal(locationId) {
      const location = state.locations.find(({ id }) => id == locationId);

      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.countryLocation,
        payload: {
          onAccept: updateLocation,
          location,
          locations: state.locations,
        },
      });
    }

    function openLocationDeleteModal(locationId) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.action,
        payload: {
          onAccept: deleteLocation.bind(null, locationId),
        },
      });
    }

    async function deleteLocation(locationId) {
      const { error } = await fetch({
        url: URLS.countries.locations.single(countryId.value, locationId),
        method: "DELETE",
      });

      if (!error) {
        state.locations = state.locations.filter(({ id }) => id != locationId);
      }
    }

    function addNewPriceModel(newPriceModel) {
      state.priceModels.push(newPriceModel);
    }

    function updatePriceModel(updatedPriceModel) {
      const newPriceModelsList = Array.from(state.priceModels);
      const oldPriceModelIndex = newPriceModelsList.findIndex(({ id }) => id == updatedPriceModel.id);

      newPriceModelsList.splice(oldPriceModelIndex, 1, updatedPriceModel);
      state.priceModels = newPriceModelsList;
    }

    function openPriceModelAddModal() {
      const type = MODAL_TYPES.priceModel;
      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode: MODAL_MODES[type].short,
        payload: {
          onAccept: addNewPriceModel,
          availableCurrencies: unusedCurrencies.value,
          companies: state.companies,
        },
      });
    }

    function openPriceModelEditModal(priceModelId) {
      const priceModel = state.priceModels.find(({ id }) => id == priceModelId);
      const type = MODAL_TYPES.priceModel;

      const unusedCurrenciesObject = cloneDeep(unusedCurrencies.value);
      const sellerCode = priceModel.seller?.code;

      if (sellerCode) {
        unusedCurrenciesObject.sellers[sellerCode].push(priceModel.currency);
      } else {
        unusedCurrenciesObject.country.push(priceModel.currency);
      }

      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode: MODAL_MODES[type].short,
        payload: {
          onAccept: updatePriceModel,
          priceModel,
          availableCurrencies: unusedCurrenciesObject,
          companies: state.companies,
        },
      });
    }

    function openPriceModelDeleteModal(priceModelId) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.action,
        payload: {
          onAccept: deletePriceModel.bind(null, priceModelId),
        },
      });
    }

    async function deletePriceModel(priceModelId) {
      const { error } = await fetch({
        url: URLS.countries.priceModels.single(countryId.value, priceModelId),
        method: "DELETE",
      });

      if (!error) {
        state.priceModels = state.priceModels.filter(({ id }) => id != priceModelId);
      }
    }

    function addNewSeller(newSeller) {
      state.sellers = [...state.sellers, newSeller];
      updateSellersConfig();
    }

    function updateSeller(updatedSeller) {
      const newSellersList = Array.from(state.sellers);
      const oldSellerIndex = newSellersList.findIndex(({ code }) => code == updatedSeller.code);

      newSellersList.splice(oldSellerIndex, 1, updatedSeller);
      state.sellers = newSellersList;

      updateSellersConfig();
    }

    function openSellerAddModal() {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.seller,
        payload: {
          onAccept: addNewSeller,
          sellers: state.sellers,
        },
      });
    }

    function openSellerEditModal(sellerCode) {
      const seller = state.sellers.find(({ code }) => code == sellerCode);

      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.seller,
        payload: {
          onAccept: updateSeller,
          seller,
          sellers: state.sellers,
        },
      });
    }

    function updateSellersConfig() {
      store.dispatch(CONFIG_ACTION_UPDATE_CONFIG, {
        key: "country.sellers",
        value: state.sellers,
      });
    }

    async function getCompanies() {
      const { data, error } = await fetch(URLS.companies.index);

      if (!error) {
        state.companies = data;
      }
    }

    onBeforeMount(async () => {
      const configsList = ["portal", { country: [countryId.value] }];

      await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, configsList);
      await getCompanies();

      if (isEditMode.value) {
        fetchCountry();
      } else {
        state.isDataLoaded = true;
      }
    });

    return {
      TABLE_SELLER_ACTIONS,
      TABLE_PRICE_MODEL_ACTIONS,
      TABLE_CELLS,
      TABLE_HEADERS,
      TOOLTIPS,

      errors,

      state,
      processingMethods,

      availableLocalesOptions,
      countryId,
      fetching,
      isEditMode,
      saving,
      timezonesOptions,
      unusedCurrencies,

      onSubmit,
      openPriceModelAddModal,
      openLocationAddModal,
      openPriceModelEditModal,
      openPriceModelDeleteModal,
      openLocationDeleteModal,
      openLocationEditModal,
      openSellerAddModal,
      openSellerEditModal,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin flex-column($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

h2 {
  font-weight: 600;
  font-size: 22px;
  margin-top: 25px;
}

.inputs-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.table {
  margin-top: 32px;
}

.link-open-modal {
  margin: 20px 0;
}

.review-reminder {
  display: block;
  margin-top: 26px;
  margin-bottom: 20px;
  font-size: 15px;
  color: #346aed;
  text-decoration: underline;
}

.equalizer {
  @include flex-column(50px);

  button {
    width: fit-content;
  }
}

.location-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 50px;
}

.small {
  width: 178px;
  height: fit-content;
}
</style>
