/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  file_update(file) {
    return axios
      .put(`/api/suppliers/${file.supplier_id}/files/${file.id}`, file)
      .then((response) => {
        return response.data.data;
      });
  },
  file_delete(supplier_id, id, force_delete) {
    return axios
      .delete(
        `/api/suppliers/${supplier_id}/files/${id}?force_delete=${force_delete}`
      )
      .then((response) => {
        return response.data;
      });
  },
  file_add(supplier_id, formData) {
    return axios
      .post(`/api/suppliers/${supplier_id}/files`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        return response.data.data;
      });
  },
  file_list_by_supplier(supplier_id, page) {
    return axios
      .get(`/api/suppliers/${supplier_id}/files?page=${page}`)
      .then((response) => {
        return response.data;
      });
  },
  bulk_file_add(files) {
    return axios
      .post(`/api/supplierfiles`, {
        files,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  file_validate(file) {
    return axios.patch(`/api/supplierfiles/validate`, file).then((response) => {
      return response.data.data;
    });
  },
  file_upgrade(id) {
    return axios
      .patch(`/api/supplierfiles/upgrade`, {
        patch: "upgrade",
        id,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  bulk_file_update(patch) {
    return axios.patch(`/api/supplierfiles`, patch).then((response) => {
      return response.data.data;
    });
  },
  download(supplier_id, id) {
    return axios
      .get(`/api/suppliers/${supplier_id}/files/${id}/download`, {
        responseType: "blob", //Force to receive data in a Blob Format
      })
      .then((response) => {
        return new Blob([response.data], { type: "application/pdf" });
      });
  },
  order_generate(supplier_id, id) {
    return axios
      .post(`/api/suppliers/${supplier_id}/files/${id}/paying`)
      .then((response) => {
        return response.data.data;
      });
  },
  generate(supplier_id, id, no_download) {
    return axios
      .get(
        `/api/suppliers/${supplier_id}/files/${id}/generate?no_download=${no_download}`,
        {
          responseType: "blob", //Force to receive data in a Blob Format
        }
      )
      .then((response) => {
        return new Blob([response.data], { type: "application/pdf" });
      });
  },
};
