import axios from "axios";

/**
 * Created by Alexander Golovnya on 2019-06-17.
 */
export default {
  testGenerateInvoiceAndSendToHr (companyId, email) {
    return axios
      .get(`/api/claim/companies/company/${companyId}/force_renew_invoice?email=${email}`)
      .then((response) => {
        return response.data.data;
      });
  },
  send_company_off(id, date) {
    return axios
      .get(`/api/notifications/company_off?company_id=${id}&date=${date}`)
      .then((response) => {
        return response.data.data;
      });
  },
  summary_levels_stats_download(finished_at) {
    return axios
      .get(
        `/api/journal_summary_statistics/levels/?finished_at=${finished_at}`,
        {
          responseType: "blob", //Force to receive data in a Blob Format
        }
      )
      .then((response) => {
        return new Blob([response.data], { type: "application/vnd.ms-excel" });
      });
  },
  summary_stats_download(finished_at) {
    return axios
      .get(
        `/api/journal_summary_statistics/companies/?finished_at=${finished_at}`,
        {
          responseType: "blob", //Force to receive data in a Blob Format
        }
      )
      .then((response) => {
        return new Blob([response.data], { type: "application/vnd.ms-excel" });
      });
  },
  b2b_stats_download(finished_at) {
    return axios
      .get(`/api/journal_summary_statistics/b2b/?finished_at=${finished_at}`, {
        responseType: "blob", //Force to receive data in a Blob Format
      })
      .then((response) => {
        return new Blob([response.data], { type: "application/vnd.ms-excel" });
      });
  },
  b2c_stats_download(finished_at) {
    return axios
      .get(`/api/journal_summary_statistics/b2c/?finished_at=${finished_at}`, {
        responseType: "blob", //Force to receive data in a Blob Format
      })
      .then((response) => {
        return new Blob([response.data], { type: "application/vnd.ms-excel" });
      });
  },
  load_templates() {
    return axios.get(`/api/companies/template_list`).then((response) => {
      return response.data;
    });
  },
  company_list() {
    return axios.get("/api/companies").then((response) => {
      return response.data.data;
    });
  },
  company_view(id) {
    return axios.get(`/api/companies/${id}`).then((response) => {
      return response.data.data;
    });
  },
  company_default() {
    return axios.get(`/api/companies/default`).then((response) => {
      return response.data.data;
    });
  },
  company_create(row) {
    return axios.post(`/api/companies`, row).then((response) => {
      return response.data.data;
    });
  },
  company_delete(id) {
    return axios.delete(`/api/companies/${id}`).then((response) => {
      return response.data;
    });
  },
  company_update(id, row) {
    return axios.put(`/api/companies/${id}`, row).then((response) => {
      return response.data.data;
    });
  },
  unn_search(unn) {
    return axios.get(`/api/unn_search?unn=${unn}`).then((response) => {
      return response.data.data;
    });
  },
  load_claim_by_id(id) {
    return axios.get(`/api/claim/claims/${id}`).then((response) => {
      return response.data.data;
    });
  },
  load_claim_list_by_range(tags) {
    const params = [];
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";
    return axios.get(`/api/claim/companies/claims${query}`).then((response) => {
      return response.data.data;
    });
  },
  claim_patch(row, command) {
    return axios
      .patch(
        `/api/claim/claim_invoices`,
        Object.assign(row, {
          command,
        })
      )
      .then((response) => {
        return response.data;
      });
  },
  invoice_create(row, invoice) {
    return axios
      .post(`/api/claim/companies/claims/${row.id}/invoices`, invoice)
      .then((response) => {
        return response.data.data;
      });
  },
  invoice_update(row, invoice) {
    return axios
      .put(
        `/api/claim/companies/claims/${row.id}/invoices/${invoice.id}`,
        invoice
      )
      .then((response) => {
        return response.data.data;
      });
  },
  invoice_delete(invoice) {
    return axios
      .delete(`/api/claim/claim_invoices/${invoice.id}`)
      .then((response) => {
        return response.data.data;
      });
  },
  invoice_download(invoice) {
    return axios.get(`/api/claim/claim_invoices/${invoice.id}/download`, {
      responseType: "blob", //Force to receive data in a Blob Format
    });
  },
  invoice_list(row) {
    return axios
      .get(
        `/api/claim/companies/claims/${row.id}/invoices?with_deleted=${row.with_deleted}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  match_holder(data) {
    return axios.post(`/api/cards/match_holder`, data).then((response) => {
      return response.data.data;
    });
  },
  act_create(row, act) {
    return axios
      .post(`/api/claim/companies/claims/${row.id}/acts`, act)
      .then((response) => {
        return response.data.data;
      });
  },
  act_update(row, act) {
    return axios
      .put(`/api/claim/companies/claims/${row.id}/acts/${act.id}`, act)
      .then((response) => {
        return response.data.data;
      });
  },
  act_delete(act) {
    return axios.delete(`/api/claim/claim_acts/${act.id}`).then((response) => {
      return response.data.data;
    });
  },
  act_download(act) {
    return axios.get(`/api/claim/claim_acts/${act.id}/download`, {
      responseType: "blob", //Force to receive data in a Blob Format
    });
  },
  act_list(row) {
    return axios
      .get(
        `/api/claim/companies/claims/${row.id}/acts?with_deleted=${row.with_deleted}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  card_list(company_id) {
    return axios
      .get(`/api/claim/companies/claims/${company_id}/cards`)
      .then((response) => {
        return response.data.data;
      });
  },
  claim_monthly_list(row) {
    return axios
      .get(`/api/claim/companies/claims/${row.id}/monthly`)
      .then((response) => {
        return response.data.data;
      });
  },
  claim_monthly_upsert(id, items) {
    return axios
      .post(`/api/claim/companies/claims/${id}/monthly`, {
        items,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  claim_create(row) {
    return axios.post(`/api/claim/companies/claims`, row).then((response) => {
      return response.data.data;
    });
  },
  claim_update(row) {
    return axios
      .put(`/api/claim/companies/claims/${row.id}`, row)
      .then((response) => {
        return response.data.data;
      });
  },
  claim_update_on_manager(row) {
    return axios
      .put(`/api/claim/companies/claim_manager/${row.id}`, row)
      .then((response) => {
        return response.data.data;
      });
  },
  claim_remove(row) {
    return axios
      .delete(`/api/claim/companies/claims/${row.id}`)
      .then((response) => {
        return response.data;
      });
  },
  v2_parse_server_csv(data) {
    let params = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        params.push(key + "=" + data[key]);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";
    return axios.get(`/api/employee/csv/parse${query}`).then((response) => {
      return response.data;
    });
  },
  v2_parse_google_sheet(data) {
    let params = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        params.push(key + "=" + data[key]);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";
    return axios.get(`/api/employee/google/parse${query}`).then((response) => {
      return response.data;
    });
  },
  v2_parse_update_list(id, filterAt, columns, values, changes) {
    return axios
      .put(`/api/employee/list/parse`, {
        company_id: id,
        filter_at: filterAt,
        columns,
        values,
        changes,
      })
      .then((response) => {
        return response.data;
      });
  },
  v2_export_google_sheet(
    next_month,
    arrayCsvObjects,
    arrayChanges,
    company_id,
    cleanup_off,
    validation_off,
    condition_off
  ) {
    return axios
      .put(`/api/employee/google/export`, {
        next_month,
        arrayCsvObjects,
        arrayChanges,
        company_id,
        cleanup_off,
        validation_off,
        condition_off,
      })
      .then((response) => {
        return response.data;
      });
  },
};
