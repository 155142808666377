<template>
  <small-layout>
    <div>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">Manage Tickets</h2>

      <div>
        <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
          <button type="button" @click="filterToggler = !filterToggler">Фильтр поставщиков</button>
          <search-ticket-list class="formFilter" v-if="filterToggler" @hide="filterToggler = false" />
        </div>
      </div>
      <div class="py-12">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg px-4 py-4">
            <template v-for="attraction in attractions">
              <jet-responsive-nav-link
                :to="{ name: 'holder_ticket_create', query: { attraction_id: attraction.id } }"
                class="bg-gray-200 hover:bg-gray-100 text-white font-bold py-2 px-4 rounded my-3"
              >
                Add Ticket for {{ attraction.supplier.name }}/{{ attraction.name }}
              </jet-responsive-nav-link>
            </template>
            <table class="table-fixed w-full">
              <thead>
                <tr class="bg-gray-100">
                  <th class="px-4 py-2" colspan="4">Value</th>
                </tr>
                <tr class="bg-gray-100">
                  <th class="px-4 py-2">RegisteredAt</th>
                  <th class="px-4 py-2">Holder</th>
                  <th class="px-4 py-2">Supplier</th>
                  <th class="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="row in tickets">
                  <tr>
                    <td
                      :class="
                        'border px-4 py-2 text-center' + (row.holder_id && !row.holder_name ? ' bg-green-200' : '')
                      "
                      colspan="4"
                    >
                      {{ row.value }}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2">
                      {{ row.taken_at ? row.taken_at.substr(0, 10) : row.registered_at }}
                    </td>
                    <td class="border px-4 py-2">
                      {{ row.card_id && row.holder ? row.holder.name : row.holder_name }}
                    </td>
                    <td class="border px-4 py-2">{{ row.supplier ? row.supplier.name : "UNKNOWN" }}</td>
                    <td class="border px-4 py-2">
                      <jet-responsive-nav-link
                        :to="{ name: 'holder_ticket_show', params: { ticket: row.id } }"
                        :active="row.id"
                      >
                        Take
                      </jet-responsive-nav-link>
                      <button
                        @click="deleteRow(row)"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </small-layout>
</template>

<script>
import SearchTicketList from "./SearchTicketList";
import JetResponsiveNavLink from "../../Jetstream/ResponsiveNavLink";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    JetResponsiveNavLink,
    SearchTicketList,
  },

  data() {
    return {
      filterToggler: false,
    };
  },

  computed: {
    ...mapGetters({
      tickets: "ticket/list",
      attractions: "ticket/attractions",
    }),
  },

  methods: {
    ...mapActions({
      loadTickets: "ticket/load_tickets",
      deleteTicket: "ticket/delete_ticket",
    }),
    deleteRow: function (data) {
      this.deleteTicket(data.id);
    },
  },
  async mounted() {
    await this.loadTickets(this.$route.query);
  },
};
</script>

<style scoped>
.formFilter {
  position: fixed;
  left: 20%;
  top: 20%;
  background-color: whitesmoke;
}
</style>
