import request from "@/helpers/request";
import URLS from "@/config/urls";
import { validateRequiredProps } from "@/helpers/validators";

export const STORE_NAMESPACE = "coupons";

const ACTION_ADD_COUPON_GROUP = "actionAddCouponGroup";
const ACTION_DELETE_ATTRACTION = "actionDeleteAttraction";
const ACTION_GET_ATTRACTIONS = "actionGetAttractions";
const ACTION_GET_COUPON = "actionGetCoupon";
const ACTION_GET_COUPONS_GROUPS = "actionGetCouponsGroups";
const ACTION_LINK_ATTRACTION = "actionLinkAttraction";
const ACTION_REDEEM_COUPON = "actionRedeemCoupon";
const ACTION_UPDATE_COUPON = "actionUpdateCoupon";

const MUTATION_UPDATE_ATTRACTIONS = "mutationUpdateAttractions";
const MUTATION_UPDATE_COUPONS_GROUPS = "mutationUpdateCouponsGroups";
const MUTATION_UPDATE_REDEEMING_COUPON = "mutationUpdateRedeemingCoupon";

export const COUPONS_ACTION_ADD_COUPON_GROUP = `${STORE_NAMESPACE}/${ACTION_ADD_COUPON_GROUP}`;
export const COUPONS_ACTION_DELETE_ATTRACTION = `${STORE_NAMESPACE}/${ACTION_DELETE_ATTRACTION}`;
export const COUPONS_ACTION_GET_ATTRACTIONS = `${STORE_NAMESPACE}/${ACTION_GET_ATTRACTIONS}`;
export const COUPONS_ACTION_GET_COUPON = `${STORE_NAMESPACE}/${ACTION_GET_COUPON}`;
export const COUPONS_ACTION_GET_COUPONS_GROUPS = `${STORE_NAMESPACE}/${ACTION_GET_COUPONS_GROUPS}`;
export const COUPONS_ACTION_LINK_ATTRACTION = `${STORE_NAMESPACE}/${ACTION_LINK_ATTRACTION}`;
export const COUPONS_ACTION_REDEEM_COUPON = `${STORE_NAMESPACE}/${ACTION_REDEEM_COUPON}`;
export const COUPONS_ACTION_UPDATE_COUPON = `${STORE_NAMESPACE}/${ACTION_UPDATE_COUPON}`;

function normalizeCouponsGroupsList(groupsList) {
  return groupsList.map((group) => ({
    id: group.id,
    name: group.name,
    coupons: group.coupons_amount ?? group.coupons,
    suppliers: group.suppliers_amount ?? group.suppliers,
  }));
}

export default {
  namespaced: true,

  state: {
    couponsGroupsList: [],
    attractionsList: [],

    redeemingCoupon: null,
  },

  mutations: {
    [MUTATION_UPDATE_COUPONS_GROUPS](state, couponsGroupsList) {
      const allPropsPassed = validateRequiredProps(
        MUTATION_UPDATE_COUPONS_GROUPS,
        couponsGroupsList
      );

      if (allPropsPassed) {
        state.couponsGroupsList = couponsGroupsList;
      }
    },
    [MUTATION_UPDATE_REDEEMING_COUPON](state, coupon) {
      state.redeemingCoupon = coupon || null;
    },
    [MUTATION_UPDATE_ATTRACTIONS](state, attractionsList) {
      state.attractionsList = attractionsList || [];
    },
  },

  actions: {
    [ACTION_ADD_COUPON_GROUP]({ state, commit }, newCouponGroup) {
      const updatedGroupsList = [...state.couponsGroupsList, newCouponGroup];
      const preparedGroupsList = normalizeCouponsGroupsList(updatedGroupsList);

      commit(MUTATION_UPDATE_COUPONS_GROUPS, preparedGroupsList);
    },
    async [ACTION_GET_COUPONS_GROUPS]({ state, commit }, forceUpdate = false) {
      if (state.couponsGroupsList.length > 0 && !forceUpdate) {
        return state.couponsGroupsList;
      }

      const result = await request(URLS.coupons.groups.list);
      const preparedGroupsList = normalizeCouponsGroupsList(result);

      commit(MUTATION_UPDATE_COUPONS_GROUPS, preparedGroupsList);

      return preparedGroupsList;
    },
    async [ACTION_REDEEM_COUPON]({ commit }, { couponsGroupId } = {}) {
      const allPropsPassed = validateRequiredProps(
        ACTION_REDEEM_COUPON,
        couponsGroupId
      );

      if (allPropsPassed) {
        const url = URLS.coupons.redeem(couponsGroupId);
        const result = await request(url);

        commit(MUTATION_UPDATE_REDEEMING_COUPON, result);

        return result;
      }
    },
    async [ACTION_GET_COUPON](
      { commit },
      { couponsGroupId, couponId, updateRedeemingCoupon } = {}
    ) {
      const allPropsPassed = validateRequiredProps(
        ACTION_GET_COUPON,
        couponsGroupId,
        couponId
      );

      if (allPropsPassed) {
        const url = URLS.coupons.single(couponId);
        const result = await request(url);

        if (updateRedeemingCoupon) {
          commit(MUTATION_UPDATE_REDEEMING_COUPON, result);
        }

        return result;
      }
    },
    async [ACTION_UPDATE_COUPON](ctx, { couponsGroupId, couponId, data } = {}) {
      const allPropsPassed = validateRequiredProps(
        ACTION_UPDATE_COUPON,
        couponsGroupId,
        couponId,
        data
      );

      if (allPropsPassed) {
        const url = URLS.coupons.single(couponId);
        const result = await request({
          method: "PUT",
          data,
          url,
        });

        return result;
      }
    },
    async [ACTION_GET_ATTRACTIONS]({ commit }, { couponsGroupId } = {}) {
      const allPropsPassed = validateRequiredProps(
        ACTION_GET_ATTRACTIONS,
        couponsGroupId
      );

      if (allPropsPassed) {
        const url = URLS.coupons.attractions(couponsGroupId);
        const result = await request(url);

        commit(MUTATION_UPDATE_ATTRACTIONS, result);
      }
    },
    async [ACTION_LINK_ATTRACTION](
      { commit, state },
      { couponsGroupId, attractionId } = {}
    ) {
      const allPropsPassed = validateRequiredProps(
        ACTION_LINK_ATTRACTION,
        couponsGroupId,
        attractionId
      );

      if (allPropsPassed) {
        const url = URLS.coupons.attractions(couponsGroupId);
        const data = {
          attraction_id: attractionId,
        };

        const result = await request({
          method: "POST",
          data,
          url,
        });

        const updatedAttractions = [...state.attractionsList, result];

        commit(MUTATION_UPDATE_ATTRACTIONS, updatedAttractions);

        return result;
      }
    },
    async [ACTION_DELETE_ATTRACTION](
      { commit, state },
      { couponsGroupId, attractionId } = {}
    ) {
      const allPropsPassed = validateRequiredProps(
        ACTION_DELETE_ATTRACTION,
        couponsGroupId,
        attractionId
      );

      if (allPropsPassed) {
        const url = URLS.coupons.deleteAttraction(couponsGroupId, attractionId);

        await request({
          method: "DELETE",
          url,
        });

        const filteredAttractions = state.attractionsList.filter(
          (attraction) => attraction.id != attractionId
        );

        commit(MUTATION_UPDATE_ATTRACTIONS, filteredAttractions);
      }
    },
  },
};
