/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import axios from "axios";
export default {
  update_error(error) {
    return axios
      .patch(`/api/error_tokens/${error.id}`, {
        url: error.url,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  tokens(values) {
    let params = [];
    if (values) {
      Object.getOwnPropertyNames(values).forEach((name) => {
        if (values[name]) {
          params.push(name + "=" + values[name]);
        }
      });
    }
    let query = params.length > 0 ? "?" + encodeURI(params.join("&")) : "";
    return axios.get(`/api/tokens${query}`).then((response) => {
      return response.data.data;
    });
  },
  history_tokens(values) {
    let params = [];
    if (values) {
      Object.getOwnPropertyNames(values).forEach((name) => {
        if (values[name]) {
          params.push(name + "=" + values[name]);
        }
      });
    }
    let query = params.length > 0 ? "?" + encodeURI(params.join("&")) : "";
    return axios.get(`/api/history_tokens${query}`).then((response) => {
      return response.data.data;
    });
  },
  revoke_token(token) {
    return axios
      .patch(`/api/tokens/${token.id}`, {
        revoke: 1,
      })
      .then((response) => {
        return response.data.data;
      });
  },
};
