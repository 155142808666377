<template>
  <div class="container">
    <div class="row-header">
      <h1>{{ $t("Coupons groups") }}</h1>
      <Button @click="openCreateGroupModal">
        {{ $t("New Group") }}
      </Button>
    </div>
    <BreadCrumbs />
    <SpinnerAllsports v-if="state.processing" wrapped centered />
    <template v-else>
      <ErrorBanner :message="state.error" />
      <div class="content">
        <SimpleTable
          class="table-simple"
          :cells="TABLE_CELLS"
          :actions="TABLE_ACTIONS"
          :headers="TABLE_HEADERS"
          :items="couponsGroupsList"
          show-actions-button
          @open-coupons-search-view="openCouponsSearchView"
          @redeem-coupon="redeemCoupon"
          @import-coupons="importCoupons"
          @link-attractions="linkAttractions"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount } from "@vue/composition-api";

import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button, { BUTTON_TYPES } from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";

import router, { ROUTES_NAMES } from "@/router";
import store from "@/store";
import {
  COUPONS_ACTION_ADD_COUPON_GROUP,
  COUPONS_ACTION_GET_COUPONS_GROUPS,
  COUPONS_ACTION_REDEEM_COUPON,
} from "@/store/modules/coupons";
import { MODAL_ACTION_OPEN, MODAL_TYPES } from "@/store/modules/modal";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const TABLE_HEADERS = ["Affiliates of Coupons", "Coupons left", "Amount of Suppliers connected"];
const TABLE_CELLS = ["name", "coupons", "suppliers"];

const ACTIONS = {
  search: "open-coupons-search-view",
  redeem: "redeem-coupon",
  import: "import-coupons",
  link: "link-attractions",
};

const TABLE_ACTIONS = [
  {
    title: "Coupons search",
    key: ACTIONS.search,
    icon: {
      type: "search",
      color: "#346AED",
    },
  },
  {
    title: "Redeem coupon",
    key: ACTIONS.redeem,
    icon: {
      type: "coupon",
      color: "#346AED",
    },
  },
  {
    title: "Import Coupons",
    key: ACTIONS.import,
    icon: {
      type: "import",
      color: "#346AED",
    },
  },
  {
    title: "Link attractions",
    key: ACTIONS.link,
    icon: {
      type: "chain",
      color: "#346AED",
    },
  },
];

export default {
  components: {
    BreadCrumbs,
    Button,
    ErrorBanner,
    SimpleTable,
    SpinnerAllsports,
  },

  setup() {
    const state = reactive({
      error: null,
      processing: false,
      redeemProcessing: false,
    });

    const couponsGroupsList = computed(() => store.state.coupons.couponsGroupsList);

    function addCouponsGroup(newGroup) {
      store.dispatch(COUPONS_ACTION_ADD_COUPON_GROUP, newGroup);
    }

    async function getCouponsList() {
      state.processing = true;

      try {
        await store.dispatch(COUPONS_ACTION_GET_COUPONS_GROUPS, true);
      } catch (xhrError) {
        onError(xhrError);
      }

      state.processing = false;
    }

    async function redeemCoupon(couponsGroupId) {
      if (state.redeemProcessing) {
        return;
      }

      state.redeemProcessing = true;

      try {
        const coupon = await store.dispatch(COUPONS_ACTION_REDEEM_COUPON, {
          couponsGroupId,
        });

        const couponId = coupon.id;
        const nextRoute = {
          name: ROUTES_NAMES.coupons.redeem,
          params: { couponsGroupId, couponId },
        };

        router.push(nextRoute);
      } catch (xhrError) {
        onError(xhrError);
      }

      state.redeemProcessing = false;
    }

    function onError(xhrError) {
      const { errorMessage } = prepareAxiosErrors(xhrError);
      state.error = errorMessage;
    }

    function importCoupons(couponsGroupId) {
      router.push({
        name: ROUTES_NAMES.coupons.import.index,
        params: { couponsGroupId },
      });
    }

    function linkAttractions(couponsGroupId) {
      router.push({
        name: ROUTES_NAMES.coupons.attractions,
        params: { couponsGroupId },
      });
    }

    function openCreateGroupModal() {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.coupons.createCouponsGroup,
        payload: {
          onSuccess: addCouponsGroup,
        },
      });
    }

    function openCouponsSearchView(couponsGroupId) {
      router.push({
        name: ROUTES_NAMES.coupons.search,
        params: { couponsGroupId },
      });
    }

    onBeforeMount(() => {
      getCouponsList();
    });

    return {
      BUTTON_TYPES,
      TABLE_ACTIONS,
      TABLE_HEADERS,
      TABLE_CELLS,

      state,
      couponsGroupsList,

      redeemCoupon,
      importCoupons,
      linkAttractions,
      openCreateGroupModal,
      openCouponsSearchView,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  .content {
    max-width: 840px;

    .controls {
      position: sticky;
      top: 48px;
    }
  }
}
</style>
