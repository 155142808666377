import axios from "axios";
/**
 * Created by Alexander Golovnya on 2019-06-24.
 */
export default {
  user_list() {
    return axios.get("/api/users").then((response) => {
      return response.data.data;
    });
  },
  user_create(row) {
    return axios.post(`/api/users`, row).then((response) => {
      return response.data.data;
    });
  },
  user_delete(id) {
    return axios.delete(`/api/users/${id}`).then((response) => {
      return response.data;
    });
  },
  user_update(id, row) {
    return axios.put(`/api/users/${id}`, row).then((response) => {
      return response.data.data;
    });
  },
  admin_user_update(id, row) {
    return axios.put(`/api/admin_users/${id}`, row).then((response) => {
      return response.data.data;
    });
  },
  supplier_search(text) {
    return axios
      .get("/api/suppliers/simple?search=" + text)
      .then((response) => {
        return response.data.data;
      });
  },
  manager_list() {
    return axios.get("/api/user_managers").then((response) => {
      return response.data.data;
    });
  },
};
