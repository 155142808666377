import api_helpdesk from "../../api/helpdesk";

const STORE_NAMESPACE = "helpdesk";

const ACTION_VIEW_VISIT = "view_visit";
const ACTION_UPDATE_VISIT_RADIUS = "updateVisit";

export const HELPDESK_ACTION_VIEW_VISIT = `${STORE_NAMESPACE}/${ACTION_VIEW_VISIT}`;
export const HELPDESK_ACTION_UPDATE_VISIT_RADIUS = `${STORE_NAMESPACE}/${ACTION_UPDATE_VISIT_RADIUS}`;

const state = {
  header_to_ids: {},
  visit_list: [],
  summary: {},
};

const getters = {
  list_summary(state) {
    return state.summary;
  },
  visit_list(state) {
    return state.visit_list;
  },
};

const actions = {
  async show_amount_of_gyms({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_helpdesk.show_amount_of_gyms();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async helpdesk_holder({ commit }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      const visit_list = await api_helpdesk.helpdesk_holder_list(params);
      commit("visit_list", visit_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async stats_list({ commit }, { tags }) {
    try {
      commit("error/clear", undefined, { root: true });
      const visit_list = await api_helpdesk.visit_list(tags);
      commit("visit_list", visit_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async stats_monthly_list_by_status({ commit }, tags) {
    try {
      commit("error/clear", undefined, { root: true });
      const visit_list = await api_helpdesk.visit_list_by_status(tags);
      commit("visit_list", visit_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async manually_update_visit({ commit, rootGetters }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      const newVisit = await api_helpdesk.manually_update_visit(visit);
      return newVisit;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async [ACTION_VIEW_VISIT]({ commit }, id) {
    commit("error/clear", undefined, { root: true });

    try {
      const newVisit = await api_helpdesk.view_visit(id);
      return newVisit;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async [ACTION_UPDATE_VISIT_RADIUS]({ commit }, { id, radius } = {}) {
    if (id && radius) {
      commit("error/clear", undefined, { root: true });

      try {
        const newVisit = await api_helpdesk.update_visit_radius({ id, radius });
        return newVisit;
      } catch (error) {
        commit("error/set_error", error, { root: true });
        throw error;
      }
    }
  },
  async view_holder({ commit, rootGetters }, id) {
    try {
      commit("error/clear", undefined, { root: true });
      const newHolder = await api_helpdesk.view_holder(id);
      return newHolder;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async manually_create_visit_card({ commit, rootGetters }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_helpdesk.manually_create_visit_card(visit);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async manually_create_visit({ commit, rootGetters }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_helpdesk.manually_create_visit(visit);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async manually_update_visit_card({ commit, rootGetters }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      const visit_list = await api_helpdesk.manually_update_visit_card(visit);
      commit("update_visit_list", visit_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async manually_update_visit_card_mobile({ commit, rootGetters }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      const visit_list = await api_helpdesk.manually_update_visit_card(visit);
      return visit_list[0];
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_attraction_by_visit({ commit }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      const supplier = await api_helpdesk.manually_patching_visit(visit);
      return supplier.attractions;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_holder_by({ commit }, { card_id, holder }) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_helpdesk.load_holder_by(card_id, holder);
      return list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_cities({ commit }) {
    return await api_helpdesk.load_cities();
  },
  async update_city({ commit }, { city, support_phone }) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_helpdesk.update_city(city, support_phone);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      if (error.response && error.response.data) {
        return error.response.data;
      }
    }
  },
};

function array_flip(trans) {
  let key,
    tmp_ar = {};

  for (key in trans) {
    if (trans.hasOwnProperty(key)) {
      tmp_ar[trans[key]] = key;
    }
  }
  return tmp_ar;
}

// mutations
const mutations = {
  visit_list(state, list) {
    if (list.summary) {
      state.summary = list.summary;
    }
    if (list.columns) {
      state.header_to_ids = array_flip(list.headers);
    }
    state.visit_list = list.data;
  },
  update_visit_list(state, list) {
    list.map((new_visit) => {
      let visit = state.visit_list.find((visit) => visit.id === new_visit.id);
      Object.assign(visit, new_visit);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
