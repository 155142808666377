<template>
  <article class="location-card">
    <div class="header">
      <h3 :title="location.name">{{ location.name }}</h3>
      <div class="buttons">
        <a href="#" class="action_button action_button__edit" @click.prevent="onEdit">
          <Icon icon="edit" class="action_button_icon" height="20px" width="24px" />
        </a>
        <a href="#" class="action_button action_button__delete" @click.prevent="onDelete">
          <Icon icon="delete" class="action_button_icon" height="20px" width="24px" />
        </a>
      </div>
    </div>
    <ul class="card-list">
      <li class="item">
        <div class="item_title">{{ $t("Type of location")}}:</div>
        <div class="item_body">{{ location.type }}</div>
      </li>
      <li class="item">
        <div class="item_title">{{ $t("Coordinates") }}:</div>
        <div class="item_body">{{ location.geolocation }}</div>
      </li>
      <li class="item">
        <div class="item_title">{{ $t("Support phone") }}:</div>
        <a href="#">{{ location.supportPhone }}</a>
      </li>
      <li class="item">
        <div class="item_title">{{ $t("Support email") }}:</div>
        <a href="#">{{ location.supportEmail }}</a>
      </li>
      <li class="item">
        <div class="item_title">{{ $t("Sales phone") }}:</div>
        <a href="#">{{ location.salesPhone }}</a>
      </li>
      <li class="item">
        <div class="item_title">{{ $t("Sales email") }}:</div>
        <a href="#">{{ location.salesEmail }}</a>
      </li>
    </ul>
  </article>
</template>

<script>
import { computed } from "@vue/composition-api";

import Icon from "@/components/icons/Icon.vue";

import { camelCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";

export default {
  components: {
    Icon,
  },

  emits: ["delete", "edit"],

  props: {
    locationInfo: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const location = computed(() => camelCaseObjectKeys(props.locationInfo));

    function onDelete() {
      emit("delete", location.value.id);
    }

    function onEdit() {
      emit("edit", location.value.id);
    }

    return {
      location,

      onDelete,
      onEdit,
    };
  },
};
</script>

<style lang="scss" scoped>
.location-card {
  padding: 16px;
  width: 278px;
  border: 1px solid #9c9c9c;
  border-radius: 8px;
  background-color: #fafafa;
  opacity: 0.5;

  &:hover {
    transition: opacity 0.1s linear;
    opacity: 1;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h3 {
      font-weight: 600;
      font-size: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .buttons {
      display: flex;

      .action_button {
        display: inline-block;

        &__delete {
          &:hover {
            .action_button_icon {
              fill: #d63230;
            }
          }
        }

        &__edit {
          &:hover {
            .action_button_icon {
              fill: #346aed;
            }
          }
        }

        .action_button_icon {
          fill: #9c9c9c;
        }
      }
    }
  }

  .card-list {
    list-style: none;

    .item {
      display: flex;
      font-weight: 300;
      font-size: 13px;

      a {
        font-weight: 400;
        width: 100%;
        word-break: break-all;
        color: #346aed;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      &_title {
        width: 100%;
        margin-right: 5px;
      }

      &_body {
        font-weight: 400;
        width: 100%;
        word-break: break-all;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
