/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api from "../../api/mail";

// initial state
const state = {
  mail_list: [],
};

// getters
const getters = {
  list(state) {
    return state.mail_list;
  },
};

// actions
const actions = {
  async index({ commit }, to) {
    try {
      commit("error/clear", undefined, { root: true });
      const mail_list = await api.mail_list(to);
      commit("mail_list", mail_list);
      return mail_list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async send({ commit }, { message, to }) {
    try {
      commit("error/clear", undefined, { root: true });
      const row = await api.send(to, message);
      commit("add", row);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  mail_list(state, list) {
    state.mail_list = list;
  },
  add(state, message) {
    state.mail_list.push(message);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
