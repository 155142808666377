/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_act from "../../api/supplieract";
import openBlobOtherWindow from "../../middleware/openBlobOtherWindow";
import { isEmpty } from "../../middleware/functions";

// initial state
// shape: [{ supplier }]
const state = {
  supplier_id: 0,
  act_list: [],
  all_act_list: [],
  summary: {},
};

// getters
const getters = {
  summary(state) {
    return state.summary;
  },
  all_act_list(state) {
    return state.all_act_list;
  },
  act_list(state) {
    return state.act_list;
  },
  supplier_id(state) {
    return state.supplier_id;
  },
};

// actions
const actions = {
  async load_act_list_by_range({ commit }, { started_at, finished_at }) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_act.load_act_list_by_range(
        started_at,
        finished_at
      );
      commit("all_act_list", list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async csv_act_list({ commit }, { started_at, finished_at, status }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_act.csv_act_list_by_range(
        started_at,
        finished_at,
        status
      );
      openBlobOtherWindow(
        file,
        started_at + finished_at + status + ".csv",
        ref
      );
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async sup_act_list_to1c({ commit }, { month, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_act.sup_act_list_by_month_to1c(month);
      const filename = "supplier_acts_" + month + ".xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_act_summary_by_range(
    { commit },
    { started_at, finished_at, city }
  ) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_act.load_act_summary_by_range(
        started_at,
        finished_at,
        city
      );
      commit("act_summary", list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_attractions_compare({ commit }, { started_at, finished_at }) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_act.load_attractions_compare(
        started_at,
        finished_at
      );
      commit("all_act_summary", list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async create_act({ commit, state }, payment_keys) {
    try {
      commit("error/clear", undefined, { root: true });
      const acts = state.all_act_list.filter(
        (act) =>
          payment_keys.indexOf(act.payment_key) !== -1 &&
          act.status === "to_create"
      );
      const bulk_data = await api_act.bulk_act_add(acts);
      commit("bulk_act_replace", bulk_data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete_act({ commit }, { supplier_id, id }) {
    try {
      await api_act.act_delete(supplier_id, id);
      commit("act_delete", id);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async validate_act({ commit }, act) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_act.act_validate(act);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async upgrade_act({ commit }, act) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await api_act.act_upgrade(act.id);
      commit("bulk_act_patch", [data]);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async patch_act({ commit }, patch) {
    try {
      commit("error/clear", undefined, { root: true });
      if (!patch.ids.length) {
        return;
      }
      const bulk_data = await api_act.bulk_act_update(patch);
      commit("bulk_act_patch", bulk_data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async generate_act({ commit }, { act, ref, no_download }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_act.generate(act.supplier_id, act.id, no_download);
      commit("bulk_act_pdf", act);
      if (!no_download) {
        openBlobOtherWindow(file, act.name + ".pdf", ref);
      }
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async generate_order_ru({ commit }, act) {
    try {
      commit("error/clear", undefined, { root: true });
      const new_act = await api_act.ru_order_generate(act.supplier_id, act.id);
      commit("bulk_act_replace", [new_act]);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async generate_order_by({ commit }, act) {
    try {
      commit("error/clear", undefined, { root: true });
      const new_act = await api_act.by_order_generate(act.supplier_id, act.id);
      commit("bulk_act_replace", [new_act]);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_act({ commit }, { act, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_act.download(act.supplier_id, act.id);
      openBlobOtherWindow(file, act.name + ".pdf", ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_bank_order({ commit }, { started_at, finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await api_act.download_bank_order(
        started_at,
        finished_at
      );
      const file = new Blob([response.data], { type: response.type });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.match(/\"([^\"]+)\"/)[1];
      openBlobOtherWindow(file, fileName, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_bank_order_xml({ commit }, { started_at, finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await api_act.download_bank_order_xml(
        started_at,
        finished_at
      );
      const file = new Blob([response.data], { type: response.type });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.match(/\"([^\"]+)\"/)[1];
      openBlobOtherWindow(file, fileName, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async export_to_1c({ commit }, { ids, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await api_act.export_to_1c(ids);
      const file = new Blob([response.data], { type: response.type });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.match(/\"([^\"]+)\"/)[1];
      openBlobOtherWindow(file, fileName, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_acts({ commit }, { name, ids, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_act.download_acts(name, ids);
      openBlobOtherWindow(file, name + ".pdf", ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async get_act_list_by_supplier({ commit }, supplier_id) {
    if (state.supplier_id === supplier_id) {
      return state.act_list;
    }
    commit("act_list", []);
    if (isEmpty(state.act_list)) {
      commit("act_list_cache", supplier_id);
    }
    if (isEmpty(state.act_list)) {
      await actions.load_act_list_by_supplier({ commit }, supplier_id);
    }
  },
  async load_act_list_by_supplier({ commit }, supplier_id) {
    try {
      commit("error/clear", undefined, { root: true });
      const act_list = await api_act.act_list_by_supplier(supplier_id);
      commit("act_list", act_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  all_act_list(state, data) {
    state.all_act_list = data;
  },
  all_act_summary(state, summary) {
    state.summary = summary;
  },
  act_summary(state, summary) {
    const local_summary = {
      allsports: {
        name: "acts",
        total: 0,
        supplier_count: 0,
        amount: 0,
        aggregator: "allsports",
      },
      allsports_super: {
        name: "acts",
        total: 0,
        supplier_count: 0,
        amount: 0,
        aggregator: "allsports_super",
      },
    };
    state.all_act_list.map((item) => {
      if (item.status === "to_create") {
        return;
      }
      const local = local_summary[item.aggregator];
      local.amount += Number(item.visits);
      local.total += Number(item.money);
      local.supplier_count += 1;
    });
    state.summary = [
      local_summary["allsports"],
      local_summary["allsports_super"],
    ].concat(summary);
  },
  bulk_act_replace(state, acts) {
    acts.map((new_act) => {
      let act = state.all_act_list.find(
        (act) => act.payment_key === new_act.payment_key
      );
      Object.assign(act, new_act);
    });
  },
  act_delete(state, id) {
    state.all_act_list.splice(
      state.all_act_list.findIndex((card) => card.id === id),
      1
    );
  },
  bulk_act_pdf(state, oldAct) {
    let act = state.all_act_list.find((act) => act.id === oldAct.id);
    if (act.status === "no_pdf") {
      Object.assign(act, { status: "generated" });
    }
  },
  bulk_act_patch(state, acts) {
    acts.map((new_act) => {
      let act = state.all_act_list.find((act) => act.id === new_act.id);
      Object.assign(act, new_act);
    });
  },
  act_list_cache(state, id) {
    state.supplier_id = id;
    const item = localStorage.getItem("app_supplier_item_" + id);
    if (item) {
      state.act_list = JSON.parse(item);
    }
  },
  act_list(state, list) {
    state.act_list = list;
    localStorage.setItem(
      "app_supplier_item_" + state.supplier_id,
      JSON.stringify(list)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
