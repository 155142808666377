<template>
  <div class="container">
    <h1>Manage Attractions Connections</h1>
    <BreadCrumbs />
    <SpinnerBrand v-if="processingMethods.GET" centered />
    <template v-else>
      <ErrorBanner :message="errors.errorMessage" />
      <table class="content">
        <thead>
          <tr>
            <td>Integrator attraction</td>
            <td>Supplier attraction</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="intAttraction in state.integrationAttractions" :key="`integration-attraction-${intAttraction.id}`">
            <td>{{ intAttraction.name }} ({{ intAttraction.price }})</td>
            <td>
              <NewSelectInput
                :disabled="intAttraction.processing"
                :modelValue="intAttraction.system_attraction_id"
                :options="getAttractionsOptions(intAttraction)"
                @select="updateAttractionsConnection(intAttraction.id, $event)"
              />
            </td>
            <td>
              <SpinnerRadial v-if="intAttraction.processing" />
              <a
                v-else-if="intAttraction.system_attraction_id"
                href="#"
                class="delete-connection"
                @click.prevent="updateAttractionsConnection(intAttraction.id, null)"
              >
                <Icon icon="delete" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "@vue/composition-api";

import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import Icon from "@/components/icons/Icon.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import SpinnerRadial from "@/components/loaders/SpinnerRadial.vue";

import useRequest from "@/composables/network/useRequest";
import URLS from "@/config/urls";
import { useRoute } from "@/helpers/router/routeCompositionAPI";

export default {
  components: {
    BreadCrumbs,
    ErrorBanner,
    Icon,
    NewSelectInput,
    SpinnerBrand,
    SpinnerRadial,
  },

  setup() {
    const route = useRoute();
    const { errors, fetch, processingMethods } = useRequest({
      errorsFormat: "flat",
    });

    const state = reactive({
      supplierAttractions: [],
      integrationAttractions: [],
    });

    const supplierId = computed(() => route.value.params.supplierId);
    const supplierAttractionsOptions = computed(() => {
      return state.supplierAttractions.map(({ id, name }) => ({ text: name, value: id }));
    });
    const supplierAttractionsUnusedOptions = computed(() => {
      const usedSupplierAttractionsIdList = state.integrationAttractions
        .map(({ system_attraction_id }) => system_attraction_id)
        .filter(Boolean);

      const supplierAttractionsUnusedOptions = supplierAttractionsOptions.value.filter(
        ({ value }) => !usedSupplierAttractionsIdList.includes(value)
      );

      return supplierAttractionsUnusedOptions;
    });

    function getAttractionsOptions(serviceAttraction) {
      if (serviceAttraction.system_attraction_id) {
        return supplierAttractionsOptions.value;
      }

      return supplierAttractionsUnusedOptions.value;
    }

    async function getData() {
      const results = await Promise.all([
        fetch(URLS.attractions.getBySupplier(supplierId.value)),
        fetch(URLS.integrations.attractions.supplier(supplierId.value)),
      ]);

      const isError = results.some(({ error }) => !!error);

      if (!isError) {
        const [supplierAttractionsResult, integrationAttractionsResult] = results;

        state.supplierAttractions = supplierAttractionsResult.data;
        state.integrationAttractions = integrationAttractionsResult.data;
      }
    }

    function setAttractionProcessingStatus(serviceAttractionId, newProcessingStatus = true) {
      const newState = { processing: newProcessingStatus };
      updateServiceAttraction(serviceAttractionId, newState);
    }

    function updateServiceAttraction(serviceAttractionId, newState = {}) {
      const serviceAttractionIndex = state.integrationAttractions.findIndex(({ id }) => id === serviceAttractionId);
      const serviceAttraction = state.integrationAttractions[serviceAttractionIndex];
      const updatedServiceAttraction = {
        ...serviceAttraction,
        ...newState,
      };

      state.integrationAttractions.splice(serviceAttractionIndex, 1, updatedServiceAttraction);
    }

    async function updateAttractionsConnection(serviceAttractionId, supplierAttractionId) {
      const url = URLS.integrations.attractions.update(supplierId.value, serviceAttractionId);
      const data = {
        system_attraction_id: supplierAttractionId,
      };

      setAttractionProcessingStatus(serviceAttractionId);

      const { data: newAttractionState, error } = await fetch({
        url,
        data,
        method: "PUT",
      });

      if (!error) {
        updateServiceAttraction(serviceAttractionId, newAttractionState);
      }

      setAttractionProcessingStatus(serviceAttractionId, false);
    }

    onBeforeMount(() => {
      getData();
    });

    return {
      errors,
      processingMethods,

      state,

      supplierAttractionsOptions,
      supplierAttractionsUnusedOptions,

      getAttractionsOptions,
      updateAttractionsConnection,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 640px;
  border-collapse: collapse;

  thead {
    tr {
      td {
        padding: 0.5em 0 1em;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid #aaa;

      td {
        font-size: 16px;
        padding-top: 24px;
        padding-left: 8px;
        padding-right: 8px;

        &:nth-child(1) {
          padding-bottom: 24px;
          width: 40%;
        }

        &:nth-last-child(1) {
          width: 48px;
          padding-top: 0;
        }
      }
    }
  }

  .delete-connection {
    opacity: 0.4;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.1s linear;
    }
  }
}
</style>
