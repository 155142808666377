<template>
  <jet-form-section>
    <template #title> Create Ticket </template>

    <template #description> Create a new ticket if import is broken and we need to add new tickets fast. </template>

    <template #form>
      <div class="col-span-6">
        <jet-label value="Ticket" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ supplier.name }}</div>
            <div class="text-gray-700 text-sm">{{ supplier.address }}</div>
          </div>
        </div>
      </div>

      <div class="col-span-6 sm:col-span-4">
        <jet-label for="value" value="Ticket Value" />
        <jet-input id="value" type="text" class="mt-1 block w-full" v-model="form.value" autofocus />
        <jet-input-error :message="form.errors.value" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-button @click="fireCreateTicket" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Add
      </jet-button>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import { mapActions } from "vuex";

export default {
  components: {
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
  },

  props: ["supplier", "attraction"],

  data() {
    return {
      form: {
        value: "",
        supplier_id: this.supplier.id,
        attraction_id: this.attraction.id,
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      createTicket: "ticket/store_ticket",
    }),
    async fireCreateTicket() {
      const output = await this.createTicket(this.form);
      if (output) {
        this.form.errors = output;
      } else {
        this.$router.push({ name: "holder_ticket_list", query: { value: this.form.value } });
      }
    },
  },
};
</script>
