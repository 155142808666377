<template>
  <div class="map-radius-selector_container">
    <h4>{{ $t("map.radius_selector_title") }}</h4>
    <ControlledNumberInput v-model="radiusValue" class="map-radius-selector_controls" postfix="km" />
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import ControlledNumberInput from "@/components/inputs/ControlledNumberInput.vue";

export default {
  emits: ["input"],

  components: {
    ControlledNumberInput,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const radiusValue = computed({
      get() {
        return props.value;
      },
      set(val) {
        emit("input", val);
      },
    });

    return {
      radiusValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.map-radius-selector {
  &_container {
    width: 220px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
  }

  h4 {
    font-family: "Montserrat", "DejaVu Sans", "Verdana", sans‑serif;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
  }
}
</style>
