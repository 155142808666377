<template>
  <small-layout>
    <div>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">Ticket</h2>

      <div>
        <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
          <div>
            <update-ticket-information-form v-if="ticket" :ticket="ticket" />

            <jet-section-border />
          </div>
        </div>
      </div>
    </div>
  </small-layout>
</template>

<script>
import JetSectionBorder from "../../Jetstream/SectionBorder";
import UpdateTicketInformationForm from "./UpdateTicketInformationForm";
import { mapActions } from "vuex";

export default {
  components: {
    JetSectionBorder,
    UpdateTicketInformationForm,
  },
  data() {
    return {
      ticket: null,
    };
  },
  methods: {
    ...mapActions({
      loadTicket: "ticket/load_ticket",
    }),
  },
  async mounted() {
    this.ticket = await this.loadTicket(this.$route.params.ticket);
  },
};
</script>
