<template>
  <div class="table-pagination" :class="paginationClass">
    <ul class="table-pagination_controls">
      <li>
        <button :class="getPaginationButtonClass('prev')" :disabled="isPrevButtonDisabled" @click="onPrevPage">
          «
        </button>
      </li>

      <li v-for="page in numbersPosition.start" :key="`pagination-${_uid}-start-${page}`">
        <button :class="getPaginationButtonClass(page)" @click="onPageSelect(page)">{{ page }}</button>
      </li>

      <TablePaginationPageSelect :items="numbersPosition.preCenter" :parent-id="_uid" @select="onPageSelect" />

      <li v-for="page in numbersPosition.center" :key="`pagination-${_uid}-cntr-${page}`">
        <button :class="getPaginationButtonClass(page)" @click="onPageSelect(page)">
          <SpinnerRadial v-if="isPageProcessing(page)" />
          <template v-else>{{ page }}</template>
        </button>
      </li>

      <TablePaginationPageSelect :items="numbersPosition.postCenter" :parent-id="_uid" @select="onPageSelect" />

      <li v-for="page in numbersPosition.end" :key="`pagination-${_uid}-end-${page}`">
        <button :class="getPaginationButtonClass(page)" @click="onPageSelect(page)">{{ page }}</button>
      </li>

      <li>
        <button :class="getPaginationButtonClass('next')" :disabled="isNextButtonDisabled" @click="onNextPage">
          »
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref } from "@vue/composition-api";
import isNumber from "lodash/isNumber";
import range from "lodash/range";

import SpinnerRadial from "@/components/loaders/SpinnerRadial.vue";
import TablePaginationPageSelect from "./components/TablePaginationPageSelect.vue";

const PAGES_OFFSET = 4;

const MODEL_CONFIG = {
  prop: "modelValue",
  event: "update:modelValue",
};

export default {
  components: {
    SpinnerRadial,
    TablePaginationPageSelect,
  },

  model: MODEL_CONFIG,

  emits: [MODEL_CONFIG.event],

  props: {
    centered: {
      type: Boolean,
      required: false,
    },
    pageChangeCallback: {
      type: Function,
      required: false,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    [MODEL_CONFIG.prop]: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const pageChangeCallbackProcessing = ref(null);

    const numbersPosition = computed(() => {
      const position = {
        center: [],
        start: [],
        end: [],
        preCenter: [],
        postCenter: [],
      };

      if (props[MODEL_CONFIG.prop] <= PAGES_OFFSET) {
        const lastPage = Math.min(props.totalPages + 1, PAGES_OFFSET + 2);
        position.center = range(1, lastPage);
      } else if (props[MODEL_CONFIG.prop] > props.totalPages - PAGES_OFFSET) {
        position.center = range(props.totalPages - PAGES_OFFSET, props.totalPages + 1);
      } else {
        position.center = range(props[MODEL_CONFIG.prop] - 1, props[MODEL_CONFIG.prop] + 2);
      }

      if (!position.center.includes(1)) {
        position.start = [1];
        position.preCenter = range(2, position.center[0]);
      }

      if (!position.center.includes(props.totalPages)) {
        position.end = [props.totalPages];
        position.postCenter = range(position.center.slice(-1)[0] + 1, props.totalPages);
      }

      return position;
    });

    const paginationClass = computed(() => ({
      wide: props.centered,
    }));

    const isPrevButtonDisabled = computed(() => {
      return props[MODEL_CONFIG.prop] === 1;
    });

    const isNextButtonDisabled = computed(() => {
      return props[MODEL_CONFIG.prop] === props.totalPages;
    });

    const isPageProcessing = (page) => {
      return pageChangeCallbackProcessing.value == page;
    };

    function getPaginationButtonClass(page) {
      if (isNumber(page)) {
        if (props[MODEL_CONFIG.prop] === page) {
          return ["active", "disabled"];
        }
      } else {
        if (page === "prev" && isPrevButtonDisabled.value) {
          return "disabled";
        }

        if (page === "next" && isNextButtonDisabled.value) {
          return "disabled";
        }
      }

      return "";
    }

    async function dispatchEvent(newPage) {
      if (pageChangeCallbackProcessing.value !== null) {
        return;
      }

      const oldPage = props[MODEL_CONFIG.prop];

      emit(MODEL_CONFIG.event, newPage);

      if (props.pageChangeCallback) {
        pageChangeCallbackProcessing.value = newPage;
        await props.pageChangeCallback(newPage, oldPage);
        pageChangeCallbackProcessing.value = null;
      }
    }

    function onPrevPage() {
      if (props[MODEL_CONFIG.prop] > 1) {
        dispatchEvent(props[MODEL_CONFIG.prop] - 1);
      }
    }

    function onNextPage() {
      if (props[MODEL_CONFIG.prop] < props.totalPages) {
        dispatchEvent(props[MODEL_CONFIG.prop] + 1);
      }
    }

    function onPageSelect(page) {
      if (page !== props[MODEL_CONFIG.prop]) {
        dispatchEvent(page);
      }
    }

    function onSelectPage(e) {
      dispatchEvent(Number(e.target.value));
    }

    return {
      props,

      numbersPosition,
      paginationClass,
      isPrevButtonDisabled,
      isNextButtonDisabled,
      isPageProcessing,

      getPaginationButtonClass,
      onPrevPage,
      onNextPage,
      onPageSelect,
      onSelectPage,
    };
  },
};
</script>

<style lang="scss">
.table-pagination {
  & &_controls {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    list-style-type: none;
    overflow: hidden;

    li {
      float: left;
      position: relative;

      button {
        background: #fff;
        width: 34px;
        height: 30px;
        font-weight: 400;
        font-size: 15px;
        color: #444545;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          background-color: #e7e7e7;
        }

        &.disabled {
          color: lighten(#444545, 50%);
        }
      }

      select {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      &:nth-child(n + 2) {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:hover {
        button:not(.disabled) {
          background-color: #fafafa;
          cursor: pointer;
        }
      }
    }
  }

  &.wide {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
