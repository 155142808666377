/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  show_amount_of_gyms() {
    return axios.get(`/api/helpdesk/show_amount_of_gyms`).then((response) => {
      return response.data;
    });
  },
  helpdesk_holder_list(input) {
    let params = [];
    if (input.date && input.card_id) {
      params.push("date=" + input.date);
      params.push("card_id=" + input.card_id);
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/helpdesk/${query}`).then((response) => {
      return response.data;
    });
  },
  visit_list(tags) {
    let params = [];
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/helpdesk/${query}`).then((response) => {
      return response.data;
    });
  },
  visit_list_by_status(tags) {
    let params = [];
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/helpdesk/monthly/${query}`).then((response) => {
      return response.data;
    });
  },
  manually_update_visit(visit) {
    return axios
      .patch(
        `/api/helpdesk/manually_update_visit?id=${visit.id}&status=${visit.status}&reason=${visit.reason}&attraction_id=${visit.attraction_id}`
      )
      .then((response) => {
        return response.data;
      });
  },
  view_visit(id) {
    return axios.get(`/api/helpdesk/visit/${id}`).then((response) => {
      return response.data.data;
    });
  },
  update_visit_radius({ id, radius } = {}) {
    return axios
      .put(`/api/helpdesk/visit/${id}/update_radius`, {
        radius,
      })
      .then((response) => {
        return response.data;
      });
  },
  view_holder(id) {
    return axios.get(`/api/helpdesk/card/${id}`).then((response) => {
      return response.data;
    });
  },
  manually_create_visit_card(visit) {
    return axios
      .patch(
        `/api/helpdesk/manually_create_visit_card?id=${visit.id}&card_id=${
          visit.card_id
        }&created_at=${
          visit.created_at ? visit.created_at : new Date().toJSON()
        }&timedelta=${visit.timedelta}&supplier_id=${
          visit.supplier_id
        }&status=${visit.status}&reason=${visit.reason}&attraction_id=${
          visit.attraction_id
        }`
      )
      .then((response) => {
        return response.data;
      });
  },
  manually_create_visit(visit) {
    return axios
      .patch(
        `/api/helpdesk/manually_create_visit_card?id=${visit.id}&card_id=${visit.card_id}&created_at=${visit.created_at}&timedelta=${visit.timedelta}&supplier_id=${visit.supplier_id}&status=${visit.status}&reason=${visit.reason}&attraction_id=${visit.attraction_id}`
      )
      .then((response) => {
        return response.data;
      });
  },
  manually_update_visit_card(visit) {
    return axios
      .patch(
        `/api/helpdesk/manually_update_visit_card?id=${visit.id}&card_id=${visit.card_id}&timedelta=${visit.timedelta}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  manually_patching_visit(visit) {
    return axios
      .get(`/api/helpdesk/manually_patching_visit?id=${visit.id}`)
      .then((response) => {
        return response.data.data;
      });
  },
  load_holder_by(card_id, holder) {
    if (!card_id) {
      card_id = "";
    }
    if (!holder) {
      holder = "";
    }
    return axios
      .get(`/api/helpdesk/load_holder_by?card_id=${card_id}&holder=${holder}`)
      .then((response) => {
        return response.data.data;
      });
  },
  load_cities() {
    return axios.get("/api/jrnl/admin/cities/index").then((response) => {
      return response.data.data;
    });
  },
  update_city(city, support_phone) {
    return axios.patch(`/api/jrnl/admin/cities/${city}`, {
      city,
      support_phone,
    });
  },
};
