/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  payment_list(params) {
    let list = [];
    if (params) {
      Object.getOwnPropertyNames(params).forEach((name) => {
        list.push(name + "=" + params[name]);
      });
    }
    let query = list.length > 0 ? "?" + list.join("&") : "";
    return axios.get(`/api/bsb_payments${query}`).then((response) => {
      return response.data;
    });
  },
  update_payment(payment) {
    return axios
      .patch(`/api/bsb_payments/${payment.id}`, {
        patch: {
          transactable_type: payment.transactable_type,
          transactable_id: payment.transactable_id,
          claim_id: payment.claim_id,
        },
      })
      .then((response) => {
        return response.data;
      });
  },
};
