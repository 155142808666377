<template>
  <fieldset>
    <legend>{{ $t("client.actions.updating_title") }}</legend>
    <div>
      <label for="client_phone">{{ $t("client.columns.phone") }}</label>
      <input id="client_phone" type="tel" v-model="client.phone" />
    </div>
    <div>
      <label for="client_email">{{ $t("client.columns.email") }}</label>
      <input id="client_email" type="tel" v-model="client.email" />
    </div>
    <div>
      <label for="client_name">{{ $t("client.columns.name") }}</label>
      <input id="client_name" type="tel" v-model="client.name" />
    </div>
    <div>
      <label for="client_position">{{ $t("client.columns.position") }}</label>
      <input id="client_position" type="tel" v-model="client.position" />
    </div>
    <div>
      <button @click="confirm">{{ $t("client.actions.update_title") }}</button>
    </div>
  </fieldset>
</template>

<script>
import { ref } from "@vue/composition-api";
export default {
  props: {
    value: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    const client = ref(props.value);
    const confirm = () => {
      context.emit("input", client.value);
    };
    return {
      client,
      confirm,
    };
  },
};
</script>

<style scoped></style>
