<template>
  <small-layout>
    <table>
      <thead>
        <tr>
          <th v-for="item in columns">
            {{ item.name }}
          </th>
          <th>{{ $t("attraction.commands") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in source">
          <template v-for="column in columns">
            <td>{{ entry[column.key] }}</td>
          </template>
          <td>
            <button @click.prevent="() => fireDeleteItem(entry)">{{ $t("attraction.cmd_delete") }}</button>
          </td>
        </tr>
      </tbody>
    </table>
  </small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      params: {
        offset: 0,
        limit: 100,
      },
      columns: [
        { key: "sid", name: "SID" },
        { key: "id", name: "id" },
        { key: "name", name: "name" },
        { key: "levels", name: "levels" },
        { key: "list", name: "list" },
        { key: "activated_at", name: "activated_at" },
        { key: "published_at", name: "published_at" },
        { key: "command", name: "command" },
        { key: "price", name: "price" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      source: "supplier/scheduling_list",
    }),
  },
  methods: {
    ...mapActions({
      fetchSchedules: "supplier/schedule_list",
      destroy: "supplier/schedule_destroy",
    }),
    fireDeleteItem(attraction) {
      if (confirm("Are you sure?")) {
        this.destroy(attraction);
      }
    },
  },
  async mounted() {
    await this.fetchSchedules(this.params);
  },
};
</script>

<style scoped></style>
