<template>
  <div class="container subscriber-audit-view">
    <div class="row-header">
      <h1>Subscriber audit</h1>
      <LocalSearchInput v-if="!state.processing" v-model="state.localSearchValue" />
    </div>
    <ErrorBanner class="error_banner" :message="state.errors.errorMessage" />
    <SpinnerAllsports v-if="state.processing" :wrapped="true" centered />
    <SimpleTable
      v-else
      :cells="TABLE_CELLS"
      :extra-cells="TABLE_EXTRA_CELLS"
      :headers="TABLE_HEADERS"
      :items="filteredAuditList"
    />

    <paginate
      v-if="isShowPagination"
      :page-count="state.lastPage"
      :page-range="3"
      :margin-pages="2"
      v-model="state.currentPage"
      :click-handler="changePage"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'pagination subscriber-audit-view__pagination'"
      :page-class="'page-item'"
      :prev-link-class="'page-link'"
      :next-link-class="'page-link'"
      :page-link-class="'page-link'"
    />
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount } from "@vue/composition-api";
import isObject from "lodash/isObject";
import Paginate from "vuejs-paginate";
import axios from "axios";

import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import LocalSearchInput from "@/components/inputs/LocalSearchInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";

import { AUDITABLE_TYPES, URLS } from "@/config/index";
import router, { ROUTES_NAMES } from "@/router";
import { prepareAxiosErrors } from "@/helpers/index";

const TABLE_HEADERS = ["Changed by", "IP-address", "UserAgent", "Changed at", "Changed fields"];
const TABLE_CELLS = [
  ({ user }) => (user ? `${user.name}<br />${user.email}` : "–"),
  "ip_address",
  "user_agent",
  "created_at",
  ({ updatedFields }) => updatedFields.join("<br />"),
];
const TABLE_EXTRA_CELLS = [
  {
    tag: "router-link",
    text: "Details",
    attrs: (item) => {
      const to = {
        name: ROUTES_NAMES.subscribers.auditDetails,
        params: { auditId: item.id },
      };

      return { to };
    },
  },
];

export default {
  components: {
    ErrorBanner,
    LocalSearchInput,
    SimpleTable,
    SpinnerAllsports,
    Paginate,
  },

  setup() {
    const state = reactive({
      processing: false,
      errors: {},

      auditList: [],

      localSearchValue: "",

      currentPage: 1,
      lastPage: 1,
    });

    const filteredAuditList = computed(() => {
      const searchableFields = ["auditable_id", "id", "new_values", "old_values", "user_agent", "user"];
      const filteredList = state.auditList.filter((audit) => {
        let searchStringsList = [];

        searchableFields.forEach((field) => {
          if (isObject(audit[field])) {
            searchStringsList = searchStringsList.concat(Object.entries(audit[field]));
          } else {
            searchStringsList.push(audit[field]);
          }
        });

        const searchString = searchStringsList.join(" ");

        return searchString.includes(state.localSearchValue);
      });

      return filteredList;
    });

    const isShowPagination = computed(() => {
      return state.lastPage !== 1;
    });

    async function getData() {
      state.errors = {};
      state.processing = true;
      state.auditList = [];

      const url = URLS.subscribers.audit;
      const { subscriberId } = router.currentRoute.params;
      const data = {
        auditable_id: subscriberId,
        auditable_type: AUDITABLE_TYPES.card,
        page: state.currentPage,
      };

      try {
        const response = await axios.post(url, data);
        const result = response?.data?.data;
        state.lastPage = response?.data?.meta?.last_page;

        state.auditList = result.map((item) => ({
          ...item,
          updatedFields: Object.keys(item.new_values),
        }));
      } catch (error) {
        state.errors = prepareAxiosErrors(error);
      }

      state.processing = false;
    }

    function changePage(page) {
      state.currentPage = page;
      getData();
    }

    onBeforeMount(() => {
      getData();
    });

    return {
      TABLE_HEADERS,
      TABLE_CELLS,
      TABLE_EXTRA_CELLS,

      state,

      filteredAuditList,

      changePage,
      isShowPagination,
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 24px;
}

.subscriber-audit-view {
  &__pagination {
    margin-top: 20px;
  }
}
</style>
