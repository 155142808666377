const DEFAULT_ORDER_KEY = "id";

export default {
  data() {
    return {
      orderKey: DEFAULT_ORDER_KEY,
      filterKey: "",
      filterCity: "",
      columns: this.createColumns(),
    };
  },
  computed: {
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.columns[index].order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (this.filterCity) {
        const filterCity = this.filterCity;
        data = data.filter(function (row) {
          return row.city === filterCity;
        });
      }
      if (orderKey && index !== -1) {
        this.columns[index].order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      if (index === -1) {
        console.log(
          `${index} cannot be -1. We are looking for ${orderKey} in our columns.`
        );
      }
      return data;
    },
  },
  methods: {
    sortBy: function (key) {
      let sortOrderModificator = 1;

      if (this.orderKey === key) {
        sortOrderModificator = -1;
      }

      this.orderKey = key;
      const index = this.columns.findIndex((column) => column.key === key);

      if (index !== -1) {
        this.columns[index].order =
          this.columns[index].order * sortOrderModificator;
        return;
      }

      console.log(
        `${index} cannot be -1. We are looking for ${key} in our columns.`
      );
    },
  },
};
