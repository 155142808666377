<template>
  <small-layout>
    <div>
      <button :disabled="summary_card_types_disabled" v-if="isSuperAdmin" @click="fireDownloadLevelsStatistics">
        Card Types Statistics by Month
      </button>
      <button :disabled="summary_stats_disabled" v-if="isSuperAdmin" @click="fireDownloadSummaryStatistics">
        Summary Statistics by Month
      </button>
      <button :disabled="summary_stats_disabled" v-if="isSuperAdmin" @click="fireDownloadB2BStatistics">
        B2B Statistics by Month
      </button>
      <button :disabled="summary_stats_disabled" v-if="isSuperAdmin" @click="fireDownloadB2CStatistics">
        B2С Statistics by Month
      </button>
      <a ref="invoice_download_ref">__</a>

      <button @click.prevent="toggleDeleteButtons = !toggleDeleteButtons">toggle deletion</button>
      <button @click.prevent="toggleColumnForm = !toggleColumnForm">
        {{ toggleColumnForm ? "hide" : "show" }} columns
      </button>
      <form v-if="toggleColumnForm" id="columnFormToggler" @submit.prevent="addColumnForm">
        <div>
          <label for="column_name">Имя столбца</label>
          <input id="column_name" type="text" />
        </div>
        <div>
          <label for="column_key">Поле данных столбца</label>
          <select id="column_key">
            <option v-for="column in all_columns" :value="column.key">{{ column.name }}</option>
          </select>
        </div>
        <button type="submit">Сохранить</button>
        <button type="button" @click.prevent="clearColumnsAndRefresh">Reset</button>
      </form>
      <fieldset>
        <label for="stat_filter_at">Filter At:</label>
        <input id="stat_filter_at" type="month" :value="params.filter_at" @input="updateFilterAt" />
        <button @click.prevent="loadClaimList(tags_from_params)">Update</button>
        <div>
          <div v-if="filter_ids_toggle" class="status-on">filter from companies applied</div>
          <label for="change_company_id">New company:</label>
          <input id="change_company_id" type="text" v-model="params.company" v-on:keyup.enter="setParamsCompany" />
          {{ filterCompanies.length }} Found
          <label for="add_company_claim" :class="params.status === 'ready' ? 'status-on' : 'status-off'">
            Status {{ params.status }}
          </label>
          <select id="add_company_claim" @input="addCompany">
            <option value=""></option>
            <option v-for="company in filterCompanies" :value="company.id">
              {{ company.name }}
            </option>
          </select>
          <div>
            <router-link target="_new_company" :to="{ name: 'company_create', params: { country } }">
              Create Company
            </router-link>
            <router-link
              target="_claim_card_list"
              :to="{
                name: 'claim_card_list',
                params: { filter_at: params.filter_at },
                query: { company: params.company },
              }"
              >On/Off/New Cards {{ params.filter_at }}</router-link
            >
            <router-link target="_company_list" :to="{ name: ROUTES_NAMES.companies.list }">Company List</router-link>
            <a
              v-if="claim_company && claim_company.google_sheet_id"
              target="_blank"
              :href="'https://docs.google.com/spreadsheets/d/' + claim_company.google_sheet_id + '/edit#gid=0'"
              >Open {{ claim_company.name }}</a
            >
          </div>
          <button @click="claimAdvanced = !claimAdvanced">Advanced</button>
          <div v-if="claimAdvanced">
            <label for="with_deleted">Show deleted acts and invoices</label>
            <input type="checkbox" id="with_deleted" v-model="params.with_deleted" :false-value="0" :true-value="1" />

            <label for="params_off_cleanup">Cleanup off during Export</label>
            <input type="checkbox" id="params_off_cleanup" v-model="params_off.cleanup" />

            <label for="params_off_validation">validation off during Export</label>
            <input type="checkbox" id="params_off_validation" v-model="params_off.validation" />

            <label for="params_off_condition">condition off during Export</label>
            <input type="checkbox" id="params_off_condition" v-model="params_off.condition" />

            <label for="params_off_import_only">Import Only when do Import</label>
            <input type="checkbox" id="params_off_import_only" v-model="params_off.import_only" />

            <div>
              <label for="choose_card_parse_mode">Import By Cards/Phones/Both</label>
              <select id="choose_card_parse_mode" v-model="params.idMode">
                <option value="cards">Cards</option>
                <option value="phones">Phones</option>
                <option value="cards|phones">BothP</option>
              </select>
              <label for="choose_card_parse_missed">Cancel missed or continue</label>
              <select id="choose_card_parse_missed" v-model="params.missed_card_status">
                <option value="reject">Reject</option>
                <option value="continue">Continue</option>
              </select>
              <label for="choose_card_parse_default">Default card type if not set</label>
              <select id="choose_card_parse_default" v-model="params.defaultCardLevel">
                <option value="region">region</option>
                <option value="silver">silver</option>
                <option value="gold">gold</option>
                <option value="platinum">platinum</option>
              </select>
            </div>
            <div>
              <label for="stat_it_info">It descr:</label>
              <textarea
                id="stat_it_info"
                cols="50"
                rows="5"
                v-if="dictionaries.invoices"
                v-model="dictionaries.invoices.it"
              />
              <label for="stat_service_info">Service descr:</label>
              <textarea
                id="stat_service_info"
                cols="50"
                rows="5"
                v-if="dictionaries.invoices"
                v-model="dictionaries.invoices.service"
              />
              <label for="stat_percent">Percent to IT:</label>
              <input
                id="stat_percent"
                inputmode="numeric"
                type="number"
                v-if="dictionaries.invoices"
                v-model="dictionaries.invoices.percent"
              />
              <label for="stat_currency_info">currency descr:</label>
              <textarea
                id="stat_currency_info"
                cols="50"
                rows="5"
                v-if="dictionaries.invoices"
                v-model="dictionaries.invoices.currency"
              />
            </div>

            <div>
              <label for="prices"
                >Price:
                <span v-if="editableClaim"
                  >R - {{ editableClaim.region_price }}|S - {{ editableClaim.silver_price }}|G -
                  {{ editableClaim.gold_price }}|P - {{ editableClaim.platinum_price }}</span
                >
              </label>
              <textarea id="prices" style="width: 100%" v-model="dictionaries.price" />
            </div>
          </div>
          <div>
            <label for="it_balance">IT Leftovers</label>
            <input id="it_balance" type="number" v-model="editableClaim.it_balance" />
          </div>
          <div>
            <label for="service_balance">Service Leftovers</label>
            <input id="service_balance" type="number" v-model="editableClaim.service_balance" />
          </div>
          <div>
            <label for="start_at">Start Date</label>
            <input id="start_at" type="date" v-model="editableClaim.start_at" />
          </div>
          <div>
            <button v-if="claim_company && claim_company.google_sheet_id" @click.prevent="importGoogle">
              {{ "From Google Sheet" + (params_off.import_only ? "" : " + -> ") }}
            </button>
            <button v-if="claim_company" @click.prevent="exportToSheet">To Google Sheet</button>
            <button v-if="claim_company" @click.prevent="exportNextMonthSheet">Prepare to Next Month</button>
          </div>
        </div>
      </fieldset>
      <div class="row">
        <invoice-index
          v-if="dictionaries && dictionaries.invoices"
          :toggleDeleteButtons="toggleDeleteButtons"
          :templates="templates"
          :claim="invoiceClaim"
          @close="invoiceClaim = null"
          :filter_at="params.filter_at"
          :monthly="monthly"
          :dictionaries="dictionaries.invoices"
          :info_price="dictionaries.price"
        />
        <act-index
          v-if="dictionaries && dictionaries.invoices"
          :toggleDeleteButtons="toggleDeleteButtons"
          :templates="templates"
          :claim="actClaim"
          @close="actClaim = null"
          :filter_at="params.filter_at"
          :monthly="monthly"
          :dictionaries="dictionaries.invoices"
          :info_price="dictionaries.price"
        />
        <table v-if="exported.length">
          <tbody>
            <tr v-for="item in exported">
              <td v-for="value in item">{{ value }}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <span v-if="activeCsvChanged">
            Total rows: {{ arrayCsvObjects.length }}
            <button @click.prevent="upgradeListCsvArray(arrayColumns, arrayCsvObjects, params, changingHolderChanges)">
              Apply Changes
            </button>
          </span>
          <table>
            <tr v-for="item in monthly_status">
              <th>{{ item[0] }}</th>
              <td>{{ item[1] }}</td>
              <th>{{ item[2] }}</th>
              <td>{{ item[3] }}</td>
            </tr>
          </table>
          <button @click.prevent="addToEnd($refs.card_list)" v-if="!activeCsvItem">Add</button>

          <data-table
            ref="holder_changes"
            class="block2"
            :items="holderChanges"
            :per-page="500"
            @loaded="loadedHolderChanges"
          >
            <data-column field="0" label="#" />
            <data-column field="1" label="status" />
            <data-column field="2" label="New Value" />
          </data-table>
        </div>
        <div class="item" v-if="toggleFileCsv && arrayColumns && arrayColumns.length">
          from file {{ params.filter_at }} {{ editableClaim.company_id }}
          <div>
            <button v-if="!editableClaim.id" :class="activeCsvChanged ? 'status-on' : ''" @click.prevent="addToClaim">
              Add
            </button>
            <button v-if="editableClaim.id" :class="activeCsvChanged ? 'status-on' : ''" @click.prevent="editClaim">
              Update
            </button>
            <button @click="toggleFileCsv = !toggleFileCsv">Hide</button>
            <button @click="removeRecordsWithoutPhones()">Remove Records without Phones</button>
            <label for="monthly_tracked">Tracked</label
            ><input id="monthly_tracked" type="checkbox" v-model="arrayTracked" />
            <label for="monthly_errors">Errors</label
            ><input id="monthly_errors" type="checkbox" v-model="arrayConflicted" />
            <div
              v-if="activeCsvItem"
              tabindex="0"
              class="overlay"
              @click.prevent="overlayPreClose"
              @keyup="overlayKeyUpPreClose"
              role="overlay"
            >
              <fieldset
                role="model"
                class="content"
                @click.prevent="handleClick"
                :style="{
                  backgroundColor: 'lightgray',
                  position: 'fixed',
                  top: activeCsvRectangle.top + 'px',
                  left: activeCsvRectangle.left + 'px',
                  width: activeCsvRectangle.width + 'px',
                  height: activeCsvRectangle.height + 'px',
                }"
              >
                <button class="close" @click.prevent="saveCsvRow(activeCsvItem)">x</button>
                <span>{{ activeCsvItem.index }}</span>
                <input
                  style="width: auto"
                  aria-label="holder"
                  placeholder="holder"
                  type="text"
                  v-model="activeCsvItem.holder"
                />
                <input
                  size="8"
                  placeholder="status"
                  :class="getActivation(activeCsvItem.status, ['', 'reject', 'off'])"
                  aria-label="status"
                  type="text"
                  v-model="activeCsvItem.status"
                />
                <input
                  size="6"
                  placeholder="level"
                  :class="getActivation(activeCsvItem.level, [''])"
                  aria-label="level"
                  type="text"
                  v-model="activeCsvItem.level"
                />
                <input size="12" placeholder="phone" aria-label="phone" type="text" v-model="activeCsvItem.phone" />
                <input size="5" placeholder="card" aria-label="card" type="text" v-model="activeCsvItem.card" />
                <input size="25" placeholder="extra" aria-label="extra" type="text" v-model="activeCsvItem.extra" />
                <button @click.prevent="destroyCsvRow(activeCsvItem)">Delete</button>
              </fieldset>
            </div>
            <data-table
              ref="card_list"
              class="block1"
              :items="arrayCsvObjectsComp"
              :per-page="500"
              @loaded="loadedArrayObjects"
            >
              <data-column field="index" label="#" />
              <data-column field="holder" label="holder">
                <template slot-scope="props">
                  <span :class="props.item === activeCsvItem ? 'status-on' : ''">{{ props.item.holder }}</span>
                </template>
              </data-column>
              <data-column field="status" label="status" :sortable="false">
                <template slot-scope="props">
                  <span
                    @click="editingCsvRow(props.item, $event.target.parentElement.parentElement)"
                    :class="'clicker ' + getActivation(props.item.status, ['', 'reject', 'off'])"
                    >{{ props.item.status }}</span
                  >
                </template>
              </data-column>
              <data-column field="level" label="level" :sortable="false" />
              <data-column field="phone" label="phone" :sortable="false" />
              <data-column field="extra" label="extra" :sortable="false" />
              <data-column field="card" label="card" :sortable="false" />
            </data-table>
          </div>
        </div>
        <div class="block2">
          <table class="table-fixed">
            <thead>
              <tr>
                <template
                  v-for="item in columns"
                  @click.prevent="sortBy(item.key, columns)"
                  :class="{ active: orderKey === item.key }"
                >
                  <th :class="item.key === 'company_name' ? 'name_width' : ''">
                    {{ item.name }}
                    <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"></span>
                    <span class="remove_button" @click.prevent="removeColumn(item)">X</span>
                  </th>
                </template>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entity in filteredSource">
                <template v-for="column in columns">
                  <td v-if="column.key === 'filter_at'">
                    {{ String(entity.filter_at).replace(/(\d{2})(\d{2})/, "$1/$2") }}
                  </td>
                  <template v-else-if="column.key === 'company_name' && entity.company_id">
                    <td :class="buildCompanyClassName(entity)" :title="buildCompanyPlaceholder(entity)">
                      <router-link
                        target="_blank"
                        :to="{ name: 'company_update', params: { company: entity.company_id, country } }"
                        >{{ entity.company_name }}</router-link
                      >
                      <router-link
                        target="_blank"
                        :to="{ name: 'company_view', params: { company: entity.company_id, country } }"
                        >Cards</router-link
                      >
                    </td>
                  </template>
                  <td v-else-if="column.key === 'it_balance'">
                    {{ entity.it_balance | byn }}
                  </td>
                  <td v-else-if="column.key === 'service_balance'">
                    {{ entity.service_balance | byn }}
                  </td>
                  <td v-else-if="column.key === 'it_credit'">
                    <span :class="statusClassByResult(entity.it_balance + entity.it_debet - entity.it_credit)">{{
                      entity.it_credit | byn
                    }}</span>
                  </td>
                  <td v-else-if="column.key === 'service_credit'">
                    <span
                      :class="
                        statusClassByResult(entity.service_balance + entity.service_debet - entity.service_credit)
                      "
                      >{{ entity.service_credit | byn }}</span
                    >
                  </td>
                  <span v-else-if="column.key === 'expecting_credit'">
                    {{ entity.expecting_credit | byn }}
                  </span>
                  <td v-else-if="column.key === 'it_invoice'">
                    <span :class="statusClassByResult(entity.it_debet - entity.it_invoice)">{{
                      entity.it_invoice | byn
                    }}</span>
                  </td>
                  <td v-else-if="column.key === 'service_invoice'">
                    <span :class="statusClassByResult(entity.service_debet - entity.service_invoice)">{{
                      entity.service_invoice | byn
                    }}</span>
                  </td>
                  <td v-else-if="column.key === 'it_debet'">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'payments_list_params',
                        params: { aggregator: 'allsports', unn: entity.unn, claim_id: entity.id },
                      }"
                      >{{ entity.it_debet | byn }}</router-link
                    >
                  </td>
                  <td v-else-if="column.key === 'service_debet'">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'payments_list_params',
                        params: { aggregator: 'allsports_super', unn: entity.unn, claim_id: entity.id },
                      }"
                      >{{ entity.service_debet | byn }}</router-link
                    >
                  </td>
                  <td v-else>{{ entity[column.key] }}</td>
                </template>
                <td>
                  <span v-if="toggleDeleteButtons">
                    <button title="Delete" @click.prevent="fireDeletingClaim(entity)">&#10005;</button>
                  </span>
                  <button title="Edit" @click.prevent="fireEditClaim(entity)">
                    {{ entity.hr_portal_only ? $t("claims.list_hr_portal") : $t("claims.list_google") }}
                  </button>
                  <button title="Invoice" @click.prevent="fireNewInvoice(entity)">Invoice</button>
                  <button title="Act" @click.prevent="fireNewAct(entity)">Act</button>
                  <router-link :to="{ name: 'order_update', params: { order: entity.id } }">&#9998;</router-link>
                  <router-link :to="{ name: ROUTES_NAMES.orders.invoices.list, params: { orderId: entity.id } }">
                    Order invoices
                  </router-link>
                  <router-link :to="{ name: ROUTES_NAMES.orders.acts.list, params: { orderId: entity.id } }">
                    Order acts
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </small-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ColumnMixin from "../../mixins/ColumnMixin";

import {
  formatToRFC3339,
  generateCalculator,
  findFixedRectangle,
  createStartOfMonth,
} from "../../middleware/functions";
import InvoiceIndex from "./InvoiceIndex";
import ActIndex from "./ActIndex";
import { DataTable, DataColumn } from "vue-teible";
import { themeDefault, themeDark } from "teible";

import { ROUTES_NAMES } from "@/router";

export default {
  components: { InvoiceIndex, ActIndex, DataTable, DataColumn },
  mixins: [ColumnMixin],
  data() {
    const date = createStartOfMonth(null);
    date.setMonth(date.getMonth() + 1);
    const filterAt = formatToRFC3339(date, false);
    const editableClaimFilterAt = (date.getYear() % 100) * 100 + date.getMonth();
    const levelRuToEnList = {
      VIP: "vip",
      Платиновая: "platinum",
      Золотая: "gold",
      Серебряная: "silver",
      Регион: "region",
      "": "",
    };
    const statusRuToEnList = {
      Продолжать: "continue",
      Отмена: "reject",
      "Новый человек": "new",
      Возобновить: "continue",
      "Новая карта": "continue_plus_card",
      Конфликт: "conflict",
      Вкл: "on",
      Выкл: "off",
      "": "",
    };
    const levelEnToRuList = {};
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    const levels = [];
    Object.getOwnPropertyNames(levelRuToEnList).map((key) => {
      levelEnToRuList[levelRuToEnList[key]] = key;
      levels.push(levelRuToEnList[key]);
    });
    const statusEnToRuList = {};
    const statuses = [];
    Object.getOwnPropertyNames(statusRuToEnList).map((key) => {
      statusEnToRuList[statusRuToEnList[key]] = key;
      statuses.push(statusRuToEnList[key]);
    });

    const defaultDictionaries = {
      columns: {
        holder: "holder",
        status: "status",
        level: "level",
      },
      level: levelRuToEnList,
      status: statusRuToEnList,
      levelEn: levelEnToRuList,
      statusEn: statusEnToRuList,
      invoices: {
        it: "оплата лицензии ({level}) на предоставление доступа к электронной платформе, обеспечивающей оказание спортивно-оздоровительных услуг",
        service: "Услуги доступа в физкультурно-оздоровительные заведения по картам ({level})",
        percent: "50",
        currency: "byn",
      },
      price: `
|10-49|    region    |       89 |
|50-99|    region    |       85 |
|>=100|    region    |       79 |
| >=10|    silver    |       79 |
|10-49|     gold     |       109 |
|50-99|     gold     |       105 |
|>=100|     gold     |       99 |
|10-49|   platinum   |       139 |
|50-99|   platinum   |       135 |
|>=100|   platinum   |       129 |
`,
    };
    let dictionaries = defaultDictionaries;
    if (!dictionaries) {
      dictionaries = defaultDictionaries;
    }
    if (!dictionaries.price) {
      dictionaries.price = defaultDictionaries.price;
    }
    if (!dictionaries.invoices.currency) {
      dictionaries.invoices.currency = defaultDictionaries.invoices.currency;
    }
    const defaultColumns = [
      { key: "id", name: "id", order: 1 },
      { key: "company_name", name: "Company", order: 1 },
      { key: "it_balance", name: "Leftovers AllSports", order: 1 },
      { key: "service_balance", name: "Leftovers Super", order: 1 },
      { key: "cards", name: "Cards", order: 1 },
      { key: "manager_name", name: "Manager", order: 1 },
      { key: "notice", name: "Notice", order: 1 },
    ];
    const allColumns = [
      { key: "it_invoice", name: "Alls Invoice", order: 1 },
      { key: "service_invoice", name: "Super Invoice", order: 1 },
      { key: "expecting_credit", name: "Expecting", order: 1 },
      { key: "it_debet", name: "Alls Income", order: 1 },
      { key: "service_debet", name: "Super Income", order: 1 },
      { key: "it_credit", name: "Alls Acts", order: 1 },
      { key: "service_credit", name: "Super Acts", order: 1 },
      { key: "manager_name", name: "Manager", order: 1 },
      { key: "notice", name: "Notice", order: 1 },
    ]
      .concat(defaultColumns)
      .sort();
    const filter_ids = String(localStorage.getItem("journal_company_index_filter"));
    const filter_hash = Object.assign({}, ...filter_ids.split(",").map((val) => ({ [val]: val })));
    const filter_ids_toggle = Boolean(localStorage.getItem("journal_company_index_filter_toggle"));
    return {
      ROUTES_NAMES,

      filter_hash,
      filter_ids_toggle,
      invoiceClaim: null,
      actClaim: null,
      defaultDictionaries,
      dictionaries,
      toggleColumnForm: false,
      toggleDeleteButtons: false,
      toggleFileCsv: false,
      statuses: statuses.filter(onlyUnique),
      levels: levels.filter(onlyUnique),
      editableClaim: {
        company_id: "",
        id: "",
        csv: "",
        filter_at: editableClaimFilterAt,
        start_at: formatToRFC3339(date, true),
      },
      params: {
        status: "find_company",
        filter_at: filterAt,
        company: "",
        delimiter: ",",
        holderKey: 1,
        statusKey: 2,
        levelKey: 3,
        phoneKey: 5,
        messageKey: 6,
        changedKey: 7,
        cardKey: 8,
        claimKey: 9,
        with_deleted: 0,
        idMode: "cards|phones",
        missed_card_status: "reject",
        defaultCardLevel: "gold",
      },
      all_columns: allColumns,
      columns: this.createColumns("claim_company_index", defaultColumns),
      orderKey: "id",
      arrayColumns: [],
      arrayCsv: [],
      holderChanges: [],
      changingHolderChanges: [],
      arrayCsvObjects: [],
      changingCsvObjects: [],
      activeCsvItem: null,
      activeCsvRectangle: { top: 0, left: 0, width: "100%", height: "100%" },
      activeCsvChanged: false,
      arrayCsvDuplicates: {},
      arrayTracked: false,
      arrayConflicted: false,
      activeEntity: null,
      claimAdvanced: false,
      claim_company: null,

      exported: [],

      summary_stats_disabled: false,
      summary_card_types_disabled: false,

      params_off: {
        cleanup: false,
        validation: false,
        condition: false,
        import_only: false,
      },
    };
  },
  computed: {
    theme() {
      return themeDark;
    },
    ...mapGetters({
      locale: "auth/locale",
      country: "auth/country",
      isSuperAdmin: "auth/isSuperAdmin",
      templates: "company/templates",
      source: "company/claim_list",
      companies: "company/company_list",
      monthly: "company/monthly_list",
      cards: "company/card_list",
      holders: "company/match_holder_list",
    }),
    monthly_status() {
      const arrayCsvObjects = this.arrayCsvObjects;
      const statuses = this.defaultDictionaries.statusEn;
      const levels = this.defaultDictionaries.levelEn;
      let sum = {
        status: {},
        level: {},
      };
      Object.getOwnPropertyNames(statuses)
        .filter((key) => typeof statuses[key] === "string")
        .map((key) => {
          sum["status"][key] = 0;
        });
      Object.getOwnPropertyNames(levels)
        .filter((key) => typeof levels[key] === "string")
        .map((key) => {
          sum["level"][key] = 0;
        });
      let i, item, status, level;
      for (i in arrayCsvObjects) {
        item = arrayCsvObjects[i];
        status = item.status;
        level = item.level;
        ++sum["status"][status];
        if (["continue", "continue_plus_card", "new", "on"].indexOf(status) !== -1) {
          ++sum["level"][level];
        }
      }
      const result = [];
      let j = 0;
      const maxJ = Math.max(
        Object.getOwnPropertyNames(sum["level"]).length,
        Object.getOwnPropertyNames(sum["status"]).length
      );
      for (i = 0; i < maxJ; i++) {
        result[i] = ["", 0];
      }
      for (i in sum["level"]) {
        item = sum["level"][i];
        result[j++] = [i, item];
      }
      j = 0;
      for (i in sum["status"]) {
        item = sum["status"][i];
        result[j].push(i);
        result[j++].push(item);
      }
      return result;
    },
    arrayCsvObjectsComp() {
      const arrayCsvObjects = this.arrayCsvObjects;
      if (this.arrayTracked) {
        return arrayCsvObjects.filter((item) => item.changed === "yes");
      }
      if (this.arrayConflicted) {
        return arrayCsvObjects.filter((item) => item.extra);
      }
      return arrayCsvObjects;
    },
    filterCompanies() {
      let companies = this.companies;
      const filter_hash = this.filter_hash;
      if (this.filter_ids_toggle) {
        companies = companies.filter(function (row) {
          return filter_hash[row.id];
        });
      }
      if (this.params.company) {
        const filterCompany = this.params.company && this.params.company.toLowerCase();
        companies = companies.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterCompany) > -1;
          });
        });
      }
      const source = this.source;
      const source_hash = Object.assign({}, ...source.map((val) => ({ [val.company_id]: val.company_id })));
      return companies.filter(function (row) {
        return !source_hash[row.id];
      });
    },
    filteredSource() {
      const orderKey = this.orderKey;
      let index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.columns[index].order;
      }
      let data = this.source;
      const filter_hash = this.filter_hash;
      if (this.filter_ids_toggle) {
        data = data.filter(function (row) {
          return filter_hash[row.company_id];
        });
      }
      if (this.params.company) {
        const filterCompany = this.params.company && this.params.company.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterCompany) > -1;
          });
        });
      }
      if (orderKey && index !== -1) {
        this.columns[index].order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      if (index === -1) {
        console.log(`${index} cannot be -1. We are looking for ${orderKey} in our columns.`);
      }
      return data;
    },
    tags_from_params() {
      let tags = [];
      if (this.params.filter_at) {
        tags.push({ name: "filter_at", value: this.params.filter_at });
      }
      return tags;
    },
  },
  filters: {
    byn(value) {
      const val = value > 0 ? Math.floor(value / 100) : Math.ceil(value / 100);
      return `${val}.${Math.abs(value % 100)}`;
    },
  },
  watch: {
    $route(to) {
      this.extractParamsAndRun(to);
    },
    dictionaries(val, oldVal) {
      if (val === oldVal) {
        return;
      }
      localStorage.setItem("journal_company_claim_filter", JSON.stringify(val));
    },
  },
  mounted() {
    this.extractParamsAndRun(this.$route);
  },
  methods: {
    buildCompanyClassName(entry) {
      const result = [entry.activated === 0 ? "status-off" : "", "name_width"];
      return result.join(" ");
    },
    buildCompanyPlaceholder(entry) {
      return entry.activated === 0 ? "Company is turning off" : "";
    },
    overlayPreClose($event) {
      $event.stopPropagation();
      $event.preventDefault();
      this.saveCsvRow(this.activeCsvItem);
    },
    overlayKeyUpPreClose($event) {
      $event.stopPropagation();
      $event.preventDefault();
      if ($event.key === "Escape") {
        this.saveCsvRow(this.activeCsvItem);
      }
    },
    handleClick($event) {
      $event.stopPropagation();
    },
    editingCsvRow(item, element) {
      const rectangle = findFixedRectangle(element, element.scrollLeft, element.scrollTop);
      this.activeCsvRectangle = { left: rectangle[0], top: rectangle[1], width: rectangle[2], height: rectangle[3] };
      this.activeCsvItem = item;
    },
    removeRecordsWithoutPhones() {
      this.arrayCsvObjects = this.arrayCsvObjects.filter((item) => item.phone);
    },
    saveCsvRow(item) {
      let index = this.arrayCsvObjects.findIndex((row) => row.index === item.index);
      this.$set(this.arrayCsvObjects, index, item);
      this.activeCsvChanged = true;
      this.activeCsvItem = null;
    },
    destroyCsvRow(item) {
      this.arrayCsvObjects = this.arrayCsvObjects.filter((row) => row.index !== item.index);
      this.activeCsvChanged = true;
      this.activeCsvItem = null;
    },
    addToEnd(element) {
      const rectangle = findFixedRectangle(element, element.scrollLeft, element.scrollTop);
      this.activeCsvRectangle = { left: rectangle[0], top: rectangle[1], width: rectangle[2], height: rectangle[3] };
      const entity = [];
      entity.index = this.arrayCsvObjects.length + 1;
      entity.holder = "";
      entity.status = "new";
      entity.level = "gold";
      entity.message = "";
      entity.changed = "yes";
      entity.card = "";
      this.activeCsvItem = entity;
      this.arrayCsvObjects.push(entity);
    },
    statusClassByResult(result) {
      if (result > 0) {
        return "status-on";
      } else if (result < 0) {
        return "status-off";
      }
    },
    async importGoogle() {
      const text = "We do IMPORT of sheet" + (this.params_off.import_only ? "" : " + EXPORT At Once") + ", right?";
      if (!confirm(text)) {
        return;
      }
      const company_id = this.editableClaim.company_id;
      const data = await this.importAndGoogleParsing({ company_id, filter_at: this.params.filter_at });
      this.passServerParseCsv(data);
      if (this.params.csv) {
        if (this.params.status === "ready" && !this.params_off.import_only) {
          await this.forceExportPath({
            next_month: false,
            arrayCsvObjects: this.arrayCsvObjects,
            arrayChanges: this.holderChanges,
            company_id,
          });
        }
      }
    },
    async exportNextMonthSheet() {
      const text = "We do EXPORT the sheet to next month?";
      if (!confirm(text)) {
        return;
      }
      await this.forceExportPath({
        next_month: 1,
        arrayCsvObjects: this.arrayCsvObjects,
        arrayChanges: this.holderChanges,
        company_id: this.editableClaim.company_id,
        cleanup_off: this.params_off.cleanup,
        validation_off: this.params_off.validation,
        condition_off: this.params_off.condition,
      });
    },
    async exportToSheet() {
      const text = "We do EXPORT of the list to Sheet, right?";
      if (!confirm(text)) {
        return;
      }
      await this.forceExportPath({
        next_month: false,
        arrayCsvObjects: this.arrayCsvObjects,
        arrayChanges: this.holderChanges,
        company_id: this.editableClaim.company_id,
      });
    },
    async fireNewInvoice(claim) {
      if (this.editableClaim.company_id !== claim.company_id) {
        await this.monthlyList(claim);
      }
      if (!this.templates.companies.length) {
        await this.load_templates();
      }
      this.editableClaim = Object.assign({ to_change: true }, claim);
      this.invoiceClaim = null;
      this.actClaim = null;
      this.invoiceClaim = Object.assign({ to_read: true, with_deleted: this.params.with_deleted }, claim);
    },
    async fireNewAct(claim) {
      if (this.editableClaim.company_id !== claim.company_id) {
        await this.monthlyList(claim);
      }
      if (!this.templates.companies.length) {
        await this.load_templates();
      }
      this.editableClaim = Object.assign({ to_change: true }, claim);
      this.invoiceClaim = null;
      this.actClaim = null;
      this.actClaim = Object.assign({ to_read: true, with_deleted: this.params.with_deleted }, claim);
    },
    updateFilterAt($event) {
      const filter_at = $event.target.value;
      const date = createStartOfMonth(filter_at);
      this.editableClaim.filter_at = (date.getYear() % 100) * 100 + (date.getMonth() + 1);
      const filterAt = formatToRFC3339(date, false);
      this.params.filter_at = filterAt;
      this.params.start_at = formatToRFC3339(date, true);
      this.dictionaries.columns.status = this.params.start_at;
      this.$router.push({
        name: "claim_list",
        params: { filter_at: this.params.filter_at },
        query: { company: this.params.company },
      });
    },
    setParamsCompany($event) {
      this.params.company = $event.target.value;
      this.$router.push({
        name: "claim_list",
        params: { filter_at: this.params.filter_at },
        query: { company: this.params.company },
      });
    },
    async fireEditClaim(entity) {
      if (entity.hr_portal_only) {
        alert(this.$t("claims.list_hr_portal_description"));
        return;
      }
      this.editableClaim = Object.assign({ to_change: true }, entity);
      const company = this.companies.filter((company) => company.id === entity.company_id)[0];
      this.claim_company = company;
      this.params.status = "find_dictionary";
      this.dictionaries = await this.getDictionaryBy(
        this.editableClaim.company_id,
        this.params.filter_at,
        this.defaultDictionaries
      );

      this.params.status = "load_cards";
      const data = await this.importAndServerParsing({
        company_id: entity.company_id,
        filter_at: this.params.filter_at,
      });
      this.passServerParseCsv(data);
      if (this.params.csv) {
        const monthly = await this.monthlyList(entity);
      }
    },
    async getDictionaryBy(id, filterAt, defaultDictionaries) {
      let dictionaries = defaultDictionaries;
      try {
        dictionaries = await this.getDictPath({ company_id: id, filter_at: filterAt });
      } catch (e) {
        dictionaries = defaultDictionaries;
      }
      if (!dictionaries) {
        dictionaries = defaultDictionaries;
      }
      if (!dictionaries.price) {
        dictionaries.price = defaultDictionaries.price;
      }
      return dictionaries;
    },
    async addCompany($event) {
      const company_id = Number($event.target.value);
      this.dictionaries = await this.getDictionaryBy(company_id, this.params.filter_at, this.defaultDictionaries);
      this.editableClaim.company_id = company_id;
      this.editableClaim.id = null;
      this.claim_company = this.companies.filter((item) => item.id === company_id)[0];
      this.params.status = "load_cards";
      const data = await this.importAndServerParsing({ company_id, filter_at: this.params.filter_at });
      this.passServerParseCsv(data);
    },
    async upsertingMonthly() {
      return await this.upsertMonthly({ id: this.editableClaim.id, items: this.arrayCsvObjects });
    },
    getActivation(status, rejected) {
      return rejected.indexOf(status) === -1 ? "status-on" : "status-off";
    },
    loadedArrayObjects(data) {
      this.changingCsvObjects = data.items;
    },
    loadedHolderChanges(data) {
      this.changingHolderChanges = data.items;
    },
    async upgradeListCsvArray(arrayColumns, arrayCsvObjects, config, changingHolderChanges) {
      const data = await this.updateAndParsing({
        id: Number(this.editableClaim.company_id),
        filterAt: config.filter_at,
        columns: arrayColumns,
        values: arrayCsvObjects,
        changes: changingHolderChanges,
      });
      this.passServerParseCsv(data);
    },
    passServerParseCsv(data) {
      this.params.status = "ready";
      this.holderChanges = data.changes;
      this.arrayCsvObjects = data.arrayCsvObjects;
      this.arrayColumns = data.arrayColumns;
      this.toggleFileCsv = true;
    },
    calculateCardCountAndPrice(dictionaries, claim, arrayCsvObjects) {
      let totalCount = 0,
        i,
        item,
        level;
      let priceCalculator;
      try {
        priceCalculator = generateCalculator(dictionaries.price);
      } catch (e) {
        alert(e);
        throw e;
      }

      const counted = {};

      for (i in arrayCsvObjects) {
        if (!arrayCsvObjects.hasOwnProperty(i)) {
          continue;
        }
        item = arrayCsvObjects[i];
        if (["continue", "continue_plus_card", "new", "on"].indexOf(item.status) !== -1) {
          ++totalCount;
          level = item.level;
          if (!counted[level]) {
            counted[level] = 0;
          }
          ++counted[level];
        }
      }
      claim.cards = totalCount;
      claim.region_price = priceCalculator.calculatePriceBy("region", 1);
      claim.silver_price = priceCalculator.calculatePriceBy("silver", 1);
      claim.gold_price = priceCalculator.calculatePriceBy("gold", 1);
      claim.platinum_price = priceCalculator.calculatePriceBy("platinum", 1);
      claim.expecting_credit = Object.getOwnPropertyNames(counted).reduce((sum, level) => {
        const count = counted[level];
        const price = priceCalculator.calculatePriceBy(level, totalCount);
        claim[level + "_price"] = price;
        return price * count + sum;
      }, 0);
    },
    findErrorsInArray(arrayCsvObjects) {
      let found = arrayCsvObjects.filter(
        (item) => !item.phone || (item.extra && !/^(conflict|other_company):/.test(item.extra))
      );
      if (found.length === 0) {
        const groupedCardKey = arrayCsvObjects.reduce(function (rv, x) {
          if (!x.card) {
            return rv;
          }
          (rv[x.card] = rv[x.card] || []).push(x.card);
          return rv;
        }, {});
        found = Object.getOwnPropertyNames(groupedCardKey).filter((card) => groupedCardKey[card].length > 1);
      }
      return found;
    },
    async addToClaim() {
      if (this.activeCsvChanged) {
        await this.upgradeListCsvArray(
          this.arrayColumns,
          this.arrayCsvObjects,
          this.params,
          this.changingHolderChanges
        );
        this.activeCsvChanged = false;
        return;
      }
      const data = this.findErrorsInArray(this.arrayCsvObjects);
      if (data.length) {
        alert("Please, remove all errors first." + JSON.stringify(data));
        return;
      }
      this.calculateCardCountAndPrice(this.dictionaries, this.editableClaim, this.arrayCsvObjects);
      const claim = await this.commit(
        Object.assign({ company_id: Number(this.editableClaim.company_id) }, this.editableClaim, {
          arrayColumns: this.arrayColumns,
          arrayCsvObjects: this.arrayCsvObjects,
          arrayChanges: this.holderChanges,
          dictionaries: this.dictionaries,
        })
      );
      this.editableClaim = Object.assign({ to_change: true }, claim);
      if (this.params.status === "ready") {
        await this.upsertingMonthly();
      }
      this.toggleFileCsv = false;
    },
    async editClaim() {
      if (this.activeCsvChanged) {
        await this.upgradeListCsvArray(
          this.arrayColumns,
          this.arrayCsvObjects,
          this.params,
          this.changingHolderChanges
        );
        this.activeCsvChanged = false;
        return;
      }
      const data = this.findErrorsInArray(this.arrayCsvObjects);
      if (data.length) {
        alert("Please, remove all errors first." + JSON.stringify(data));
        return;
      }
      this.calculateCardCountAndPrice(this.dictionaries, this.editableClaim, this.arrayCsvObjects);
      const claim = await this.update(
        Object.assign(
          {
            arrayColumns: this.arrayColumns,
            arrayCsvObjects: this.arrayCsvObjects,
            arrayChanges: this.holderChanges,
            dictionaries: this.dictionaries,
          },
          this.editableClaim
        )
      );
      this.editableClaim = Object.assign({ to_change: true }, claim);
      if (this.params.status === "ready") {
        await this.upsertingMonthly();
      }
      this.toggleFileCsv = false;
    },
    ...mapActions({
      load_templates: "company/load_templates",
      loadCompanies: "company/load_company_list",
      loadClaimList: "company/load_claim_list_by_range",
      commit: "company/create_claim",
      update: "company/update_claim",
      deleteClaim: "company/delete_claim",
      importPath: "company/view_claim_data_import",
      forceImportPath: "company/view_claim_data_force_import",
      importAndServerParsing: "company/v2_import_server_parsing",
      importAndGoogleParsing: "company/v2_import_google_parsing",
      updateAndParsing: "company/v2_update_list_parsing",
      getPath: "company/view_claim_data_by_path",
      getPathChanges: "company/view_claim_data_by_path_changes",
      getDictPath: "company/view_claim_dict_by_path",
      upsertMonthly: "company/claim_monthly_list_upsert",
      cardsList: "company/card_list",
      monthlyList: "company/claim_monthly_list",
      matchHolder: "company/match_holder",

      forceExportPath: "company/v2_export_google_sheet",

      downloadSummaryStatistics: "company/download_summary_stats",
      downloadB2BStatistics: "company/download_b2b_stats",
      downloadB2CStatistics: "company/download_b2c_stats",
      downloadLevelsStatistics: "company/download_summary_levels_stats",
    }),
    async extractParamsAndRun(to) {
      if (to.params.filter_at) {
        this.params.filter_at = to.params.filter_at;
        const date = createStartOfMonth(to.params.filter_at);
        this.dictionaries.columns.status = date;
        this.editableClaim.filter_at = (date.getYear() % 100) * 100 + (date.getMonth() + 1);
        this.editableClaim.start_at = formatToRFC3339(date, true);
      }
      if (to.params.company) {
        this.params.company = to.params.company;
      }
      if (!this.companies.length) {
        await this.loadCompanies();
      }
      await this.loadClaimList(this.tags_from_params);
    },
    async fireDeletingClaim(entity) {
      if (confirm("Are you sure?")) {
        await this.deleteClaim(entity);
      }
    },
    async fireDownloadLevelsStatistics() {
      const params = { finished_at: this.params.filter_at, ref: this.$refs.invoice_download_ref };
      this.cards_card_types_disabled = true;
      try {
        await this.downloadLevelsStatistics(params);
      } catch (e) {}
      this.cards_card_types_disabled = false;
    },
    async fireDownloadSummaryStatistics() {
      const params = { finished_at: this.params.filter_at, ref: this.$refs.invoice_download_ref };
      this.cards_stats_disabled = true;
      try {
        await this.downloadSummaryStatistics(params);
      } catch (e) {}
      this.cards_stats_disabled = false;
    },
    async fireDownloadB2BStatistics() {
      const params = { finished_at: this.params.filter_at, ref: this.$refs.invoice_download_ref };
      this.cards_stats_disabled = true;
      try {
        await this.downloadB2BStatistics(params);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      this.cards_stats_disabled = false;
    },
    async fireDownloadB2CStatistics() {
      const params = { finished_at: this.params.filter_at, ref: this.$refs.invoice_download_ref };
      this.cards_stats_disabled = true;
      try {
        await this.downloadB2CStatistics(params);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      this.cards_stats_disabled = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

@include table-hoverable;

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  overflow: auto;

  .content {
    overflow: unset;
    position: relative;
    border-radius: 64px;
    background-color: white;
    color: black;
  }
}
.status-off {
  background: #e7b6b6;
  &.clicker {
    cursor: pointer;
    &:hover {
      color: #e7b6b6;
      background: black;
    }
  }
}
.status-on {
  background: #d2fced;
  &.clicker {
    cursor: pointer;

    &:hover {
      color: #d2fced;
      background: black;
    }
  }
}
.paid {
  background-color: lightgreen;
}
.paid-no-compared {
  background-color: lightgoldenrodyellow;
}
.unpaid {
  background-color: lightpink;
}
.item {
  padding: 20px;
  margin: 10px;
  th {
    color: lightgreen;
  }
  .block1 {
    order: 0;
  }
  .block2 {
    order: 1;
  }
}
.background-white > * {
  background-color: white;
  padding: 15px;
  border: 2px solid antiquewhite;
}
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.holders__info {
  position: relative;
  word-wrap: break-word;
  background-clip: border-box;
}
.holders__line {
  margin: 0;
  margin-top: -1px;
  padding: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 300;
  @include clearfix;
}
.holders__control {
  max-height: 2em;
}
.holders__value {
  float: right;
  font-weight: bold;
}

.name_width {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 30%;
}
.table-fixed {
  width: 100%;
  table-layout: fixed;
}
.table-fixed th {
  height: 2em;
}
</style>
