function onLinkClick(el, binding, e) {
  e.preventDefault();

  const targetEl = document.querySelector(binding.value);

  targetEl.scrollIntoView({ behavior: "smooth", block: "start" });
}

export default {
  bind: function (el, binding) {
    el.clickHandler = onLinkClick.bind(null, el, binding);
    el.addEventListener("click", el.clickHandler);
  },
  unbind: function (el) {
    if (el.clickHandler) {
      el.removeEventListener("click", el.clickHandler);
    }
  },
};
