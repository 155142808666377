export function kmToMeters(val) {
  if (!val) {
    return val;
  }

  return Math.round(val * 1000);
}

export function metersToKM(val) {
  if (!val) {
    return val;
  }

  return val / 1000;
}

export default {
  kmToMeters,
  metersToKM,
};
