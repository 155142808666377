<template>
  <div class="file-input" :class="elementClass">
    <label
      :for="`file_input_${_uid}`"
      class="file-input_label"
      @drop="onDrop"
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
    >
      <Icon icon="document-doc" class="file-input_label_icon" />
      <p class="file-input_label_text">
        <template v-if="state.fileName">
          <span>{{ state.fileName }}</span>
        </template>
        <template v-else>
          <span>Выберите файл</span><br />
          или<br />
          перетяните его в эту область
        </template>
      </p>
      <input
        type="file"
        :id="`file_input_${_uid}`"
        :disabled="props.disabled"
        v-bind="$attrs"
        @change="onChange"
        @click="onClick"
      />
    </label>
    <ErrorBanner v-bind="errorBannerProps" v-on="errorBannerActions" small />
  </div>
</template>

<script>
import { computed, reactive, watch } from "@vue/composition-api";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import isObject from "lodash/isObject";

import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import Icon from "@/components/icons/Icon.vue";

import { VALIDATION_ERRORS } from "@/helpers/validators";

const FILE_INPUT_CLASS = "file-input";
const FILE_INPUT_MODIFIERS = ["disabled"];

const ERROR_MESSAGES = {
  [VALIDATION_ERRORS.invalidFileExtension]: "Invalid file extension",
};

const getErrorMessage = (msg) => ERROR_MESSAGES[msg] || msg;

export default {
  emits: ["input"],

  components: {
    ErrorBanner,
    Icon,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Object, String],
      default: null,
    },
    fileTypes: {
      type: [String, Array],
      required: false,
    },
    value: {
      type: [Object, File],
      required: false,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      file: null,
      fileName: "",
      drag: false,

      error: null,
      errorActions: [],
    });

    const errorBannerProps = computed(() => {
      let message = getErrorMessage(state.error);
      let inlineActions = [];

      const isMessageString = message && !isObject(message);

      if (props.error && !isMessageString) {
        if (isString(props.error)) {
          message = props.error;
        } else {
          message = props.error.message;
          inlineActions = props.error.actions;
        }
      }

      return {
        message,
        inlineActions,
      };
    });

    const errorBannerActions = computed(() => {
      const actions = {};
      const propsActions = props.error?.actions;

      if (propsActions) {
        propsActions.forEach((action) => {
          actions[action.type] = onErrorBannerAction.bind(null, action.type);
        });
      }

      return actions;
    });

    const elementClass = computed(() => {
      const activeModifiers = FILE_INPUT_MODIFIERS.filter((modifier) => props[modifier]);

      if (state.drag) {
        activeModifiers.push("drag");
      }

      const elementClassList = activeModifiers.map((modifier) => `${FILE_INPUT_CLASS}__${modifier}`);

      return elementClassList;
    });

    const isError = computed(() => !!state.error);

    function onClick(e) {
      e.target.value = null;
    }

    function onChange(e) {
      const selectedFile = e.target.files.item(0);

      state.error = null;
      state.file = selectedFile;
      state.fileName = selectedFile?.name || "";

      emit("input", state.file);

      if (selectedFile && props.fileTypes) {
        const allowedFileTypes = isString(props.fileTypes) ? [props.fileTypes] : props.fileTypes;
        const fileExtension = state.fileName.split(".").slice(-1)[0];

        if (!allowedFileTypes.includes(fileExtension)) {
          state.error = VALIDATION_ERRORS.invalidFileExtension;
        }
      }
    }

    function onErrorBannerAction(event) {
      emit(event);
    }

    function onDrop() {
      state.drag = false;
    }

    function onDragEnter() {
      state.drag = true;
    }

    function onDragLeave() {
      state.drag = false;
    }

    watch(
      () => props.value,
      (newVal) => {
        if (isNull(newVal)) {
          state.fileName = "";
          state.file = null;
        } else {
          state.fileName = newVal?.name || "";
        }
      },
      { immediate: true }
    );

    watch(
      () => props.error,
      (newError) => {
        state.error = newError;
      },
      { immediate: true }
    );

    return {
      VALIDATION_ERRORS,

      props,

      state,

      errorBannerActions,
      errorBannerProps,
      elementClass,
      isError,

      onClick,
      onChange,
      onDrop,
      onDragEnter,
      onDragLeave,
    };
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  position: relative;
  width: 100%;

  &_label {
    padding: 8px;
    position: relative;
    width: 100%;
    height: 100px;
    overflow: hidden;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_icon {
      margin-right: 20px;
      flex-shrink: 0;
    }

    &_text {
      font-size: 13px;
      line-height: 1.5em;
      position: relative;

      span {
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        word-break: break-all;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .file-input:not(.file-input__disabled) &:hover {
      .file-input_label_text {
        span {
          text-decoration: none;
        }
      }
    }
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
  }

  &__disabled {
    opacity: 0.3;
  }

  &__drag {
    .file-input_label {
      border-style: solid;
      border-color: #000;
    }
  }
}
</style>
