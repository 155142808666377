<template>
  <div class="container">
    <h3>Template file errors:</h3>
    <div class="content">
      <div v-for="variable in unknownVariables" class="content_row" :key="`${_uid}_unknow_var_${variable}`">
        <h4>{{ variable }}</h4>
        <p class="error_type">Unknown variable</p>
      </div>
      <div class="content_row">
        <div class="content_buttons">
          <Button auto-width wide @click.prevent="emit('close')">Back</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/buttons/Button.vue";

export default {
  emits: ["close"],

  props: {
    unknownVariables: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    Button,
  },

  setup(props, { emit }) {
    return {
      emit,
    };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 1em;
}

.content {
  &_row {
    display: grid;
    grid-template-columns: 3fr 2fr 7fr;
    column-gap: 34px;
    margin-bottom: 16px;

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.4em;
    }

    .error_type {
      font-weight: 500;
      font-size: 16px;
      color: #d63230;
    }

    .checkbox {
      margin-bottom: 12px;
    }

    .content_buttons {
      margin-top: 12px;
      grid-column-start: 2;
      grid-column-end: 3;

      button + button {
        margin-left: 16px;
      }
    }
  }
}
</style>
