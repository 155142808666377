<template>
  <div class="controlled-number">
    <button class="controlled-number_btn controlled-number_btn-minus" @click.prevent="updateValue('dec')">-</button>

    <div class="controlled-number_input_container" :class="{ error: isErrorState }">
      <input :id="`controlled-number-${_uid}`" class="controlled-number_input" type="text" v-model="inputValue" />
      <label :for="`controlled-number-${_uid}`" class="controlled-number_input_label">{{ formattedValue }}</label>
    </div>

    <button class="controlled-number_btn controlled-number_btn-plus" @click.prevent="updateValue('inc')">+</button>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";

const DEFAULT_STEP = 0.5;

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    error: {
      type: [Boolean, String],
      required: false,
    },
    postfix: {
      type: String,
      required: false,
    },
    step: {
      type: Number,
      default: DEFAULT_STEP,
    },
  },
  emits: ["input"],

  setup(props, { emit }) {
    const state = reactive({
      value: props.value,
      error: false,
    });

    const isErrorState = computed(() => Boolean(props.error) || state.error);
    const formattedValue = computed(() => {
      if (isErrorState.value) {
        return "";
      }

      return [inputValue.value, props.postfix].join(" ");
    });
    const inputValue = computed({
      get() {
        return state.value;
      },
      set(val) {
        state.error = false;

        if (isNumber(val)) {
          state.value = val;
          return emit("input", val);
        }

        const FLOAT_REGEXP = /^[\d\.]*$/g;
        let castedValue = val.replace(",", ".").replace(/^0/, "");

        if (castedValue.lenth > 1 && castedValue.startsWith("0")) {
          castedValue = castedValue.slice(1);
        }

        const parsedValue = Number.parseFloat(castedValue);

        if (!FLOAT_REGEXP.test(val) || Number.isNaN(parsedValue)) {
          state.error = true;
        } else {
          emit("input", parsedValue);
        }

        state.value = castedValue;
      },
    });

    function updateValue(operation = "inc") {
      if (isErrorState.value || !isString(operation)) {
        return;
      }

      const updateStep = operation === "inc" ? props.step : -1 * props.step;
      const currentValue = Number.parseFloat(state.value);
      inputValue.value = Math.max(currentValue + updateStep, 0);
    }

    return {
      props,
      state,
      isErrorState,
      inputValue,
      formattedValue,
      updateValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.controlled-number {
  display: flex;
  align-items: center;
  justify-content: center;

  &_input_container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 75px;
    box-sizing: border-box;

    &.error {
      border-color: #f00;
    }
  }

  &_input {
    $input_height: 24px;

    border: none;
    background: none;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    font-family: "Montserrat", "DejaVu Sans", "Verdana", sans‑serif;
    height: $input_height;
    outline: none;
    appearance: none;
    opacity: 0;

    &:focus {
      opacity: 1;

      & + label {
        opacity: 0;
      }
    }

    &_label {
      font-family: "Montserrat", "DejaVu Sans", "Verdana", sans‑serif;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
      line-height: $input_height;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  &_btn {
    background: none;
    appearance: none;
    border: none;
    font-weight: 400;
    font-size: 20px;
    color: #666;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }
}
</style>
