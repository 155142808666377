<template>
  <div class="file-input_container">
    <div class="file-input_label">
      <span class="file-input_label_fake-input">{{ fakeInputText }}</span>
      <span v-if="showLabelMessage" class="file-input_label_message" :class="{ error: isError }">
        <template v-if="!isFileSelected">File not selected.</template>
        <template v-else-if="state.error === FILE_INPUT_ERRORS.invalidFileExtension">Invalid File extension</template>
        <template v-else>{{ state.error }}</template>
      </span>
    </div>
    <input v-bind="$attrs" type="file" @change="onChange" @click="onClick" />
  </div>
</template>

<script>
import { computed, reactive, watch } from "@vue/composition-api";
import isString from "lodash/isString";
import isNull from "lodash/isNull";

const FILE_INPUT_ERRORS = {
  invalidFileExtension: "invalidFileExtension",
};

export default {
  emits: ["input"],

  props: {
    fileTypes: {
      type: [String, Array],
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select a file",
    },
    value: {
      type: [Object, File],
      required: false,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      file: null,
      fileName: "",
      error: null,
    });

    const fakeInputText = computed(() => state.fileName || props.placeholder);
    const isError = computed(() => Boolean(state.error));
    const isFileSelected = computed(() => Boolean(state.file));
    const showLabelMessage = computed(() => isError || !isFileSelected);

    function onClick(e) {
      e.target.value = null;
    }

    function onChange(e) {
      const selectedFile = e.target.files.item(0);

      state.error = null;
      state.file = selectedFile;
      state.fileName = selectedFile?.name || "";

      emit("input", state.file);

      if (selectedFile && props.fileTypes) {
        const allowedFileTypes = isString(props.fileTypes) ? [props.fileTypes] : props.fileTypes;
        const fileExtension = state.fileName.split(".").slice(-1)[0];

        if (!allowedFileTypes.includes(fileExtension)) {
          state.error = FILE_INPUT_ERRORS.invalidFileExtension;
        }
      }
    }

    watch(
      () => props.value,
      (newVal) => {
        if (isNull(newVal)) {
          state.fileName = "";
          state.file = null;
        }
      }
    );

    return {
      FILE_INPUT_ERRORS,

      state,

      fakeInputText,
      isError,
      isFileSelected,
      showLabelMessage,

      onClick,
      onChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.file-input_container {
  display: flex;
  align-items: center;
  position: relative;

  .file-input_label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &_fake-input {
      display: inline-block;
      color: rgba(0, 0, 0, 0.3);
      font-weight: 400;
      font-size: 13px;
      line-height: 38px;
      width: 180px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      margin-right: 24px;
      padding: 0 5px;
      text-align: center;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_message {
      font-weight: 400;
      font-size: 13px;

      &.error {
        color: #d63230;
      }
    }
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
