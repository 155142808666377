<template>
  <div class="container">
    <div class="row-header">
      <h1>HR сотрудники</h1>
      <LocalSearchInput v-model="state.searchValue" />
    </div>
    <BreadCrumbs />
    <ErrorBanner class="error_banner" :message="state.error" />
    <SpinnerAllsports v-if="state.processingInitialData" centered />
    <template v-else-if="showTable">
      <SimpleTable
        :actions="TABLE_ACTIONS"
        :cells="TABLE_CELLS"
        :headers="TABLE_HEADERS"
        :items="filteredEmployeesList"
        :processing-items="processingItems"
        :show-actions-button="true"
        @delete="onDelete"
        @edit="onEdit"
        @sms="onSms"
      />
      <div class="controls">
        <Button auto-width @click="openAddPortalUserModal">Добавить</Button>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, reactive, ref, onBeforeMount } from "@vue/composition-api";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import LocalSearchInput from "@/components/inputs/LocalSearchInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";

import router from "@/router";
import store from "@/store";
import request from "@/helpers/request";
import URLS from "@/config/urls";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import { MODAL_TYPES, MODAL_MODES, MODAL_ACTION_OPEN } from "@/store/modules/modal";

const TABLE_HEADERS = ["Телефон", "Имя", "Позиция", "E-mail"];
const TABLE_CELLS = ["phone", "name", "position", "email"];

const ACTIONS = {
  edit: "edit",
  sms: "sms",
  delete: "delete",
};
const TABLE_ACTIONS = [
  {
    title: "Редактировать",
    key: ACTIONS.edit,
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
  {
    title: "SMS коды",
    key: ACTIONS.sms,
    icon: {
      type: "sms",
      color: "#E3001B",
    },
  },
  {
    title: "Удалить",
    key: ACTIONS.delete,
    icon: {
      type: "delete",
      color: "#E3001B",
    },
  },
];

export default {
  components: {
    BreadCrumbs,
    Button,
    ErrorBanner,
    LocalSearchInput,
    SimpleTable,
    SpinnerAllsports,
  },

  setup() {
    const refModal = ref(null);

    const state = reactive({
      processing: false,
      processingDelete: null,
      processingInitialData: false,
      processingSms: null,

      error: null,

      searchValue: "",
      portalUsersList: [],
    });

    const showTable = computed(() => state.portalUsersList.length > 0 || !state.error);

    const processingItems = computed(() => {
      const allItems = [state.processingSms, state.processingDelete];
      const activeItems = allItems.filter(Boolean);

      return activeItems;
    });

    const filteredEmployeesList = computed(() => {
      let filteredList = Array.from(state.portalUsersList);

      if (state.searchValue.length > 0) {
        filteredList = state.portalUsersList.filter((employee) => {
          const fullEmployeeInfo = TABLE_CELLS.map((key) => employee[key])
            .join(" ")
            .toLowerCase();
          return fullEmployeeInfo.includes(state.searchValue);
        });
      }

      return filteredList;
    });

    async function getEmployeesList() {
      const { companyId } = router.currentRoute.params;
      const url = URLS.companies.portalUsers.list(companyId);

      state.processingInitialData = true;
      state.error = null;

      try {
        state.portalUsersList = await request(url);
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.processingInitialData = false;
    }

    function addPortalUser(newPortalUser) {
      state.portalUsersList.push(newPortalUser);
    }

    function updatePortalUser(updatedPortalUser) {
      const user = state.portalUsersList.find((user) => user.id === updatedPortalUser.id);

      if (user) {
        Object.assign(user, updatedPortalUser);
      }
    }

    function openModal(modalCustomConfig) {
      const modalConfig = merge({}, modalCustomConfig, {
        payload: {
          portalUsersList: state.portalUsersList,
        },
      });

      store.dispatch(MODAL_ACTION_OPEN, modalConfig);
    }

    function openAddPortalUserModal() {
      const type = MODAL_TYPES.portalUserEdit;
      const mode = MODAL_MODES[type].add;

      openModal({
        type,
        mode,
        payload: {
          onAccept: addPortalUser,
        },
      });
    }

    async function onSms(employeeId) {
      if (state.processingSms) {
        return;
      }

      state.error = null;
      state.processingSms = employeeId;

      const { companyId } = router.currentRoute.params;
      const url = URLS.companies.portalUsers.sms(companyId, employeeId);

      try {
        const result = await request(url);

        if (result.code) {
          openModal({
            type: MODAL_TYPES.message,
            payload: {
              message: `Sms code: ${result.code}`,
            },
          });
        }
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.processingSms = null;
    }

    function onEdit(portalUserId) {
      const type = MODAL_TYPES.portalUserEdit;
      const mode = MODAL_MODES[type].edit;
      const portalUser = state.portalUsersList.find((employee) => employee.id === portalUserId);

      openModal({
        type,
        mode,
        payload: {
          portalUser,
          onAccept: updatePortalUser,
        },
      });
    }

    function onDelete(portalUserId) {
      const type = MODAL_TYPES.action;

      openModal({
        type,
        payload: {
          onAccept: deleteEmployee.bind(null, portalUserId),
        },
      });
    }

    async function deleteEmployee(employeeId) {
      state.processingDelete = employeeId;
      state.error = null;

      const { companyId } = router.currentRoute.params;
      const url = URLS.companies.portalUsers.update(companyId, employeeId);

      const currentPortalUsersList = cloneDeep(state.portalUsersList);
      const newPortalUsersList = currentPortalUsersList.filter((employee) => employee.id !== employeeId);

      try {
        await request({
          method: "DELETE",
          url,
        });

        state.portalUsersList = newPortalUsersList;
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.processingDelete = null;
    }

    onBeforeMount(() => {
      getEmployeesList();
    });

    return {
      TABLE_ACTIONS,
      TABLE_CELLS,
      TABLE_HEADERS,

      refModal,

      state,

      filteredEmployeesList,
      processingItems,
      showTable,

      onDelete,
      onEdit,
      onSms,
      openAddPortalUserModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.row-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error_banner {
  margin: 12px 0;
}

.controls {
  margin-top: 30px;
}
</style>
