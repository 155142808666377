/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  store(item) {
    return axios.post(`/api/helpdesk/messages/store`, item).then((response) => {
      return response.data.data;
    });
  },
  get_message_list() {
    return axios.get(`/api/helpdesk/messages/index`).then((response) => {
      return response.data.data;
    });
  },
  queue_message_subscribers(id) {
    return axios.get(`/api/helpdesk/messages/queue/${id}`).then((response) => {
      return response.data.message;
    });
  },
};
