<template>
  <jet-form-section>
    <template #title> Import Ticket </template>

    <template #description>
      Import tickets for chosen supplier with as CSV file. Columns: ticket, taken_at (format -> 31.12.2021), holder.
    </template>

    <template #form>
      <div class="col-span-6">
        <jet-label value="Import" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ supplier.name }}</div>
            <div class="text-gray-700 text-sm">{{ supplier.address }}</div>
          </div>
        </div>
      </div>

      <div>
        <jet-label for="csv" value="File to Import (first column should be `ticket`)" />
        <input id="csv" accept="accept" type="file" @change="addFile($event)" autofocus />
        <jet-input-error :message="form.errors.csv" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-button
        @click="fireImportTicket"
        :class="{ 'opacity-25': !form.csv || form.processing }"
        :disabled="!form.csv || form.processing"
      >
        Import
      </jet-button>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import { mapActions } from "vuex";

export default {
  components: {
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
  },

  props: ["supplier", "attraction"],

  data() {
    return {
      form: {
        csv: null,
        supplier_id: this.supplier.id,
        attraction_id: this.attraction.id,
        errors: [],
        processing: false,
      },
    };
  },

  methods: {
    ...mapActions({
      importTicket: "ticket/import_list",
    }),
    addFile(e) {
      this.form.csv = e.target.files[0];
    },

    async fireImportTicket() {
      const output = await this.importTicket({ attraction: this.attraction, form: this.form });
      if (output) {
        this.form.errors = output;
      } else {
        this.$router.push({ name: "holder_ticket_list", query: { attraction_id: this.attraction.id } });
      }
    },
  },
};
</script>
