<template>
  <div class="container">
    <div class="row-header">
      <h1 v-if="state.processing">Link attractions</h1>
      <h1 v-else>Link attractions for "{{ currentCouponsGroup.name }}"</h1>
      <Button
        :disabled="!isLinkAttractionButtonEnabled"
        :processing="state.newAttraction.linkingAttraction"
        @click="linkAttraction"
      >
        Add
      </Button>
    </div>
    <BreadCrumbs />
    <SpinnerAllsports v-if="state.processing" :wrapped="true" centered />
    <template v-else>
      <ErrorBanner :message="state.error" />
      <div class="content">
        <SimpleTable
          v-model="state.selectedLinkedAttraction"
          class="table-simple"
          :cells="TABLE_CELLS"
          :headers="TABLE_HEADERS"
          :items="attractionsList"
          :show-delete-button="true"
          :processing-items="state.deletingAttraction"
          @delete="onDelete"
        >
          <template #controls>
            <tr class="non-selectable">
              <td>
                <SearchInput
                  v-model="state.newAttraction.supplierId"
                  ref="refSupplierInput"
                  :error="state.newAttraction.error"
                  :search-params="supplierSearchParams"
                  :search-url="URLS.suppliers.search"
                  search-field="matchByName"
                  model-field="id"
                  :item-text-field="({ id, name }) => `${id} ${name}`"
                  @select="onSupplierChange"
                />
              </td>
              <td>
                <SearchInput
                  v-model="state.newAttraction.attractionId"
                  ref="refAttractionInput"
                  :error="state.newAttraction.error"
                  :offline-items="state.newAttraction.supplierAttractionsList"
                  model-field="id"
                  :item-text-field="({ id, name, list }) => `${id} ${name} (${list})`"
                  :disabled="!isSearchAttractionEnabled"
                  :processing="state.newAttraction.processingAttractions"
                />
              </td>
            </tr>
          </template>
        </SimpleTable>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, reactive, ref, onBeforeMount } from "@vue/composition-api";
import isNull from "lodash/isNull";

import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";

import router from "@/router";
import store from "@/store";
import {
  COUPONS_ACTION_GET_ATTRACTIONS,
  COUPONS_ACTION_GET_COUPONS_GROUPS,
  COUPONS_ACTION_LINK_ATTRACTION,
  COUPONS_ACTION_DELETE_ATTRACTION,
} from "@/store/modules/coupons";
import request from "@/helpers/request";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import URLS from "@/config/urls";

const TABLE_HEADERS = ["Supplier Name", "Name of Attraction"];
const TABLE_CELLS = ["supplier.name", "name"];

const newAttractionInitialValue = {
  supplierId: null,
  attractionId: null,

  supplierAttractionsList: [],

  error: null,
  processingAttractions: false,
  linkingAttraction: false,
};

export default {
  components: {
    BreadCrumbs,
    Button,
    ErrorBanner,
    SearchInput,
    SimpleTable,
    SpinnerAllsports,
  },

  setup() {
    const refSupplierInput = ref(null);
    const refAttractionInput = ref(null);

    const state = reactive({
      selectedLinkedAttraction: null,

      isSupplierSelectedByName: false,

      error: null,
      processing: false,
      deletingAttraction: null,

      newAttraction: Object.assign({}, newAttractionInitialValue),
    });

    const attractionsList = computed(() => store.state.coupons.attractionsList);
    const isSearchAttractionEnabled = computed(
      () => !isNull(state.newAttraction.supplierId) && state.isSupplierSelectedByName
    );
    const isLinkAttractionButtonEnabled = computed(
      () => !isNull(state.newAttraction.supplierId) && !isNull(state.newAttraction.attractionId)
    );
    const currentCouponsGroup = computed(() => {
      const { couponsGroupId } = router.currentRoute.params;
      const group = store.state.coupons.couponsGroupsList.find((group) => group.id == couponsGroupId);

      return group;
    });

    const supplierSearchParams = computed(() => ({
      country: store.state.auth.country,
    }));

    async function getData() {
      state.processing = true;
      state.error = null;

      try {
        const { couponsGroupId } = router.currentRoute.params;

        await store.dispatch(COUPONS_ACTION_GET_COUPONS_GROUPS);
        await store.dispatch(COUPONS_ACTION_GET_ATTRACTIONS, {
          couponsGroupId,
        });
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.processing = false;
    }

    async function onSupplierChange(supplierId) {
      state.isSupplierSelectedByName = true;
      state.newAttraction.error = null;

      if (isNull(supplierId)) {
        return;
      }

      state.newAttraction.processingAttractions = true;

      try {
        const baseUrl = URLS.attractions.getBySupplier(supplierId);
        const query = new URLSearchParams({ coupon_group_empty: true });
        const url = `${baseUrl}?${query.toString()}`;

        const result = await request(url);

        const filteredAttractionsList = result.filter((item) => {
          const isItemLinked = store.state.coupons.attractionsList.find((attr) => attr.id == item.id);

          return !isItemLinked;
        });

        state.newAttraction.supplierAttractionsList = filteredAttractionsList;
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.newAttraction.error = errorMessage;
      }

      state.newAttraction.processingAttractions = false;
    }

    async function linkAttraction() {
      if (state.newAttraction.linkingAttraction) {
        return;
      }

      state.error = null;
      state.newAttraction.linkingAttraction = true;

      try {
        const { couponsGroupId } = router.currentRoute.params;
        const { attractionId } = state.newAttraction;

        await store.dispatch(COUPONS_ACTION_LINK_ATTRACTION, {
          couponsGroupId,
          attractionId,
        });

        state.newAttraction = Object.assign({}, newAttractionInitialValue);
        refSupplierInput.value.reset();
        refAttractionInput.value.reset();
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.newAttraction.linkingAttraction = false;
      state.isSupplierSelectedByName = false;
    }

    async function onDelete(attractionId) {
      const { couponsGroupId } = router.currentRoute.params;
      const actionData = {
        couponsGroupId,
        attractionId,
      };

      state.error = null;
      state.deletingAttraction = attractionId;

      try {
        await store.dispatch(COUPONS_ACTION_DELETE_ATTRACTION, actionData);
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.deletingAttraction = null;
    }

    onBeforeMount(() => {
      getData();
    });

    return {
      URLS,
      TABLE_HEADERS,
      TABLE_CELLS,

      refSupplierInput,
      refAttractionInput,

      state,

      attractionsList,
      isSearchAttractionEnabled,
      isLinkAttractionButtonEnabled,
      currentCouponsGroup,
      supplierSearchParams,

      onDelete,
      onSupplierChange,
      linkAttraction,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 9fr 3fr;
  gap: 25px;
  align-items: start;

  .controls {
    position: sticky;
    top: 48px;

    .btn {
      margin-bottom: 15px;
    }
  }
}
</style>
