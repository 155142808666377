import axios from "axios";
/**
 * Created by Alexander Golovnya on 2019-06-24.
 */
export default {
  mail_list(to) {
    return axios.get(`/api/electronic_mails?to=${to}`).then((response) => {
      return response.data.data;
    });
  },
  send(to, message) {
    return axios
      .post(`/api/electronic_mails`, { to, message })
      .then((response) => {
        return response.data.data;
      });
  },
};
