<style scoped>
.action-link {
  cursor: pointer;
}
</style>

<template>
  <small-layout>
    <form @submit.prevent="signUpUser">
      <fieldset>
        <legend>SignUp</legend>
        <div>
          <label for="name">Name</label>
          <input id="name" type="text"
name="name" autocomplete="name" required autofocus />
        </div>
        <div>
          <label for="email">Email</label>
          <input id="email" type="email"
name="email" autocomplete="email" required autofocus />
        </div>
        <div>
          <label for="password">Password</label>
          <input autocomplete="current-password" id="password" type="password" name="password" required />
        </div>
        <div>
          <label for="password_confirmation">Repeat Password</label>
          <input
            autocomplete="current-password"
            id="password_confirmation"
            type="password"
            name="repeat-password"
            required
          />
        </div>
        <div>
          <label for="phone_number">Phone number</label>
          <input id="phone_number" type="tel" name="phone_number" required />
        </div>
        <div>
          <label for="country">Country (ZZ/LT/UA etc.)</label>
          <input id="country" type="text"
name="country" autocomplete="country" required autofocus />
        </div>
        <div>
          <button>SignUp</button>
        </div>
      </fieldset>
    </form>
  </small-layout>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      signUp: "auth/signUp",
    }),
    async signUpUser(e) {
      const password = e.target.password.value;
      const password_confirmation = e.target.password_confirmation.value;
      if (password !== password_confirmation) {
        return alert("Password did not matched.");
      }
      let postData = {
        email: e.target.email.value,
        password,
        password_confirmation,
        name: e.target.name.value,
        country: e.target.country.value,
        phone_number: e.target.phone_number.value,
      };
      const routeName = await this.signUp(postData);
      this.$router.push({ name: routeName, params: { filter_at: this.filter_at } });
    },
  },
};
</script>
