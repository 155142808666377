export default {
  name: "prolongating_type_label",
  filter(value) {
    const results = [
      "skip",
      "new_sms",
      "prolonged_push",
      "prolonged_sms",
      "deleted_sms",
      "turning_off_push",
      "changed_level_push",
      "changed_level_sms",
    ];
    return results[value] ?? "unknown";
  },
};
