<template>
  <div class="common-layout">
    <Modal />
    <SmallLayoutOld class="small-layout" />
    <router-view></router-view>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import SmallLayoutOld from "@/components/layouts/SmallLayoutOld.vue";

export default {
  components: {
    Modal,
    SmallLayoutOld,
  },
};
</script>

<style lang="scss">
.common-layout {
  .small-layout {
    margin-bottom: 24px;
  }

  .container {
    width: 1280px;
    margin: 0 auto;
    padding-bottom: 96px;

    h1 {
      font-weight: 700;
      font-size: 25px;
      padding: 0.1em 0;
      line-height: 1.4em;
    }

    .row-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 40px;
    }
  }
}
</style>
