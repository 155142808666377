<template>
  <small-layout>
    <div>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">city List</h2>

      <div>
        <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8" v-if="selectedCity">
          <update-phone-form
            :city="selectedCity.name"
            :support_phone="selectedCity.support_phone"
            @hide="reloadCities() && (selectedCity = null)"
          />
        </div>
      </div>

      <div v-if="cities.length > 0">
        <table class="table-mixed">
          <thead>
            <tr>
              <th class="w-1/2 px-4 py-2 text-light-blue-600">Name</th>
              <th class="w-1/4 px-4 py-2 text-light-blue-600">Support</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="city in cities">
              <tr>
                <td class="break-all border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                  {{ city.name }}
                </td>
                <td class="break-all border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                  <button @click="selectedCity = city">
                    {{ city.support_phone }}
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </small-layout>
</template>

<script>
import JetResponsiveNavLink from "../../Jetstream/ResponsiveNavLink";
import UpdatePhoneForm from "./UpdatePhoneForm";
import { mapActions } from "vuex";
import Button from "../../Jetstream/Button";
export default {
  data() {
    return {
      cities: [],
      selectedCity: null,
    };
  },
  methods: {
    ...mapActions({
      loadCities: "helpdesk/load_cities",
    }),
    async reloadCities() {
      this.cities = await this.loadCities();
    },
  },
  components: {
    Button,
    UpdatePhoneForm,
    JetResponsiveNavLink,
  },
  async mounted() {
    this.reloadCities();
  },
};
</script>
