<template>
  <jet-form-section>
    <template #title> Update Phone </template>

    <template #description> Update phone for city. </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="city" value="City" />
        <jet-input
          id="city"
          type="phone"
          class="mt-1 block w-full"
          v-model="form.city"
          ref="city"
          autocomplete="city"
        />
        <jet-input-error :message="form.errors.city" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="support_phone" value="Current Phone" />
        <jet-input
          id="support_phone"
          type="phone"
          class="mt-1 block w-full"
          v-model="form.support_phone"
          ref="support_phone"
          autocomplete="current-phone"
        />
        <jet-input-error :message="form.errors.support_phone" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-action-message :on="form.recentlySuccessful" class="mr-3"> Saved. </jet-action-message>

      <jet-button @click="fireUpdatePhone" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Change Support Phone
      </jet-button>
    </template>
  </jet-form-section>
</template>

<script>
import JetActionMessage from "../../Jetstream/ActionMessage";
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import { mapActions } from "vuex";

export default {
  components: {
    JetActionMessage,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
  },

  props: ["city", "support_phone"],

  data() {
    return {
      form: {
        city: this.city,
        support_phone: this.support_phone,
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      updatePhone: "helpdesk/update_city",
    }),
    async fireUpdatePhone() {
      const output = await this.updatePhone(this.form);
      this.form.recentlySuccessful = !(output && output.errors);
      if (!this.form.recentlySuccessful) {
        this.form.errors = output.errors;
      }
      if (this.form.recentlySuccessful) {
        this.$emit("hide");
      }
    },
  },
};
</script>
