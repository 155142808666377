<template>
  <div class="container">
    <div class="row-header">
      <h1>Templates</h1>
      <Button v-if="!processing" @click="openAddTemplatePage">Add</Button>
    </div>
    <ErrorBanner :message="errorMessage" />
    <SpinnerAllsports v-if="processing" :wrapped="true" centered />
    <SimpleTable
      v-else
      class="table"
      empty-cell-value="–"
      :actions="TABLE_ACTIONS"
      :headers="TABLE_HEADERS"
      :cells="TABLE_CELLS"
      :items="templatesList"
      showActionsButton
      @download="onDownload"
      @edit="onEdit"
    />
  </div>
</template>

<script>
import { onBeforeMount } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";

import useRequest from "@/composables/network/useRequest";
import router, { ROUTES_NAMES } from "@/router";
import { download } from "@/helpers/request";
import URLS from "@/config/urls";

const ACTIONS = {
  edit: "edit",
  download: "download",
};
const TABLE_ACTIONS = [
  {
    title: "Edit",
    key: ACTIONS.edit,
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
  {
    title: "Download template",
    key: ACTIONS.download,
    icon: {
      type: "download",
      color: "#79C99E",
    },
  },
];
const TABLE_HEADERS = ["ID", "Seller", "Name", "Company", "Document type"];
const TABLE_CELLS = ["id", "seller.name", "name", "company.name", "document_type"];

export default {
  components: {
    Button,
    ErrorBanner,
    SimpleTable,
    SpinnerAllsports,
  },

  setup() {
    const {
      data: templatesList,
      fetch: getTemplatesList,
      processing,
      errorMessage,
    } = useRequest({ url: URLS.templates.list });

    function openAddTemplatePage() {
      router.push({ name: ROUTES_NAMES.templates.add });
    }

    async function onDownload(templateId) {
      await download(URLS.templates.download(templateId));
    }

    function onEdit(templateId) {
      router.push({ name: ROUTES_NAMES.templates.edit, params: { templateId } });
    }

    onBeforeMount(() => {
      getTemplatesList();
    });

    return {
      ROUTES_NAMES,
      TABLE_ACTIONS,
      TABLE_HEADERS,
      TABLE_CELLS,

      processing,
      templatesList,
      errorMessage,

      openAddTemplatePage,
      onDownload,
      onEdit,
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 24px;
}
</style>
