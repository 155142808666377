/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_ticket from "../../api/tickets";

// initial state
// shape: [{ supplier }]
const state = {
  tickets: [],
  attractions: [],
};

// getters
const getters = {
  list(state) {
    return state.tickets;
  },
  attractions(state) {
    return state.attractions;
  },
};

// actions
const actions = {
  async load_tickets({ commit }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await api_ticket.list(params);
      commit("tickets", data.tickets);
      commit("attractions", data.attractions);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      return error?.response?.data;
    }
  },

  async load_ticket({ commit }, id) {
    return await api_ticket.one(id);
  },

  async update_ticket({ commit }, ticket) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_ticket.update(ticket);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      return error?.response?.data;
    }
  },

  async delete_ticket({ commit }, id) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_ticket.delete(id);
      commit("removeTicket", id);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      return error?.response?.data;
    }
  },

  async get_attraction({ commit }, id) {
    return await api_ticket.load_attraction(id);
  },

  async store_ticket({ commit }, ticket) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_ticket.store(ticket);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      return error?.response?.data;
    }
  },

  async import_list({ commit }, { attraction, form }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_ticket.import_file(attraction, form);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      return error?.response?.data;
    }
  },
  async load_stats({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_ticket.stats();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      return error?.response?.data;
    }
  },
};

// mutations
const mutations = {
  tickets(state, tickets) {
    state.tickets = tickets;
  },
  attractions(state, attractions) {
    state.attractions = attractions;
  },
  removeTicket(state, id) {
    state.tickets.splice(
      state.tickets.findIndex((ticket) => ticket.id === id),
      1
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
