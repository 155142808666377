<template>
  <small-layout>
    <div>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">Stats Tickets</h2>
      <div class="py-12">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg px-4 py-4">
            <table class="table-fixed w-full">
              <thead>
                <tr class="bg-gray-100">
                  <th class="px-4 py-2">Supplier</th>
                  <th class="px-4 py-2" title="IF ID is set, then ticket is taken.">ID is set</th>
                  <th class="px-4 py-2" title="IF Name is set, then ticket were provided manually">Name is set</th>
                  <th class="px-4 py-2">Amount</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="row in tickets">
                  <tr>
                    <td class="border px-4 py-2">{{ row.supplier ? row.supplier.name : "UNKNOWN" }}</td>
                    <td class="border px-4 py-2">{{ row.card_id ? "yes" : "no" }}</td>
                    <td class="border px-4 py-2">{{ row.holder_name ? "yes" : "no" }}</td>
                    <td class="border px-4 py-2">{{ row.amount }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="table-fixed w-full">
              <thead>
                <tr class="bg-gray-100">
                  <th class="px-4 py-2">Supplier</th>
                  <th class="px-4 py-2">Status</th>
                  <th class="px-4 py-2">Amount</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="row in tickets">
                  <tr>
                    <td class="border px-4 py-2">{{ row.supplier ? row.supplier.name : "UNKNOWN" }}</td>
                    <td class="border px-4 py-2">{{ getStatusBy(row.holder_id, row.holder_name) }}</td>
                    <td class="border px-4 py-2">{{ row.amount }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Load </jet-button>
          </div>
        </div>
      </div>
    </div>
  </small-layout>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetCheckbox from "../../Jetstream/Checkbox";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import { mapActions } from "vuex";

export default {
  components: {
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetCheckbox,
  },

  data() {
    return {
      form: {},
      tickets: [],
    };
  },

  methods: {
    ...mapActions({
      loadStats: "ticket/load_stats",
    }),
    getStatusBy(id, name) {
      if (id && name) {
        return "ByHAND";
      }
      if (id && !name) {
        return "AUTOMATICALLY";
      }
      if (!id && !name) {
        return "FREE";
      }
      return "BROKEN";
    },
  },
  async mounted() {
    this.tickets = await this.loadStats();
  },
};
</script>
