/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_visit from "../../api/visit";
import openBlobOtherWindow from "../../middleware/openBlobOtherWindow";

let per_page = localStorage.getItem("x-journal-per-page");
if (!per_page) {
  per_page = 100;
}
// initial state
// shape: [{ supplier }]
const state = {
  visit_list: [],
  header_to_ids: {},
  list_meta: {
    current_page: 1,
    from: 1,
    last_page: 2,
    per_page: per_page,
    to: 15,
    total: 29,
  },
  visit_search: [],
  search_meta: {
    current_page: 1,
    from: 1,
    last_page: 2,
    per_page: per_page,
    to: 15,
    total: 29,
  },
};

// getters
const getters = {
  header_to_ids(state) {
    return state.header_to_ids;
  },
  list_source(state) {
    return state.visit_list;
  },
  list_meta(state) {
    return state.list_meta;
  },
  search_meta(state) {
    return state.search_meta;
  },
};

// actions
const actions = {
  async visit_list({ commit, rootGetters }, { page, tags }) {
    try {
      commit("error/clear", undefined, { root: true });
      const row_count = 100;
      const visit_list = await api_visit.visit_list(page, row_count, tags);
      commit("visit_list", visit_list);
      return visit_list.data;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async visit_search({ commit, rootGetters }, { page, tags }) {
    try {
      commit("error/clear", undefined, { root: true });
      const visit_list = await api_visit.visit_search(page, tags);
      commit("visit_search", visit_list);
      return visit_list.data;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async manually_update_visit({ commit, rootGetters }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      const newVisit = await api_visit.manually_update_visit(visit);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_attraction_by_visit({ commit }, visit) {
    try {
      commit("error/clear", undefined, { root: true });
      const supplier = await api_visit.manually_patching_visit(visit);
      return supplier.attractions;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async visit_print_download({ commit }, { tags, ahref }) {
    const filename = "visits" + new Date().toDateString() + "_AllSports.xls";

    let blob;

    try {
      commit("error/clear", undefined, { root: true });
      const response = await api_visit.visit_download(tags);
      blob = new Blob([response.data]);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }

    openBlobOtherWindow(blob, filename, ahref);
  },
};

function array_flip(trans) {
  let key,
    tmp_ar = {};

  for (key in trans) {
    if (trans.hasOwnProperty(key)) {
      tmp_ar[trans[key]] = key;
    }
  }
  return tmp_ar;
}

// mutations
const mutations = {
  visit_list(state, list) {
    state.header_to_ids = array_flip(list.headers);
    state.visit_list = list.data;
    state.list_meta = list.meta;
  },
  visit_search(state, list) {
    state.visit_search = list.data;
    state.search_meta = list.meta;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
