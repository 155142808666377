/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_card from "../../api/card";
import openBlobOtherWindow from "../../middleware/openBlobOtherWindow";
import api_visit from "../../api/visit";

// initial state
// shape: [{ supplier }]
const state = {
  card_list: [],
  card_list_meta: {
    current_page: 1,
    from: 1,
    last_page: 2,
    per_page: 100,
    to: 15,
    total: 29,
  },
};

// getters
const getters = {
  card_list_meta(state) {
    return state.card_list_meta;
  },
  holder_list(state) {
    return state.holder_list;
  },
  company_list(state) {
    return state.company_list;
  },
};

// actions
const actions = {
  async card_search({ commit, rootGetters }, { page, tags }) {
    try {
      commit("error/clear", undefined, { root: true });
      const card_list = await api_card.card_search(page, tags);
      commit("card_search", card_list);
      return card_list.data;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async card_list({ commit, rootGetters }, { page, search }) {
    try {
      commit("error/clear", undefined, { root: true });
      const row_count = 100;
      const card_list = await api_card.card_list(page, search, row_count);
      commit("card_list", card_list);
      return card_list.data;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_summary_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_card.summary_stats_download(finished_at);
      const filename = "card_stats" + finished_at + "_AllSports.xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  card_list(state, list) {
    state.card_list = list.data;
    state.card_list_meta = list.meta;
  },
  holder_list(state, list) {
    state.holder_list = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
