/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  switch_holders() {
    return axios
      .get(`/api/helpdesk/control/new_month/switch_holders`)
      .then((response) => {
        return response.data.data;
      });
  },
  check_phone() {
    return axios
      .get(`/api/helpdesk/control/new_month/check_phone`)
      .then((response) => {
        return response.data.data;
      });
  },
  prepare_holders() {
    return axios
      .get(`/api/helpdesk/control/new_month/prepare_holders`)
      .then((response) => {
        return response.data.message;
      });
  },
};
