<template>
  <div class="container">
    <h1>Redeem coupon</h1>
    <BreadCrumbs />
    <template v-if="!state.processing">
      <div class="header-block">
        <h3>Coupon group:</h3>
        <input type="text" :value="couponGroupName" disabled />
      </div>
      <div class="header-block">
        <h3>Coupon value:</h3>
        <input type="text" :value="couponValue" disabled />
      </div>
    </template>
    <SpinnerAllsports v-if="state.processing" :wrapped="true" />
    <form v-else action="#" class="block" @submit.prevent="updateCoupon">
      <p>Holder Name or Phone Number</p>
      <SearchInput
        v-model="state.cardId"
        :error="state.error"
        :search-url="URLS.cards.loadHolder"
        search-field="holder"
        model-field="id"
        :item-text-field="({ holder, phone_number, level }) => `${holder} ${phone_number} ${level}`"
      />
      <Button class="btn" type="submit" :processing="state.updateProcessing">Submit coupon redeeming</Button>
    </form>
  </div>
</template>

<script>
import { computed, nextTick, reactive, onBeforeMount, watch } from "@vue/composition-api";

import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";

import store from "@/store";
import router, { ROUTES_NAMES } from "@/router";
import {
  COUPONS_ACTION_GET_COUPONS_GROUPS,
  COUPONS_ACTION_GET_COUPON,
  COUPONS_ACTION_UPDATE_COUPON,
} from "@/store/modules/coupons";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import { VALIDATION_ERRORS } from "@/helpers/validators";
import URLS from "@/config/urls";

export default {
  components: {
    BreadCrumbs,
    Button,
    SearchInput,
    SpinnerAllsports,
  },

  setup() {
    const state = reactive({
      cardId: null,

      error: null,
      processing: true,
      updateProcessing: false,
    });

    const routeParams = computed(() => router.currentRoute.params);
    const couponValue = computed(() => store.state.coupons.redeemingCoupon?.value);
    const couponGroupName = computed(() => {
      const group = store.state.coupons.couponsGroupsList.find((group) => group.id == routeParams.value.couponsGroupId);
      return group?.name;
    });

    async function updateCoupon() {
      if (state.updateProcessing) {
        return;
      }

      if (!state.cardId) {
        await nextTick();
        state.error = VALIDATION_ERRORS.requiredField;
        return;
      }

      state.error = null;
      state.updateProcessing = true;

      try {
        const { couponsGroupId, couponId } = routeParams.value;
        const actionPayload = { couponsGroupId, couponId, data: { card_id: state.cardId } };

        await store.dispatch(COUPONS_ACTION_UPDATE_COUPON, actionPayload);

        router.push({ name: ROUTES_NAMES.coupons.list });
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.updateProcessing = false;
    }

    onBeforeMount(async () => {
      await store.dispatch(COUPONS_ACTION_GET_COUPONS_GROUPS);

      if (!store.state.coupons.redeemingCoupon) {
        const { couponsGroupId, couponId } = routeParams.value;
        const actionPayload = { couponsGroupId, couponId, updateRedeemingCoupon: true };

        await store.dispatch(COUPONS_ACTION_GET_COUPON, actionPayload);
      }

      state.processing = false;
    });

    watch(
      () => state.cardId,
      () => {
        state.error = null;
      }
    );

    return {
      URLS,

      state,

      couponValue,
      couponGroupName,

      updateCoupon,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-block {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 16px;

  h3,
  input {
    font-size: 16px;
  }

  input {
    margin-left: 16px;
    background: none;
  }
}

.block {
  margin-top: 60px;
  width: 380px;

  p {
    margin-bottom: 15px;
  }

  .btn {
    margin-top: 20px;
  }
}
</style>
