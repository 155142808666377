<template>
  <div>
    <table>
      <caption>
        {{
          $t("client.title")
        }}
      </caption>
      <thead>
        <tr>
          <th>{{ $t("client.columns.phone") }}</th>
          <th>{{ $t("client.columns.email") }}</th>
          <th>{{ $t("client.columns.name") }}</th>
          <th>{{ $t("client.columns.position") }}</th>
          <th>{{ $t("client.actions.title") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in clients">
          <td>{{ client.phone }}</td>
          <td>{{ client.email }}</td>
          <td>{{ client.name }}</td>
          <td>{{ client.position }}</td>
          <td>
            <button @click="fireUpdatingClient(client)">{{ $t("client.actions.updating_title") }}</button>
            <button @click="fireShowingSmsCode(client)">{{ $t("client.actions.sms_code_title") }}</button>
            <button @click="fireDeleteClient(client)">{{ $t("client.actions.deleting_title") }}</button>
          </td>
        </tr>
      </tbody>
    </table>
    <button @click="fireCreatingClient">{{ $t("client.actions.creating_title") }}</button>
    <client-create v-if="toggleCreateForm" :value="client" @input="fireUpdateClient"></client-create>
    <client-update v-if="toggleUpdateForm" :value="client" @input="fireUpdateClient"></client-update>
    <button v-if="canMassUpdate" @click="updateClientsFromCompany">
      {{ $t("client.actions.mass_upsert_title") }}
    </button>
    <label for="today">{{ $t("notification.date.now") }}</label>
    <input type="date" v-model:value="today" id="today" :placeholder="$t('notification.date.now')" />
    <button @click="fireNotificationClients">{{ $t("notification.actions.clients") }}</button>
    <button @click="fireNotificationManagers">{{ $t("notification.actions.managers") }}</button>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import ClientCreate from "./client/ClientCreate";
import ClientUpdate from "./client/ClientUpdate";
import api from "../../api/company_client";
import apiNotification from "../../api/company_notify";

export default {
  components: {
    ClientCreate,
    ClientUpdate,
  },
  setup(props, context) {
    const company = context.parent.$route.params.companyId;
    const today = ref(new Date().toJSON().substring(0, 10));
    const clients = ref([]);
    const client = ref({
      phone: "375291294950",
      email: "alex@allsports.by",
      name: "Alexander",
      position: "HR",
    });
    const toggleUpdateForm = ref(false);
    const toggleCreateForm = ref(false);
    const canMassUpdate = ref(false);

    const fireDeleteClient = (client) => {
      if (confirm("Are you sure?")) {
        canMassUpdate.value = true;
        clients.value.splice(clients.value.indexOf(client), 1);
      }
    };
    const fireCreatingClient = () => {
      if (!toggleCreateForm.value) {
        clients.value.push(client.value);
      }
      toggleCreateForm.value = !toggleCreateForm.value;
    };
    const fireUpdateClient = (client) => {
      canMassUpdate.value = true;
      clients.value.splice(
        clients.value.findIndex((item) => item.phone === client.phone),
        1,
        client
      );
      client.value = {};
      toggleCreateForm.value = false;
      toggleUpdateForm.value = false;
    };
    const fireUpdatingClient = (client) => {
      if (!toggleUpdateForm.value) {
        client.value = client;
      }
      toggleUpdateForm.value = !toggleUpdateForm.value;
    };
    const fireShowingSmsCode = (client) => {
      api.code(company, client.id).then((code) => {
        alert(code);
      });
    };
    const fireApplyClients = (data) => {
      clients.value = data;
    };

    const updateClientsFromCompany = () => {
      api.store(company, clients.value).then(fireApplyClients);
    };

    api.index(company).then(fireApplyClients);

    const fireNotificationClients = () => {
      apiNotification.clients(company, today.value).then(() => alert("Clients notified."));
    };

    const fireNotificationManagers = () => {
      apiNotification.managers(company, today.value).then(() => alert("Managers notified."));
    };

    return {
      company_id: company,
      canMassUpdate,
      clients,
      client,
      toggleCreateForm,
      toggleUpdateForm,
      fireUpdateClient,
      fireCreatingClient,
      fireUpdatingClient,
      fireDeleteClient,
      fireApplyClients,
      updateClientsFromCompany,
      fireShowingSmsCode,
      today,
      fireNotificationClients,
      fireNotificationManagers,
    };
  },
};
</script>

<style scoped></style>
