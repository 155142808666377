import axios from "axios";

/**
 * Created by Alexander Golovnya on 2019-06-17.
 */
export default {
  index(company_id) {
    return axios
      .get(`/api/hr-list/by-company/${company_id}`)
      .then((response) => {
        return response.data.data;
      });
  },
  store(company_id, clients) {
    return axios
      .post(`/api/hr-list/by-company/${company_id}`, {
        data: clients,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  code(company_id, client_id) {
    return axios
      .get(`/api/hr-list/by-company/${company_id}/sms_code/${client_id}`)
      .then((response) => {
        return response.data.code;
      });
  },
};
