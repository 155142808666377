import apiSignin from "../../api/signin";
import createStorageClient from "@/helpers/storage";
import { STORAGE_KEYS } from "@/config/storage";
import { ROUTES_NAMES } from "@/router";

import {
  LOGIN,
  LOGOUT,
  INIT_AUTH,
  SET_LANGUAGE,
  SET_COUNTRY,
  SET_IF_UPDATE_FOUND,
  SET_IF_LOGOUT_FOUND,
} from "../../mutation-types";
import packageJson from "../../../package.json";
import axios from "axios";
import i18n from "../../i18n";
import config from "../../config";

export const USER_ROLES = {
  accountant: "accountant",
  accountantlocal: "accountantlocal",
  admin: "admin",
  finance: "finance",
  guest: "guest",
  salemanager: "salemanager",
  superadmin: "superadmin",
  support2manager: "support2manager",
  supportmanager: "supportmanager",
  technical_manager: "technical manager",
};

const storage = createStorageClient();

const state = {
  auth: {
    access_token: "",
    refresh_token: "",
    expires_in: "",
  },
  locale: "ru",
  country: "by",
  app_update_request: false,
  details: {
    position: USER_ROLES.guest,
    version: "",
  },
  config: {
    policies: {
      [USER_ROLES.guest]: [],
      [USER_ROLES.accountantlocal]: ["supplier_act_list"],
      [USER_ROLES.accountant]: ["supplier_act_list"],
      [USER_ROLES.support2manager]: [
        "supplier_list",
        "user_list",
        ROUTES_NAMES.companies.list,
        "visit_journal",
        "helpdesk",
        "helpdesk_mobile",
      ],
    },
  },
};

// getters
const getters = {
  routes(state, getters) {
    const routes = {};

    if (!getters.loggedin) {
      routes.login = "login";
    }

    const policyList = state.config.policies[state.details.position];

    if (policyList) {
      policyList.forEach((routeName) => {
        routes[routeName] = routeName;
      });
    } else {
      if (state.details.position !== USER_ROLES.guest) {
        routes["visit_journal"] = "visit_journal";
        routes["helpdesk"] = "helpdesk";
        routes["helpdesk_mobile"] = "helpdesk_mobile";
      }

      if (getters.isSupportManager) {
        routes[ROUTES_NAMES.coupons.list] = "coupons";
        routes[ROUTES_NAMES.notifications.list] = "notifications";
      }
    }

    return routes;
  },
  position(state) {
    return state.details.position;
  },
  locale(state) {
    return state.locale;
  },
  country(state) {
    return state.country;
  },
  loggedin(state) {
    return !!state.auth.refresh_token;
  },
  isSuperAdmin(state) {
    return state.details.position === USER_ROLES.superadmin;
  },
  isAdmin(state) {
    return (
      state.details.position === USER_ROLES.superadmin ||
      state.details.position === USER_ROLES.admin
    );
  },
  isSaleManager(state) {
    return (
      state.details.position === USER_ROLES.superadmin ||
      state.details.position === USER_ROLES.admin ||
      state.details.position === USER_ROLES.salemanager
    );
  },
  isSupportManager(state) {
    return (
      state.details.position === USER_ROLES.superadmin ||
      state.details.position === USER_ROLES.admin ||
      state.details.position === USER_ROLES.salemanager ||
      state.details.position === USER_ROLES.supportmanager
    );
  },
  isLocalManager(state) {
    return (
      state.details.position === USER_ROLES.superadmin ||
      state.details.position === USER_ROLES.admin ||
      state.details.position === USER_ROLES.support2manager
    );
  },
  isAccountant(state) {
    return (
      state.details.position === USER_ROLES.superadmin ||
      state.details.position === USER_ROLES.admin ||
      state.details.position === USER_ROLES.accountant ||
      state.details.position === USER_ROLES.accountantlocal
    );
  },
  appHasNewVersion(state) {
    return state.app_update_request;
  },
  getRefreshToken(state) {
    return state.auth.refresh_token;
  },
  getAccessToken(state) {
    return state.auth.access_token;
  },
  routeByPosition(state) {
    const position = state.details.position;
    switch (position) {
      case USER_ROLES.accountant:
      case USER_ROLES.accountantlocal:
      case USER_ROLES.admin:
        return "supplier_act_list";
      case USER_ROLES.superadmin:
      case USER_ROLES.supportmanager:
      case USER_ROLES.support2manager:
        return "helpdesk";
      default:
        return "visit_journal";
    }
  },
};

// actions
const actions = {
  setLanguage({ commit }, locale) {
    if (config.supported_languages.indexOf(locale) === -1) {
      return;
    }
    apiSignin.set_header("X-localization", locale);
    commit(SET_LANGUAGE, locale);
  },
  setCountry({ commit }, country) {
    apiSignin.set_header("X-country", country);
    commit(SET_COUNTRY, country);
  },
  async signin({ commit, state }, input) {
    commit("error/clear", undefined, { root: true });

    const data = await apiSignin.signin({
      email: input.email,
      password: input.password,
    });

    commit(LOGIN, data);

    return getters.routeByPosition(state);
  },
  async signUp({ commit, state }, input) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await apiSignin.signUp({
        email: input.email,
        password: input.password,
        password_confirmation: input.password_confirmation,
        name: input.name,
        country: input.country,
        phone_number: input.phone_number,
      });
      commit(LOGIN, data);
      return getters.routeByPosition(state);
    } catch (error) {
      throw error;
    }
  },
  async forgotPassword({ commit }, { email }) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await apiSignin.forgotPassword(email);
      commit("error/set_error", data);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async resetPassword(
    { commit },
    { token, email, password, passwordConfirmation }
  ) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await apiSignin.resetPassword(
        token,
        email,
        password,
        passwordConfirmation
      );
      commit(LOGIN, data);
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateApp({ commit }) {
    commit(SET_IF_UPDATE_FOUND, true);
  },
  async logout({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await apiSignin.logout();
      commit(LOGOUT);
      return response.data;
    } catch (error) {
      commit(LOGOUT);
    }
  },
  refreshLogin({ commit, state }, err) {
    return new Promise((resolve, reject) => {
      return apiSignin.refreshLogin(getters.getRefreshToken(state)).then(
        (response) => {
          commit("setRefreshToken", response.data);
          err.config.__isRetryRequest = true;
          err.config.headers.Authorization =
            "Bearer " + getters.getAccessToken(state);
          resolve(axios(err.config));
        },
        (error) => {
          commit(SET_IF_LOGOUT_FOUND, error.response);
          reject(error);
        }
      );
    });
  },
};

const Auth = {
  clearAuth: () => {
    storage.clear(STORAGE_KEYS.auth.details);
  },
  getAuth: () => {
    const authStoredData = storage.get(STORAGE_KEYS.auth.details);
    return authStoredData;
  },
  setAuth: (state) => {
    const { auth, country, details, locale } = state;
    const authData = {
      auth,
      country,
      details,
      locale,
    };

    storage.save(authData, STORAGE_KEYS.auth.details);
  },
};

// mutations
const mutations = {
  [SET_IF_UPDATE_FOUND](state, response) {
    if (response && response.headers) {
      const prevVersion = localStorage.getItem("x-supplier-version");
      const version = response.headers["x-supplier-version"];
      if (version && version !== prevVersion) {
        localStorage.setItem("x-supplier-version", version);
        state.app_update_request = true;
      }
    }
  },
  [SET_IF_LOGOUT_FOUND](state, response) {
    if (response && response.status > 400) {
      state.auth.refresh_token = null;
    }
  },
  [SET_LANGUAGE](state, locale) {
    state.locale = locale;
    i18n.locale = locale;
    Auth.setAuth(state);
  },
  [SET_COUNTRY](state, country) {
    state.country = country;
    Auth.setAuth(state);
  },
  [INIT_AUTH](state) {
    state.details.version = packageJson.version;
    apiSignin.set_header("X-localization", state.locale);
    apiSignin.set_header("X-country", state.country);
    // Check if the ID exists
    let oldState = Auth.getAuth();
    if (oldState) {
      try {
        apiSignin.set_header("X-localization", "ru");
        apiSignin.set_header("X-country", "by");
        // Check the version stored against current. If different, don't
        // load the cached version
        if (state.details.version === packageJson.version) {
          if (oldState.auth) {
            state.auth = oldState.auth;
            state.locale = oldState.locale;
            i18n.locale = oldState.locale;
            state.country = oldState.country;
            apiSignin.set_header(
              "Authorization",
              "Bearer " + oldState.auth.access_token
            );
            apiSignin.set_header("X-localization", state.locale);
            apiSignin.set_header("X-country", state.country);
          }
          if (oldState.details) {
            state.details = oldState.details;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  setRefreshToken(state, tokenData) {
    // sometimes tokens are in snake case and other times they are in camelcase :/
    const now = new Date();
    const tokensExpiry = now.setTime(
      now.getTime() + 1000 * tokenData.expires_in
    );

    apiSignin.set_header("Authorization", "Bearer " + tokenData.access_token);

    state.auth = {
      access_token: tokenData.access_token,
      refresh_token: tokenData.refresh_token,
      expires_in: tokensExpiry,
    };

    state.details.position = tokenData.position;

    Auth.setAuth(state);
  },
  [LOGIN](state, tokenData) {
    mutations.setRefreshToken(state, tokenData);
    state.details.position = tokenData.position;
  },
  [LOGOUT](state) {
    apiSignin.set_header("Authorization", "");

    state.auth = {
      access_token: "",
      refresh_token: "",
      expires_in: "",
    };

    state.details.position = USER_ROLES.guest;

    Auth.clearAuth(state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
