import axios from "axios";

/**
 * Created by Alexander Golovnya on 2019-06-17.
 */
export default {
  clients(company_id, today) {
    return axios
      .post(
        `/api/hr-list/by-company/${company_id}/notify-clients?date=${today}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  managers(company_id, today) {
    return axios
      .post(
        `/api/hr-list/by-company/${company_id}/notify-managers?date=${today}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
};
