<template>
  <div class="checkbox" :class="elementClass">
    <input v-model="value" type="checkbox" :disabled="props.disabled" v-bind="$attrs" />
    <div class="switcher"></div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

export default {
  emits: ["modelValue:update"],

  model: {
    prop: "modelValue",
    event: "modelValue:update",
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Boolean, Number],
      default: false,
    },
    numeric: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      checked: false,
    });

    const value = computed({
      get() {
        return props.inverted ? !props.modelValue : props.modelValue;
      },
      set(newVal) {
        const booleanValue = props.inverted ? !newVal : newVal;
        let emitValue = booleanValue;

        if (props.numeric) {
          emitValue = booleanValue ? 1 : 0;
        }

        emit("modelValue:update", emitValue);
      },
    });

    const elementClass = computed(() => ({
      checkbox__checked: value.value,
      checkbox__disabled: props.disabled,
    }));

    return {
      props,
      state,

      value,
      elementClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  width: 80px;
  height: 30px;
  background: #e7e7e7;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: background-color 200ms ease-in-out;

  input {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
  }

  .switcher {
    width: 24px;
    height: 24px;
    background-color: #fafafa;
    box-shadow: 0px 0px 4px rgba(39, 39, 39, 0.35);
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: all 200ms ease-in-out;
  }

  &__checked {
    background-color: #79c99e;

    .switcher {
      right: 3px;
      left: 53px;
    }
  }

  &__disabled {
    opacity: 0.5;

    input {
      cursor: default;
    }
  }
}
</style>
