<template>
  <div class="container">
    <form class="content" @submit.prevent="submitForm">
      <div class="row-header">
        <h1>
          <template v-if="isEditMode">Edit company</template>
          <template v-else>Add company</template>
        </h1>
      </div>

      <BreadCrumbs />
      <ErrorBanner :message="state.error || errors.errorMessage" />

      <SpinnerBrand v-if="state.processing" centered />
      <template v-else-if="!state.error">
        <section class="section">
          <BaseInput v-if="isEditMode" v-model="company.id" class="small" label="ID:" type="text" disabled />
          <NewSelectInput
            v-model="company.city"
            class="small"
            label="Location:"
            placeholder="Select location"
            :options="locationsOptions"
            :error="errors.city"
            required
          />
          <NewSelectInput
            v-model="company.locale"
            class="small"
            label="Locale:"
            placeholder="Select locale"
            :options="localesOptions"
            :error="errors.locale"
            required
          />
          <SearchInput
            v-model="company.timezone"
            class="small timezone-input"
            label="Timezone:"
            :item-text-field="({ id }) => id"
            :offline-items="timezonesOptions"
            :error="errors.timezone"
            input-mode
            required
          />
          <NewSelectInput
            v-model="company.sellStrategy"
            label="Sell Strategy"
            placeholder="Select sell strategy"
            :error="errors.sell_strategy"
            :options="sellStrategyOptions"
            class="small"
            required
            @select="onSellStrategyChange($event, company.defaultSubscriptionType)"
          />
          <div class="section">
            <NewSelectInput
              v-model="company.registrationType"
              label="Registration type"
              placeholder="Select registration type"
              :error="errors.registration_type"
              :options="registrationTypeOptions"
              class="medium"
            />
            <NewSelectInput
              v-if="company.registrationType !== 0"
              v-model="company.defaultSubscriptionType"
              label="Default subscription"
              placeholder="Select default subscription"
              :error="errors.default_subscription"
              :options="defaultSubscriptionOptions"
              class="medium"
              :required="company.registrationType !== 0"
              @select="onDefaultSubscriptionChange($event, company.sellStrategy)"
            />
            <NewSelectInput
              v-if="company.registrationType !== 0"
              v-model="company.compensationType"
              label="Compensation type"
              placeholder="Select compensation type"
              :error="errors.compensation_type"
              :options="compensationTypeOptions"
              class="medium"
              :required="company.registrationType !== 0"
              @select="onCompensationTypeSelect"
            />
            <BaseInput
              v-if="company.registrationType !== 0"
              v-model="company.compensationAmount"
              type="text"
              label="Compensation amount (VAT included):"
              class="medium"
              :error="errors.compensation_amount"
              :required="company.registrationType !== 0"
              :disabled="isCompensationTypeFull"
            />
            <div v-if="company.registrationType !== 0" class="white-list-domains">
              <p class="white-list-domains_title">
                <span>{{ $t("Corporate domain names:") }}</span>
                <Tooltip :text="$t('The domain name must contain only letters, numbers, hyphens and periods.')" />
              </p>
              <div v-for="domain in whiteListDomains" :key="domain" class="white-list-domains_item">
                <BaseInput
                  v-model="company.whiteListDomains[domain]"
                  type="text"
                  validator="domain"
                  :emitValidationErrorsById="domain"
                  @validation-error="onDomainsValidationError"
                  class="medium"
                />

                <a href="#" class="white-list-domains_delete-control" @click.prevent="deleteCorporateDomain(domain)">
                  <Icon icon="delete" />
                </a>
              </div>
              <AddInstanceButton @click="addCorporateDomain">{{ $t("Add Corporate domain name") }}</AddInstanceButton>
            </div>
          </div>

          <DatePickerInput
            v-model="company.startAt"
            label="Activation date:"
            class="small"
            placeholder="yyyy-mm-dd"
            type="date"
            tooltip-text="Only for launching new companies with custom activation date"
            :disabled-date="(date) => date <= new Date()"
            :clearable="false"
            :isUnlocked="isStartAtInputUnlocked"
            locked
            @unlock="onUnlockStartAtInput"
          />
          <BaseInput v-model="company.name" type="text" label="Company Name:" :error="errors.name" required />
          <NewSelectInput
            v-if="isManagerSelectVisible"
            v-model="company.managerId"
            label="Manager:"
            placeholder="Select a manager"
            :error="errors.manager_id"
            :options="managersOptions"
            class="large"
          />
          <div v-if="managerContacts" class="manager-contacts">
            <p>Email/Phone:</p>
            <div class="manager-contacts_data">
              <Icon icon="copy" v-clipboard="managerContacts" class="copy" />
              {{ managerContacts }}
            </div>
          </div>
        </section>
        <h2>HR SETTINGS</h2>
        <ErrorBanner />
        <section class="section">
          <BaseInput
            v-model="company.allowUpdatePeriod"
            type="text"
            label="Dates we update list:"
            class="large"
            tooltip-text="Examples: 0525, 0115, 1022"
            :error="errors.allow_update_period"
            :disabled="isRegistrationTypeRegistrationForm"
          />
          <BaseInput
            v-model="company.minOrderItems"
            type="text"
            label="Minimum order items:"
            class="large"
            :error="errors.min_order_items"
            :disabled="isRegistrationTypeRegistrationForm"
          />
        </section>
        <h2>DATA FOR THE CONTRACT</h2>
        <ErrorBanner />
        <section class="section">
          <BaseInput
            v-model="company.vatNumber"
            type="text"
            label="VAT NUMBER:"
            class="large"
            :error="errors.vat_number"
            required
            :validator="vatNumberValidator"
            emitValidationErrorsById="vat"
            @validation-error="onVatValidationError"
            @input="onVatNumberInput"
          />
          <BaseInput
            v-model="company.legalName"
            type="text"
            label="Legal Name:"
            class="large"
            :error="errors.legal_name"
            :disabled="state.legalNameProcessing"
            :processing="state.legalNameProcessing"
            required
          />
        </section>
        <h2>BENEFIARY</h2>
        <ErrorBanner />
        <section class="section">
          <BaseInput
            v-model="company.legalAddress"
            type="text"
            label="Legal Address:"
            class="large"
            :error="errors.legal_address"
            required
          />
          <BaseInput
            v-model="company.legalContactPhone"
            type="text"
            label="Contact Phone:"
            class="large"
            :error="errors.legal_contact_phone"
            required
          />

          <template v-if="!isCountryCY">
            <BaseInput v-model="company.bankName" type="text" label="Bank:" :error="errors.bank_name" />
            <BaseInput v-model="company.swift" type="text" label="SWIFT:" class="large" :error="errors.swift" />
            <BaseInput v-model="company.accnum" type="text" label="Account:" class="large" :error="errors.accnum" />
          </template>
        </section>
        <h2>OPTIONAL</h2>
        <ErrorBanner />
        <section class="section">
          <BaseInput v-model="company.officialRepresentative" type="text" label="Representative:" class="large" />
          <BaseInput
            v-model="company.powerOfAttorneyRepresentative"
            type="text"
            label="Power of Attorney representative:"
            class="large"
          />
          <BaseInput v-model="company.postAddress" type="text" label="Post Address:" />
          <BaseInput v-model="company.additional" type="text" label="Additional:" />
        </section>

        <template v-if="isEditMode">
          <div class="toggle">
            <span>Disconnect the company:</span>
            <CheckboxInput v-model="company.activated" inverted numeric />
          </div>
          <BaseInput
            v-model="company.deactivationCause"
            type="text"
            label="Reason:"
            :class="{ hide: company.activated }"
          />
        </template>

        <BaseInput
          v-for="(legalDocumentContent, legalDocumentId) in company.legalDetails"
          v-model="company.legalDetails[legalDocumentId]"
          class="large"
          type="text"
          :error="errors[snakeCase(legalDocumentId)]"
          :key="legalDocumentId"
          :label="getLegalDetailsLabel(legalDocumentId)"
          required
        />

        <div v-if="isEditMode" class="accept-agreement">
          <ErrorBanner :message="state.downloadError" />
          <p>By making payment you accept “Website agreement” according 5.6 paragraph</p>
          <BaseCheckbox
            v-for="doc in availableToSignDocumentsList"
            :key="`company-signed-doc-${doc.id}`"
            @change="onDocumentSignChange(doc.id, $event)"
            :value="company.documentAccepted.includes(doc.id)"
          >
            Signed
            <a href="#" class="accept-agreement_link" @click.prevent="downloadDocument(doc.id)">{{ doc.name }}</a>
          </BaseCheckbox>
        </div>

        <div class="required-fields-hint">
          <p>Required fields are marked with</p>
          <Icon fill="#e3001b" icon="tooltip" />
        </div>

        <Button type="submit" :disabled="!isObjectChanged" :processing="processingSave">
          <template v-if="isEditMode">Save</template>
          <template v-else>Save and continue</template>
        </Button>
      </template>
    </form>
  </div>
</template>

<script>
import { computed, ref, reactive, nextTick, watchEffect, watch, onBeforeMount } from "@vue/composition-api";
import cloneDeep from "lodash/cloneDeep";
import clone from "lodash/clone";
import Vue from "vue";

import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import Icon from "@/components/icons/Icon.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import Tooltip from "@/components/tooltips/Tooltip.vue";
import AddInstanceButton from "@/components/buttons/AddInstanceButton.vue";

import { camelCaseObjectKeys, snakeCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import snakeCase from "lodash/snakeCase";
import { CONFIG_ACTION_FETCH_CONFIGS, COMPENSATION_TYPES } from "@/store/modules/config";
import { useRoute } from "@/helpers/router/routeCompositionAPI";
import download from "@/helpers/request/download";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import router, { ROUTES_NAMES } from "@/router";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";
import useIsObjectChanged from "@/composables/useIsObjectChanged";
import validateRequiredFields from "@/helpers/validators/inputs/validateRequiredFields";
import scrollToErrors from "@/helpers/document/scrollToErrors";
import { MODAL_ACTION_OPEN, MODAL_TYPES } from "@/store/modules/modal";

const REQUIRED_COMPANY_KEYS = [
  "locale",
  "timezone",
  "sellStrategy",
  "name",
  "city",
  "allowUpdatePeriod",
  "minOrderItems",
  "vatNumber",
  "legalName",
  "legalAddress",
  "legalContactPhone",
  "legalDetails",
];

const COMPANY_LEGAL_AGREEMENT_PREFIX = "agreement_";

export default {
  components: {
    BaseCheckbox,
    BaseInput,
    BreadCrumbs,
    Button,
    CheckboxInput,
    ErrorBanner,
    Icon,
    SearchInput,
    NewSelectInput,
    SpinnerBrand,
    DatePickerInput,
    Tooltip,
    AddInstanceButton,
  },

  setup() {
    const route = useRoute();
    const { fetch, errors, processingMethods, setErrors, resetErrors } = useRequest({ errorsFormat: "flat" });

    const companyInitialState = ref({});
    const isStartAtInputUnlocked = ref(false);

    const state = reactive({
      processing: false,
      error: null,
      domainsValidationErrors: {},
      vatValidationError: "",
      downloadError: null,

      legalNameProcessing: false,
      isLegalNameChanged: false,
    });

    const company = reactive({
      id: null,
      accnum: "",
      activated: true,
      additional: "",
      allowUpdatePeriod: "",
      bankName: "",
      city: "",
      deactivationCause: "",
      documentAccepted: [],
      defaultCompensationAmount: {},
      legalAddress: "",
      legalContactPhone: "",
      legalDetails: {},
      legalName: "",
      locale: "",
      managerId: null,
      minOrderItems: "",
      name: "",
      officialRepresentative: "",
      postAddress: "",
      powerOfAttorneyRepresentative: "",
      startAt: "",
      swift: "",
      sellStrategy: "",
      registrationType: 0,
      defaultSubscriptionType: "",
      compensationType: 0,
      compensationAmount: "",
      timezone: "",
      vatNumber: "",
      whiteListDomains: {},
    });

    const { isObjectChanged } = useIsObjectChanged(companyInitialState, company);
    const isRegistrationTypeRegistrationForm = computed(() => company.registrationType === 1);
    const isCompensationTypeFull = computed(
      () =>
        company.compensationType === COMPENSATION_TYPES.FULL ||
        company.compensationType === COMPENSATION_TYPES.FULL_WITH_EXTENSION
    );

    const whiteListDomains = computed(() => Object.keys(company.whiteListDomains));

    const companyId = computed(() => route.value.params.companyId);
    const isEditMode = computed(() => !!companyId.value);

    const processingFetch = computed(() => processingMethods.GET);
    const processingSave = computed(() => processingMethods.PUT || processingMethods.POST);

    const configStore = computed(() => store.state.config);
    const authStore = computed(() => store.state.auth);

    const sellers = computed(() => configStore.value.country.sellers);

    const isCountryBY = computed(() => authStore.value.country?.toLowerCase() === "by");
    const isCountryCY = computed(() => authStore.value.country?.toLowerCase() === "cy");

    const vatNumberValidator = computed(() => (isCountryBY.value ? "byUnnNumber" : ""));

    const locationsOptions = computed(() => {
      const locationsList = configStore.value.country.locations;
      const locationsOptions = locationsList.map((v) => ({ text: v, value: v }));
      return locationsOptions;
    });

    const localesOptions = computed(() => {
      const availableLocalesList = configStore.value.portal.availableLocales;
      const localesOptions = availableLocalesList.map((v) => ({ text: v, value: v }));
      return localesOptions;
    });

    const managersData = computed(() => configStore.value.managers);
    const managerContacts = computed(() => {
      const currentManager = managersData.value.find((manager) => manager.id === company.managerId);

      let contacts = null;

      if (currentManager) {
        const { email, phone_number } = currentManager;
        contacts = [email, phone_number].filter(Boolean).join(", ");
      }

      return contacts;
    });

    const managersOptions = computed(() => {
      const managersOptions = managersData.value.map(({ id, name }) => {
        return {
          text: name,
          value: id,
        };
      });

      return managersOptions;
    });

    const isManagerSelectVisible = computed(() => managersOptions.value?.length > 0);

    const timezonesOptions = computed(() => {
      let options = [];

      if (window.Intl) {
        const timezonesList = Intl.supportedValuesOf("timeZone");
        options = timezonesList.map((v) => ({ id: v }));
      }

      return options;
    });

    const sellStrategyOptions = computed(() => {
      return isCountryBY.value
        ? sellers.value.filter((seller) => seller.code === "by3").map(({ code, name }) => ({ value: code, text: name }))
        : sellers.value.map(({ code, name }) => ({ value: code, text: name }));
    });

    const registrationTypeOptions = computed(() => {
      return configStore.value.country.registrationType.map(({ code, name }) => ({
        value: code,
        text: name,
      }));
    });

    const defaultSubscriptionOptions = computed(() => {
      const supportedSubscriptionsList = configStore.value.country.supportedSubscriptions;
      return supportedSubscriptionsList.map((v) => ({ text: v, value: v }));
    });

    const compensationTypeOptions = computed(() => {
      return configStore.value.country.compensationType.map(({ code, name }) => ({
        value: code,
        text: name,
      }));
    });

    const availableToSignDocumentsList = computed(() => configStore.value.country.templates.availableToSign);

    async function getCompanyData() {
      const url = isEditMode.value ? URLS.companies.single(companyId.value) : URLS.companies.default;
      const { data, error, errors } = await fetch(url);

      if (error) {
        state.error = errors.errorMessage;
      } else {
        const preparedData = camelCaseObjectKeys(data);
        updateCompanyData(preparedData);
      }
    }

    function updateCompanyData(newCompanyData) {
      const companyFieldsList = Object.keys(company);

      companyFieldsList.forEach((field) => {
        if (field === "whiteListDomains" && newCompanyData[field]) {
          prepareCompanyWhiteListDomainsObject(newCompanyData[field]);
        } else {
          company[field] = newCompanyData[field] ?? company[field];
        }
      });

      try {
        company.legalDetails = JSON.parse(newCompanyData.legalDetails);
      } catch (JsonParseError) {
        console.error(JsonParseError);
      }
      companyInitialState.value = cloneDeep(company);
    }

    function prepareCompanyWhiteListDomainsObject(whiteListDomainsString) {
      const whiteListDomains = whiteListDomainsString.split(",");

      whiteListDomains.forEach((domain) => Vue.set(company.whiteListDomains, domain, domain));
    }

    async function getInitialData() {
      state.error = null;
      state.processing = true;

      try {
        const configsList = [{ country: [store.state.auth.country] }, "managers", "portal"];

        await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, configsList);
        await getCompanyData();
      } catch (error) {
        state.error = error;
      }

      state.processing = false;
    }

    async function submitForm() {
      if (processingSave.value) {
        return;
      }

      resetErrors();

      const errors = validateRequiredFields(company, REQUIRED_COMPANY_KEYS);

      if (errors) {
        return nextTick(() => {
          setErrors(snakeCaseObjectKeys(errors));
          scrollToErrors();
        });
      }

      if (Object.values(state.domainsValidationErrors).some((item) => !!item)) {
        scrollToErrors();
        return;
      }

      const switchToEditMode = !isEditMode.value;
      const method = isEditMode.value ? "PUT" : "POST";
      const url = isEditMode.value ? URLS.companies.single(companyId.value) : URLS.companies.index;
      const companyPreparedData = prepareCompanyData();
      const requestData = snakeCaseObjectKeys(companyPreparedData);

      const { data, error } = await fetch({
        data: requestData,
        method,
        url,
      });

      if (!error && data.id) {
        if (switchToEditMode) {
          router.push({ name: ROUTES_NAMES.companies.edit, params: { companyId: data.id } });
        } else {
          router.push({ name: ROUTES_NAMES.companies.index });
        }
      } else {
        scrollToErrors();
      }
    }

    function prepareCompanyData() {
      const whiteListDomains = Object.values(company.whiteListDomains).join(",");

      return { ...company, whiteListDomains, legalDetails: JSON.stringify(company.legalDetails) };
    }

    function onDocumentSignChange(documentId, isSigned) {
      if (isSigned) {
        company.documentAccepted = [...company.documentAccepted, documentId];
      } else {
        company.documentAccepted = company.documentAccepted.filter((id) => id != documentId);
      }
    }

    async function downloadDocument(documentId) {
      state.downloadError = null;

      const url = URLS.templates.download(documentId);
      try {
        await download(url);
      } catch (xhrError) {
        const { errorMessage } = prepareAxiosErrors(xhrError);
        state.downloadError = errorMessage;
      }
    }

    function onDomainsValidationError(errorMessage, domain) {
      state.domainsValidationErrors[domain] = errorMessage;
    }

    function onVatValidationError(errorMessage) {
      state.vatValidationError = errorMessage;
    }

    function onVatNumberInput(unn) {
      if (isCountryBY.value && !state.vatValidationError) {
        getLegalNameBY(unn);
      }
    }

    function onUnlockStartAtInput() {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.message,
        payload: {
          message: "Testing purpose only",
          headerText: "DEVELOPERS ONLY !",
          buttonText: "I am developer",
          onAccept: () => (isStartAtInputUnlocked.value = true),
        },
      });
    }

    async function getLegalNameBY(unn) {
      state.legalNameProcessing = true;

      const url = URLS.utils.unn(unn);
      const { data, error } = await fetch(url);

      if (!error) {
        company.legalName = data.registered_name || "";
      } else {
        company.legalName = companyInitialState.value.legalName;
      }

      state.legalNameProcessing = false;
    }

    function getLegalDetailsLabel(legalDocumentId) {
      if (legalDocumentId.startsWith(COMPANY_LEGAL_AGREEMENT_PREFIX)) {
        const documentID = legalDocumentId.replace(COMPANY_LEGAL_AGREEMENT_PREFIX, "").toUpperCase();
        return `Agreement ${documentID}`;
      }

      return legalDocumentId.toUpperCase();
    }

    function addCorporateDomain() {
      Vue.set(company.whiteListDomains, `corporateDomain${whiteListDomains.value.length + 1}`, "");
    }

    function deleteCorporateDomain(domain) {
      Vue.delete(company.whiteListDomains, domain);
    }

    watch(isRegistrationTypeRegistrationForm, (newValue) => {
      if (newValue) {
        company.allowUpdatePeriod = companyInitialState.value.allowUpdatePeriod;
        company.minOrderItems = companyInitialState.value.minOrderItems;
      } else {
        company.whiteListDomains = clone(companyInitialState.value.whiteListDomains);
      }
    });

    function onCompensationTypeSelect(selectedValue) {
      if (selectedValue === COMPENSATION_TYPES.FULL || selectedValue === COMPENSATION_TYPES.FULL_WITH_EXTENSION) {
        company.compensationAmount =
          company.defaultCompensationAmount[company.sellStrategy]?.[company.defaultSubscriptionType];
      }
    }

    function onSellStrategyChange(strategy, subscription) {
      company.compensationAmount = company.defaultCompensationAmount[strategy]?.[subscription];
    }

    function onDefaultSubscriptionChange(subscription, strategy) {
      company.compensationAmount = company.defaultCompensationAmount[strategy]?.[subscription];
    }

    onBeforeMount(() => {
      getInitialData();
    });

    return {
      errors,
      isObjectChanged,

      state,
      company,

      managerContacts,
      whiteListDomains,
      isEditMode,
      isManagerSelectVisible,
      isStartAtInputUnlocked,
      isCountryCY,
      isRegistrationTypeRegistrationForm,
      isCompensationTypeFull,
      processingFetch,
      processingSave,
      locationsOptions,
      localesOptions,
      managersOptions,
      timezonesOptions,
      sellStrategyOptions,
      registrationTypeOptions,
      defaultSubscriptionOptions,
      compensationTypeOptions,
      availableToSignDocumentsList,
      vatNumberValidator,

      downloadDocument,
      onDocumentSignChange,
      onUnlockStartAtInput,
      getLegalDetailsLabel,
      snakeCase,
      submitForm,
      addCorporateDomain,
      deleteCorporateDomain,
      onDomainsValidationError,
      onCompensationTypeSelect,
      onSellStrategyChange,
      onDefaultSubscriptionChange,
      onVatNumberInput,
      onVatValidationError,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  .content {
    padding: 0 47px;

    h2 {
      font-weight: 600;
      font-size: 22px;
      margin-top: 26px;
    }

    .section {
      display: flex;
      gap: 24px;
      align-items: flex-end;
      flex-wrap: wrap;

      .manager-contacts {
        display: flex;
        flex-direction: column;
        gap: 14px;
        font-size: 18px;
        font-weight: 500;
        color: #444545;

        &_data {
          display: flex;
          align-items: center;
          gap: 6px;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;

          .copy {
            cursor: pointer;
          }
        }
      }

      .timezone-input {
        align-self: flex-start;
      }

      .white-list-domains {
        display: flex;
        flex-direction: column;

        &_title {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          font-weight: 500;
          color: #444545;
          margin-bottom: 6px;
        }

        &_item {
          display: flex;
          gap: 16px;
          align-items: start;
        }

        &_delete-control {
          svg {
            fill: #9c9c9c;
          }

          &:hover {
            svg {
              fill: #e3001b;
            }
          }
        }
      }
    }

    .toggle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 26px;
      margin-bottom: 20px;

      span {
        display: inline-block;
        margin-right: 20px;
        font-weight: 500;
        font-size: 18px;
        color: #444545;
      }
    }

    .accept-agreement {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 6px;
      margin-bottom: 30px;
      color: #444545;
      font-size: 14px;
      font-weight: 400;

      &_link {
        font-size: 15px;
        color: #346aed;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .hide {
      visibility: hidden;
    }

    .required-fields-hint {
      display: flex;
      gap: 6px;
      margin-bottom: 12px;
      color: #e3001b;
    }
  }

  .small {
    width: 178px;
  }

  .medium {
    width: 278px;
  }

  .large {
    width: 580px;
  }
}
</style>
