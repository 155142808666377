/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
function flatErrorResponse(error) {
  if (!error.response) {
    return error;
  }

  return error.response.data instanceof Blob
    ? error.response.statusText
    : error.response.data;
}

// initial state
const state = {
  errorResponse: {},
};

// getters
const getters = {
  getErrorResponse(state) {
    return state.errorResponse;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  set_error(state, error) {
    state.errorResponse = flatErrorResponse(error);
  },
  clear(state) {
    state.errorResponse = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
