<template>
  <div class="container">
    <h1>{{ $t("Coupons search") }}</h1>
    <BreadCrumbs />
    <ErrorBanner />
    <form action="#" class="coupons-search-controls" @submit.prevent="onSearchSubmit">
      <ul class="coupons-search-controls_inputs">
        <li>
          <BaseInput label="Coupon №" v-model="state.search.number" />
        </li>
        <li>
          <DatePickerInput v-model="state.search.taken_at" label="Date" :placeholder="$t('yyyy-mm-dd')" />
        </li>
        <li>
          <BaseInput label="Phone" v-model="state.search.phone" />
        </li>
        <li>
          <BaseInput label="Subscriber" v-model="state.search.subscriber" />
        </li>
      </ul>
      <Button type="submit" button-type="light">{{ $t("Search") }}</Button>
    </form>
    <SpinnerBrand v-if="state.processingSearch" centered />
    <SimpleTable
      v-else
      class="table"
      :headers="TABLE_HEADERS"
      :cells="TABLE_CELLS"
      :items="state.searchResults"
      :processing-items="state.processingCouponDeletion"
      show-delete-button
      @delete="onDelete"
    />
  </div>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

import BaseInput from "@/components/inputs/BaseInput.vue";
import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import router from "@/router";
import URLS from "@/config/urls";
import request from "@/helpers/request";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import dateToLocaleString from "@/helpers/date/dateToLocaleString";
import clearPhone from "@/helpers/converters/clearPhone";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";

const TABLE_HEADERS = ["Coupon №", "Date", "Phone", "Subscriber", "Supplier", "Name of Attraction"];
const TABLE_CELLS = ["value", "taken_at", "subscriber.phone", "subscriber.name", "supplier_name", "attraction_name"];

export default {
  components: {
    BaseInput,
    BreadCrumbs,
    Button,
    ErrorBanner,
    SimpleTable,
    SpinnerBrand,
    DatePickerInput,
  },

  setup() {
    const state = reactive({
      processingSearch: false,
      processingCouponDeletion: null,

      errors: {},

      searchResults: [],

      search: {
        number: "",
        phone: "",
        subscriber: "",
        taken_at: "",
      },
    });

    const isError = computed(() => Object.keys(state.errors).length > 0);

    function onRequestError(xhrError) {
      const { errors, errorMessage } = prepareAxiosErrors(xhrError);
      state.errors = { ...errors, errorMessage };
    }

    async function onSearchSubmit() {
      const { couponsGroupId } = router.currentRoute.params;
      const url = URLS.coupons.search(couponsGroupId);
      const preparedParams = {
        phone: clearPhone(state.search.phone),
      };
      const params = Object.assign({}, state.search, preparedParams);

      state.errors = {};
      state.searchResults = [];
      state.processingSearch = true;

      try {
        const result = await request({
          url,
          params,
        });

        state.searchResults = result.map(({ taken_at, ...item }) => {
          const coupon = {
            taken_at: dateToLocaleString(taken_at),
            ...item,
          };

          if (taken_at) {
            coupon.__hideDeleteButton = true;
          }

          return coupon;
        });
      } catch (xhrError) {
        onRequestError(xhrError);
      }

      state.processingSearch = false;
    }

    async function onDelete(couponId) {
      const url = URLS.coupons.single(couponId);
      const method = "DELETE";

      state.errors = {};
      state.processingCouponDeletion = couponId;

      try {
        await request({
          url,
          method,
        });

        removeCouponFromList(couponId);
      } catch (xhrError) {
        onRequestError(xhrError);
      }

      state.processingCouponDeletion = null;
    }

    function removeCouponFromList(couponId) {
      state.searchResults = state.searchResults.filter(({ id }) => id !== couponId);
    }

    return {
      TABLE_HEADERS,
      TABLE_CELLS,

      state,

      isError,

      onSearchSubmit,
      onDelete,
    };
  },
};
</script>

<style lang="scss" scoped>
.coupons-search-controls {
  display: flex;
  align-items: center;

  &_inputs {
    list-style: none;
    display: flex;
    align-items: flex-end;

    li {
      float: left;
      width: 178px;
      margin-right: 24px;
    }
  }
}

.table {
  margin-top: 24px;
}
</style>
