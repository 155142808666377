<template>
  <div v-if="isWrapped" class="spinner_container" :class="{ spinner_container__centered: props.centered }">
    <div class="spinner">
      <img src="../../assets/images/icons/icon_allsports.png" />
    </div>
  </div>
  <div v-else class="spinner">
    <img src="../../assets/images/icons/icon_allsports.png" />
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isWrapped = computed(() => props.wrapper || props.centered);

    return {
      props,
      isWrapped,
    };
  },
};
</script>

<style lang="scss" scoped>
.spinner_container {
  &__centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.spinner {
  &_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  img {
    animation: as-spin 1.4s infinite ease-in-out both;
  }
}

@keyframes as-spin {
  0% {
    -webkit-transform: scale(0);
    transform: scale(1) rotate(0deg);
  }

  40% {
    transform: scale(1.25) rotate(360deg);
  }

  80%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}
</style>
