/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";
import {
  buildFormDataFromObject,
  buildQueryFromObject,
} from "../middleware/api_functions";

export default {
  list(params) {
    const query = buildQueryFromObject(params);
    return axios
      .get(`/api/jrnl/admin/tickets/index${query}`)
      .then((response) => {
        return response.data;
      });
  },
  one(id) {
    return axios.get(`/api/jrnl/admin/tickets/${id}`).then((response) => {
      return response.data;
    });
  },
  update(ticket) {
    return axios
      .put(`/api/jrnl/admin/tickets/${ticket.id}`, ticket)
      .then((response) => {
        return response.data;
      });
  },
  delete(id) {
    return axios.delete(`/api/jrnl/admin/tickets/${id}`);
  },
  load_attraction(id) {
    return axios
      .get(`/api/jrnl/admin/tickets/attraction/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  store(ticket) {
    return axios.post(`/api/jrnl/admin/tickets`, ticket).then((response) => {
      return response.data;
    });
  },

  import_file(attraction, form) {
    const formData = buildFormDataFromObject(form);
    return axios
      .post(`/api/jrnl/admin/tickets/import/${attraction.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        return response.data;
      });
  },
  stats() {
    return axios.get(`/api/jrnl/admin/tickets/stats`).then((response) => {
      return response.data;
    });
  },
};
