/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api from "./api.js";
import platform from "platform";

// initial state
// shape: [{ supplier }]
const state = {
  tokens: [],
  history_tokens: [],
  devices: [],
};

// getters
const getters = {
  token_list(state) {
    return state.tokens;
  },
  token_history_list(state) {
    return state.history_tokens;
  },
  device_history_list(state) {
    return state.devices;
  },
};

// actions
const actions = {
  async update_error({ commit, state }, error) {
    try {
      commit("error/clear", undefined, { root: true });
      const new_token = await api.update_error(error);
      commit("token_replace", new_token);
    } catch (error) {
      commit("error/clear", error, { root: true });
    }
  },
  async token_list({ commit, state }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      const tokens = await api.tokens(params);
      commit("token_list", tokens);
    } catch (error) {
      commit("error/clear", error, { root: true });
    }
  },
  async token_history_list({ commit, state }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      const history_tokens = await api.history_tokens(params);
      commit("token_history_list", { history_tokens, params });
    } catch (error) {
      commit("error/clear", error, { root: true });
    }
  },
  async revoke_token({ commit, state }, token) {
    try {
      commit("error/clear", undefined, { root: true });
      const new_token = await api.revoke_token(token);
      commit("token_history_replace", new_token);
    } catch (error) {
      commit("error/clear", error, { root: true });
    }
  },
};

// mutations
const mutations = {
  device_list(state, history_tokens) {
    const deviceObjects = history_tokens.reduce((rv, x) => {
      const y = rv[x.proper_address] || { expires_at: "" };
      if (x.expires_at > y.expires_at) {
        rv[x.proper_address] = Object.assign({ cloned: true }, x);
      } else {
        rv[x.proper_address] = y;
      }
      return rv;
    }, {});
    state.devices = Object.getOwnPropertyNames(deviceObjects)
      .map((id) => {
        return deviceObjects[id];
      })
      .map((device) => {
        const info = platform.parse(device.user_agent);
        device.user_agent = info.name + " on " + info.os;
        return device;
      });
  },
  token_list(state, tokens) {
    state.tokens = tokens;
  },
  token_history_list(state, { history_tokens, params }) {
    if (params.revoked && params.expired) {
      state.history_tokens = history_tokens;
    } else if (params.revoked) {
      const now = Date.now();
      state.history_tokens = history_tokens.filter(
        (item) => now < new Date(item.expires_at)
      );
    } else if (params.expired) {
      state.history_tokens = history_tokens.filter((item) => !item.revoked);
    } else {
      const now = Date.now();
      state.history_tokens = history_tokens.filter(
        (item) => now < new Date(item.expires_at) && !item.revoked
      );
    }
    mutations.device_list(state, state.history_tokens);
  },
  token_history_replace(state, new_token) {
    let token = state.history_tokens.find((token) => token.id === new_token.id);
    if (token) {
      Object.assign(token, new_token);
    } else {
      state.history_tokens.push(new_token);
    }
    mutations.device_list(state, state.history_tokens);
  },
  token_replace(state, new_token) {
    let token = state.tokens.find((token) => token.id === new_token.id);
    if (token) {
      Object.assign(token, new_token);
    } else {
      state.tokens.push(new_token);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
