/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_control from "../../api/control_new_month";

const state = {};
// getters
const getters = {};

// actions
const actions = {
  async check_phone({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_control.check_phone();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async prepare_holders({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_control.prepare_holders();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async switch_holders({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_control.switch_holders();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
