/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  store(item) {
    return axios
      .post(`/api/helpdesk/lock_list/store`, item)
      .then((response) => {
        return response.data.data;
      });
  },
  get_lock_list() {
    return axios.get(`/api/helpdesk/lock_list/index`).then((response) => {
      return response.data.data;
    });
  },
  delete(id) {
    return axios
      .delete(`/api/helpdesk/lock_list/delete/${id}`)
      .then((response) => {
        return response.data;
      });
  },
};
