<template>
  <div class="container">
    <h1>NotificationsListView</h1>
    <ErrorBanner :message="state.error" />
    <form ref="refNotificationControls" action="#" class="notifications-controls" @submit.prevent="onSearchSubmit">
      <ul class="notifications-controls_inputs">
        <li>
          <label>
            <span>Name Surname:</span>
            <BaseInput v-model="state.search.name" />
          </label>
        </li>
        <li>
          <label>
            <span>Phone:</span>
            <BaseInput v-model="state.search.phone" />
          </label>
        </li>
        <li>
          <DatePickerInput v-model="state.search.date" label="Date:" />
        </li>
        <li>
          <label>
            <span>Resourse:</span>
            <NewSelectInput
              v-model="state.search.resource"
              placeholder="Not selected"
              :options="RESOURCES_OPTIONS"
              :error="state.errors.resource"
            />
          </label>
        </li>
        <li>
          <Button type="submit" :disabled="isSearchButtonDisabled" :processing="state.processing">
            {{ $t("Search") }}
          </Button>
        </li>
      </ul>
    </form>
    <SimpleTable
      class="table"
      :cells="TABLE_CELLS"
      :clickable-cells="state.TABLE_CLICKABLE_CELLS"
      :headers="TABLE_HEADERS"
      :items="state.data"
      :processing="state.processing"
      @click:clickable="showNotification"
    />
    <TablePagination
      v-if="showPagination"
      v-model="state.page"
      centered
      class="pagination"
      :pageChangeCallback="pageChangeCallback"
      :total-pages="state.totalPages"
    />
  </div>
</template>

<script>
import { computed, reactive, ref } from "@vue/composition-api";
import isNumber from "lodash/isNumber";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import TablePagination from "@/components/tables/pagination/TablePagination.vue";

import URLS from "@/config/urls";
import router, { ROUTES_NAMES } from "@/router";
import request from "@/helpers/request";
import clearPhone from "@/helpers/converters/clearPhone";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import dateToLocaleString from "@/helpers/date/dateToLocaleString";

const TABLE_HEADERS = ["Name Surname", "Phone", "Channel", "SentAt", "Status"];
const TABLE_CLICKABLE_CELLS = "channel";
const TABLE_CELLS = ["name", "phone", "channel", (item) => dateToLocaleString(item.sent_at), "status"];

const RESOURCES_OPTIONS = [
  {
    value: "User",
    text: "Supplier/Journal User",
  },
  {
    value: "Subscriber",
    text: "Subscriber",
  },
  {
    value: "HR",
    text: "PortalUser - HR",
  },
  {
    value: "Manager",
    text: "PortalUser - Manager",
  },
  {
    value: "Unregistered",
    text: "Unregistered",
  },
];

export default {
  components: {
    BaseInput,
    Button,
    DatePickerInput,
    ErrorBanner,
    NewSelectInput,
    SimpleTable,
    TablePagination,
  },

  setup() {
    const refNotificationControls = ref(null);

    const state = reactive({
      TABLE_CLICKABLE_CELLS,

      page: 1,
      totalPages: 1,

      processing: false,
      error: null,
      errors: {},
      data: [],

      scrollToTheTop: false,

      search: {
        date: null,
        name: "",
        phone: "",
        resource: null,
      },
    });

    const isSearchButtonDisabled = computed(() => !state.search.resource);
    const showPagination = computed(() => state.data.length > 0 && state.totalPages > 1);

    function showNotification(field, item) {
      if (field === "channel") {
        const url = window.location.origin + "/notifications/" + item.id;
        window.open(url, "_blank");
      }
    }

    function onSearchSubmit(page) {
      if (state.processing || !state.search.resource) {
        return;
      }

      if (!isNumber(page)) {
        state.page = 1;
        state.scrollToTheTop = false;
      }

      if (state.scrollToTheTop) {
        refNotificationControls.value.scrollIntoView({ behavior: "smooth" });
        state.scrollToTheTop = false;
      }

      return submitSearch();
    }

    async function submitSearch() {
      const { date, phone, ...searchQueryParams } = state.search;

      searchQueryParams.phone = clearPhone(phone);
      searchQueryParams.page = state.page;

      if (date) {
        searchQueryParams.created_at = date;
      }

      const url = URLS.notifications.search(searchQueryParams);

      state.processing = true;
      state.error = null;
      state.errors = {};

      try {
        const { data, meta } = await request(url, {
          originalResponse: true,
        });

        state.data = data;
        state.totalPages = meta.last_page;
      } catch (xhrError) {
        const { errors, errorMessage } = prepareAxiosErrors(xhrError);
        state.error = errorMessage;
        state.errors = errors;
      }

      state.processing = false;
    }

    function pageChangeCallback(newPage) {
      state.scrollToTheTop = true;
      return onSearchSubmit(newPage);
    }

    return {
      TABLE_HEADERS,
      TABLE_CELLS,
      TABLE_CLICKABLE_CELLS,
      RESOURCES_OPTIONS,

      refNotificationControls,

      state,

      isSearchButtonDisabled,
      showPagination,

      showNotification,
      onSearchSubmit,
      pageChangeCallback,
    };
  },
};
</script>

<style lang="scss" scoped>
.notifications-controls {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &_inputs {
    list-style: none;
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: 3fr 2fr 2fr 3fr 2fr;
    align-items: center;

    li {
      span {
        font-weight: 400;
        font-size: 18px;
        display: inline-block;
        padding: 0.2em 0;
      }
    }
  }
}

.table {
  margin-top: 24px;
}

.pagination {
  margin-top: 48px;
}
</style>
