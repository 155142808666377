/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_user from "../../api/user";
import api from "../../api/company";

// initial state
const state = {
  user_list: [],
};

// getters
const getters = {
  user_list(state) {
    return state.user_list;
  },
};

// actions
const actions = {
  async managers() {
    return await api_user.manager_list();
  },
  async user_list({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const user_list = await api_user.user_list();
      commit("user_list", user_list);
      return user_list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async add_user({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const user = await api_user.user_create(data);
      commit("user_add", user);
      return user;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_admin_user({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const user = await api_user.admin_user_update(data.id, data);
      commit("user_update", { oldUser: data, newUser: user });
      return user;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_user({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const user = await api_user.user_update(data.id, data);
      commit("user_update", { oldUser: data, newUser: user });
      return user;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete_user({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const user = await api_user.user_delete(data.id);
      commit("user_delete", data);
      return user;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async supplier_search({ commit }, text) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_user.supplier_search(text);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  user_list(state, list) {
    state.user_list = list.map((user) => {
      user.supplier_name = user.suppliers
        .map((supplier) => {
          return supplier.name;
        })
        .join(",");
      return user;
    });
  },
  user_add(state, user) {
    state.user_list.push(user);
  },
  user_update(state, { oldUser, newUser }) {
    state.user_list.splice(
      state.user_list.findIndex((user) => user.id == oldUser.id),
      1,
      newUser
    );
  },
  user_delete(state, user) {
    state.user_list.splice(state.user_list.indexOf(user), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
