<template>
  <div class="container view-order">
    <div class="row-header">
      <h1>Order Acts</h1>
      <Button v-if="isOrderActsLoaded" :processing="state.creating" @click="createAct">Create Act</Button>
    </div>
    <p>Order date: {{ orderDate }}</p>
    <ErrorBanner :message="state.errors.errorMessage" />
    <SpinnerBrand v-if="state.fetching" centered />
    <div v-if="isOrderActsLoaded" class="content">
      <SimpleTable
        v-model="state.selectedAct"
        selectable
        show-actions-button
        :actions="TABLE_ACTIONS"
        :headers="orderTableHeaders"
        :cells="orderTableCells"
        :items="actsList"
        :processing-items="state.deleting"
        @change="resetOrder"
        @download="downloadAct"
        @download-sole-act-aggregator="downloadSoleActAggregator"
      >
        <template #footer>
          <tr>
            <td>Summary</td>
            <td v-for="(item, index) in orderSummary" :key="`order-summary-${index}`">
              {{ item }}
            </td>
          </tr>
        </template>
      </SimpleTable>
    </div>

    <OrderActDetails
      v-if="showActDetails"
      class="act-details"
      v-bind="orderActDetails"
      @reset="resetOrder"
      @select="selectAct"
    />
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount } from "@vue/composition-api";
import get from "lodash/get";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import OrderActDetails from "./components/OrderActDetails.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import store from "@/store";
import { ORDERS_ACTION_CREATE_ACT, ORDERS_ACTION_GET_ORDER, ORDERS_ACTION_RESET_ORDER } from "@/store/modules/orders";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";
import URLS from "@/config/urls";
import formatCurrency from "@/helpers/formatters/formatCurrency";
import download from "@/helpers/request/download";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const TABLE_ACTIONS = [
  {
    title: "Скачать",
    key: "download",
    icon: {
      type: "download",
      color: "#0038FF",
    },
  },
  {
    title: "Download AS Act",
    key: "download-sole-act-aggregator",
    icon: {
      type: "download",
      color: "#0038FF",
    },
  },
];

export const ACT_TYPES = {
  act: "act",
  soleActAggregator: "sole_act_aggregator",
};

export default {
  components: {
    Button,
    ErrorBanner,
    OrderActDetails,
    SimpleTable,
    SpinnerBrand,
  },

  setup() {
    const state = reactive({
      errors: {},

      creating: false,
      deleting: null,
      fetching: false,

      selectedAct: null,
    });

    const showActDetails = computed(() => state.selectedAct !== null);
    const actsList = computed(() => store.state.orders.order?.acts || []);
    const isOrderActsLoaded = computed(() => !(state.fetching || state.errors.fetchingError));

    const orderDate = computed(() => {
      const filterAt = store.state.orders.order?.filter_at?.toString();

      if (!filterAt) return "";

      const year = filterAt.slice(0, 2);
      const month = filterAt.slice(2);
      return `${year}-${month}`;
    });

    const orderActDetails = computed(() => ({
      actId: state.selectedAct,
      pricingPolicies: pricingPolicies.value,
    }));

    const pricingPoliciesList = computed(() => {
      const pricingPoliciesList = actsList.value.reduce((list, act) => {
        const newPolicies = Object.keys(act.pricingPolicies);
        const fullPoliciesList = [...list, ...newPolicies];
        const uniqPoliciesList = [...new Set(fullPoliciesList)];
        return uniqPoliciesList;
      }, []);
      return pricingPoliciesList;
    });

    const pricingPolicies = computed(() => {
      const policies = {};
      const group = store.state.orders.pricingGroup || [];

      group.forEach((policy) => {
        policies[policy.key] = policy.value;
      });

      return policies;
    });

    const orderTableHeaders = computed(() => {
      const headers = ["ID"];

      pricingPoliciesList.value.forEach((policy) => {
        headers.push(pricingPolicies.value[policy] ?? policy);
        headers.push("Taxes");
      });

      return headers;
    });

    const orderTableCells = computed(() => {
      const cells = ["id"];

      pricingPoliciesList.value.forEach((policy) => {
        cells.push((item) => formatCurrency(get(item, `pricingPolicies.${policy}.price`)));
        cells.push((item) => formatCurrency(get(item, `pricingPolicies.${policy}.tax`)));
      });

      return cells;
    });

    const orderSummary = computed(() => {
      const initialSummaryList = pricingPoliciesList.value.map((policy) => [policy, { price: 0, tax: 0 }]);
      const summaryObject = Object.fromEntries(initialSummaryList);

      actsList.value.forEach(({ pricingPolicies }) => {
        pricingPoliciesList.value.forEach((policy) => {
          if (pricingPolicies[policy]) {
            summaryObject[policy].price += Number(pricingPolicies[policy].price);
            summaryObject[policy].tax += Number(pricingPolicies[policy].tax);
          }
        });
      });

      const summary = pricingPoliciesList.value.flatMap((policy) => {
        const { price, tax } = summaryObject[policy];
        return [formatCurrency(price), formatCurrency(tax)];
      });

      return summary;
    });

    async function fetchOrder() {
      state.fetching = true;
      state.errors = {};

      try {
        await store.dispatch(ORDERS_ACTION_GET_ORDER, "act");
        await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, ["portal"]);
      } catch (errors) {
        state.errors = errors;
        state.errors.fetchingError = true;
      }

      state.fetching = false;
    }

    async function createAct() {
      state.creating = true;
      state.errors = {};

      try {
        await store.dispatch(ORDERS_ACTION_CREATE_ACT);
        await store.dispatch(ORDERS_ACTION_GET_ORDER, "act");
      } catch (errors) {
        state.errors = errors;
      }

      state.creating = false;
    }

    async function downloadAct(actId, act_type = ACT_TYPES.act) {
      state.errors = {};

      const url = URLS.orders.acts.download(actId);

      try {
        await download({
          url,
          params: { act_type },
        });
      } catch (xhrError) {
        state.errors = prepareAxiosErrors(xhrError);
      }
    }

    function downloadSoleActAggregator(actId) {
      downloadAct(actId, ACT_TYPES.soleActAggregator);
    }

    function selectAct(actId) {
      state.selectedAct = actId;
    }

    function resetOrder() {
      store.dispatch(ORDERS_ACTION_RESET_ORDER);
    }

    onBeforeMount(() => {
      fetchOrder();
    });

    return {
      TABLE_ACTIONS,

      state,

      actsList,
      isOrderActsLoaded,
      orderActDetails,
      orderDate,
      orderSummary,
      orderTableCells,
      orderTableHeaders,
      showActDetails,

      createAct,
      downloadAct,
      downloadSoleActAggregator,
      resetOrder,
      selectAct,
    };
  },
};
</script>

<style lang="scss">
.view-order {
  .content {
    display: grid;
    grid-template-columns: 11fr 1fr;
    grid-gap: 24px;

    &.details {
      grid-template-columns: 9fr 2fr;
    }

    .controls {
      button {
        & + button {
          margin-top: 20px;
        }
      }
    }
  }

  .act-details {
    margin-top: 64px;
  }
}
</style>
