import { nextTick } from "@vue/composition-api";

const DEFAULT_ERRORS_TYPES_TO_SCROLL = ["banner", "message"];

export default function (errorTypes = DEFAULT_ERRORS_TYPES_TO_SCROLL) {
  nextTick(() => {
    const errorElementsAll = [
      ...document.querySelectorAll("[data-error-type]"),
    ];

    const errorElementsToScroll = errorElementsAll.filter((el) =>
      errorTypes.includes(el.dataset.errorType)
    );

    if (errorElementsToScroll.length > 0) {
      errorElementsToScroll[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  });
}
