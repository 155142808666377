<template>
  <div class="container">
    <div class="row-header">
      <h1>{{ $t("Visit corrections") }}</h1>
    </div>
    <div class="control">
      <DatePickerInput
        v-model="searchState.date"
        :label="$t('Date')"
        class="datepicker"
        placeholder="yyyy-mm"
        type="month"
        :clearable="false"
        :disabled-date="disabledAfterToday"
      />
      <div class="control-filter">
        <a
          v-for="status in visitCorrectionsStatuses"
          :key="status"
          v-show="showFilter(status)"
          href="#"
          :class="{
            'selected-filter': status === searchState.filter,
          }"
          @click.prevent="onChangeFilter(status)"
        >
          {{ $t(status) }}
        </a>
      </div>
    </div>
    <div class="content">
      <SpinnerBrand v-if="searchState.isLoadingData" centered />
      <div v-else>
        <p v-if="visitCorrectionResponse.length === 0">{{ $t("No visit corrections") }}</p>
        <table v-else>
          <thead>
            <tr class="headers">
              <th>{{ $t("Date") }}</th>
              <th>{{ $t("Supplier") }}</th>
              <th>№</th>
              <th colspan="2">{{ $t("Visit info") }}</th>
              <th colspan="2">{{ $t("Change request") }}</th>
              <th>{{ $t("Decision") }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in filteredVisitCorrectionsArray">
              <tr :key="`${item.id}_status`">
                <td rowspan="4" v-html="getDate(item.visit.created_at)"></td>
                <td rowspan="4">{{ item.supplier.name || "??" }}</td>
                <td rowspan="4">
                  <router-link
                    v-if="item.is_multiple_visits_this_day"
                    :to="{
                      name: 'helpdesk_mobile_holder_list',
                      query: { card_id: item.visit.subscriber_id, date: getVisitCorrectionDate(item.visit.created_at) },
                    }"
                    target="_blank"
                  >
                    <Tooltip :text="$t('Multiple attempts of visits')" color="#e3001b" />
                  </router-link>
                  <router-link :to="`/helpdesk/mobile/view/${item.visit.id}`" class="link" target="_blank">
                    {{ item.visit.id || "??" }}
                  </router-link>
                </td>
                <th>{{ $t("Status") }}:</th>
                <td>{{ item.current.status || "??" }}</td>
                <th>{{ $t("Status") }}:</th>
                <td>{{ item.new.status || "??" }}</td>
              </tr>
              <tr :key="`${item.id}_activity`">
                <th>{{ $t("Activity") }}:</th>
                <td>{{ item.current.attraction.name || "??" }}</td>
                <th>{{ $t("Activity") }}:</th>
                <td>{{ item.new.attraction ? item.new.attraction.name : "??" }}</td>
                <td class="action">
                  <a v-if="item.status === 'waiting'" href="#" @click.prevent="onAcceptCorrection(item)">
                    <Icon icon="accept"></Icon>
                    <span>{{ $t("Accept") }}</span>
                  </a>
                  <span v-else>{{ $t(item.status) }}</span>
                </td>
              </tr>
              <tr :key="`${item.id}_reason`">
                <th>{{ $t("Reason") }}:</th>
                <td class="break-word">{{ item.current.reason_text }}</td>
                <th>{{ $t("Reason") }}:</th>
                <td class="break-word">{{ item.new.reason_text }}</td>
                <td class="action">
                  <a v-if="item.status === 'waiting'" href="#" @click.prevent="onDeclineCorrection(item.id)">
                    <Icon icon="decline"></Icon>
                    <span>{{ $t("Decline") }}</span>
                  </a>
                </td>
              </tr>
              <tr :key="`${item.id}_price`" class="border-bottom">
                <th>{{ $t("Price") }}:</th>
                <td>{{ formatCurrency(item.current.attraction.price) }}</td>
                <th>{{ $t("Price") }}:</th>
                <td>{{ formatCurrency(item.new.attraction.price) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { useRoute } from "@/helpers/router/routeCompositionAPI";

import dateToLocaleString from "@/helpers/date/dateToLocaleString";
import formatCurrency from "@/helpers/formatters/formatCurrency";

import router, { ROUTES_NAMES } from "@/router";
import api_visit from "@/api/visit";

import Icon from "@/components/icons/Icon.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import Tooltip from "@/components/tooltips/Tooltip.vue";
import store from "@/store";
import { MODAL_ACTION_OPEN, MODAL_TYPES } from "@/store/modules/modal";

export default {
  components: { Tooltip, SpinnerBrand, DatePickerInput, Icon },
  setup(props, { root }) {
    const route = useRoute(root);
    const visitCorrectionResponse = ref([]);
    const visitCorrectionsStatuses = ["all", "waiting", "accepted", "declined", "ignored"];

    const searchState = reactive({
      date: route.value.query.period || null,
      isLoadingData: false,
      filter: visitCorrectionsStatuses[0],
    });

    const filteredVisitCorrectionsArray = computed(() => {
      return visitCorrectionResponse.value.filter((e) => {
        if (searchState.filter === visitCorrectionsStatuses[0]) {
          return true;
        } else {
          return e.status === searchState.filter;
        }
      });
    });
    const currentMonth = computed(() => {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const preparedMonth = month.toString().padStart(2, "0");
      return `${year}-${preparedMonth}`;
    });

    function showFilter(status) {
      if (currentMonth.value === searchState.date && visitCorrectionsStatuses[4] === status) return false;
      if (currentMonth.value !== searchState.date && visitCorrectionsStatuses[1] === status) return false;
      return true;
    }

    function getDate(date) {
      return dateToLocaleString(date).replace(",", "<br>");
    }
    function onChangeFilter(value) {
      searchState.filter = value;
    }
    function disabledAfterToday(date) {
      const today = new Date();
      return date > today;
    }

    function getVisitCorrectionDate(date) {
      const localDate = new Date(date);
      const day = localDate.getDate();
      const preparedDay = day.toString().padStart(2, "0");
      const month = localDate.getMonth() + 1;
      const preparedMonth = month.toString().padStart(2, "0");
      const year = localDate.getFullYear();
      return `${year}-${preparedMonth}-${preparedDay}`;
    }

    async function onDeclineCorrection(correctionId) {
      await api_visit.decline_visits_correction(correctionId).then((response) => {
        const index = visitCorrectionResponse.value.findIndex((e) => e.id === correctionId);
        visitCorrectionResponse.value[index].status = response.data.status;
      });
    }

    function openMessageModal(correction) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.message,
        payload: {
          message: "Please note that this holder has more than one visit attempt this day!",
          headerText: "ATTENTION !",
          buttonText: "Accept",
          buttonDeclineText: "Decline",
          additionalLinkData: {
            text: "View details",
            routeName: "helpdesk_mobile_holder_list",
            query: {
              card_id: correction.visit.subscriber_id,
              date: getVisitCorrectionDate(correction.visit.created_at),
            },
          },
          additionalLinkText: "View details",
          additionalLinkToName: "helpdesk_mobile_holder_list",

          onAccept: acceptCorrection.bind(null, correction.id),
          onDecline: onDeclineCorrection.bind(null, correction.id),
        },
      });
    }

    async function acceptCorrection(correctionId) {
      await api_visit.accept_visits_correction(correctionId).then((response) => {
        const index = visitCorrectionResponse.value.findIndex((e) => e.id === correctionId);
        visitCorrectionResponse.value[index].status = response.data.status;
      });
    }

    function onAcceptCorrection(correction) {
      const correctionId = correction.id;
      const multipleAttempts = correction.is_multiple_visits_this_day;

      if (multipleAttempts) {
        openMessageModal(correction);
      } else if (!multipleAttempts) {
        acceptCorrection(correctionId);
      }
    }
    async function loadVisitsCorrections(period) {
      searchState.isLoadingData = true;
      await api_visit
        .load_visits_corrections(period)
        .then((response) => (visitCorrectionResponse.value = response.data))
        .catch((e) => e)
        .finally(() => (searchState.isLoadingData = false));
    }

    watch(
      () => searchState.date,
      () => {
        router.push({ query: { ...route.value.query, period: searchState.date } });
        loadVisitsCorrections(searchState.date);
        searchState.filter = visitCorrectionsStatuses[0];
      }
    );

    onMounted(async () => {
      await loadVisitsCorrections(route.value.query.period);
    });

    return {
      visitCorrectionResponse,
      searchState,
      visitCorrectionsStatuses,
      filteredVisitCorrectionsArray,
      currentMonth,
      ROUTES_NAMES,

      getDate,
      onAcceptCorrection,
      onDeclineCorrection,
      onChangeFilter,
      showFilter,
      disabledAfterToday,
      getVisitCorrectionDate,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss" scoped>
.row-header {
  margin-bottom: 24px;
}
.content {
  .controls {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 22px;

    .search {
      width: 278px;
    }
  }
}
.control {
  display: flex;
  align-items: center;
  gap: 24px;
  .datepicker {
    max-width: 200px;
  }

  &-filter {
    display: flex;
    gap: 24px;
    text-transform: capitalize;
    color: blue;

    a {
      opacity: 0.5;
    }

    .selected-filter,
    & a:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}

.break-word {
  word-break: break-word;
}

table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        padding-bottom: 16px;
        text-align: left;
        font-weight: 600;
      }

      :nth-child(1),
      :nth-child(2),
      :nth-child(3) {
        width: 120px;
      }
    }
  }

  tbody {
    > tr.border-bottom {
      border-bottom: 1px solid #9c9c9c;
    }
    tr {
      padding: 4px;

      th {
        font-weight: 600;
      }

      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 4px;
      }

      td {
        .link {
          color: blue;
          &:hover {
            text-decoration: underline;
          }
        }

        &.action {
          a {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }
}
</style>
