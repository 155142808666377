<template>
  <small-layout>
    <div>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">Add Ticket</h2>

      <div>
        <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8" v-if="supplier">
          <create-ticket-form :supplier="supplier" :attraction="attraction" />

          <import-ticket-form :supplier="supplier" :attraction="attraction" />
        </div>
      </div>
    </div>
  </small-layout>
</template>

<script>
import CreateTicketForm from "./CreateTicketForm";
import ImportTicketForm from "./ImportTicketForm";
import { mapActions } from "vuex";

export default {
  components: {
    CreateTicketForm,
    ImportTicketForm,
  },

  data() {
    return {
      supplier: null,
      attraction: null,
    };
  },

  methods: {
    ...mapActions({
      getAttraction: "ticket/get_attraction",
    }),
  },

  async mounted() {
    this.attraction = await this.getAttraction(this.$route.query.attraction_id);
    this.supplier = this.attraction.supplier;
  },
};
</script>
