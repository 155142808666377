<template>
  <nav v-if="currentPath" :class="classlist">
    <div v-for="path in activePaths" :key="path.routeName">
      <router-link class="breadcrumbs_item" :to="{ name: path.routeName }">
        {{ path.title }}
      </router-link>
      <span class="breadcrumbs_separator">-</span>
    </div>
    <router-link class="breadcrumbs_item breadcrumbs_item__current" :to="{ name: currentPath.routeName }">
      {{ currentPath.title }}
    </router-link>
  </nav>
</template>

<script>
import { computed } from "@vue/composition-api";

import router from "@/router";

export default {
  setup() {
    const pathsList = computed(() => {
      const routesWithBreadcrumbs = router.currentRoute.matched.filter((item) => item.meta.breadcrumb);
      return routesWithBreadcrumbs.map((item) => {
        return {
          routeName: item.name,
          title: item.meta.breadcrumb,
        };
      });
    });

    const activePaths = computed(() => {
      return pathsList.value.slice(0, -1);
    });

    const currentPath = computed(() => {
      return pathsList.value.at(-1);
    });

    const classlist = computed(() => {
      return activePaths.value.length > 0 ? "breadcrumbs" : "breadcrumbs breadcrumbs__invisible";
    });

    return {
      activePaths,
      currentPath,
      classlist,
      pathsList,
    };
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  line-height: 1.2em;

  &_separator {
    margin: 0 0.2em;
  }

  &_item {
    font-weight: 400;
    font-size: 13px;
    color: #444545;

    &:hover {
      text-decoration: underline;
    }

    &__current {
      pointer-events: none;
      cursor: default;
      color: #9c9c9c;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__invisible {
    visibility: hidden;
  }
}
</style>
