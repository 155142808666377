<template>
  <li v-if="props.items.length > 0">
    <select @change="onSelectItem">
      <option v-for="item in props.items" :value="item" :key="`pagination-${props.parentId}-select-${_uid}--${item}`">
        {{ item }}
      </option>
    </select>
    <button>...</button>
  </li>
</template>

<script>
export default {
  emits: ["select"],

  props: {
    parentId: {
      type: [Number, String],
      default: Date.now(),
    },
    items: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    function onSelectItem(e) {
      emit("select", Number(e.target.value));
    }

    return {
      props,
      onSelectItem,
    };
  },
};
</script>
