<template>
  <div class="container">
    <div class="row-header">
      <h1>Countries</h1>
      <Button v-if="isDataLoaded" @click="openRouteByName(ROUTES_NAMES.countries.add)">
        {{ $t("Add") }}
      </Button>
    </div>
    <BreadCrumbs />
    <ErrorBanner :message="errorMessage" />
    <SpinnerBrand v-if="processing" centered />
    <div v-if="isDataLoaded" class="content">
      <SimpleTable
        primary-key="code"
        :cells="TABLE_CELLS"
        :headers="TABLE_HEADERS"
        :items="countriesList"
        show-edit-button
        @edit="openRouteByName(ROUTES_NAMES.countries.edit, { params: { countryId: $event } })"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import { ROUTES_NAMES } from "@/router";
import URLS from "@/config/urls";
import openRouteByName from "@/helpers/router/openRouteByName";
import useRequest from "@/composables/network/useRequest";

const TABLE_HEADERS = [
  "Country code",
  "Brand name",
  "Company name",
  "Locales",
  "Currency",
  "Locations",
  "Types of subscriptions",
];

const TABLE_CELLS = [
  "code",
  "brand_name",
  "company_name",
  "locale",
  "currencies",
  "locations_name",
  "types_of_subscription",
];

export default {
  components: {
    BreadCrumbs,
    Button,
    ErrorBanner,
    SimpleTable,
    SpinnerBrand,
  },

  setup() {
    const {
      data: countriesList,
      processing,
      errorMessage,
    } = useRequest({
      url: URLS.countries.index,
      hook: "onBeforeMount",
      defaultValue: [],
    });

    const isDataLoaded = computed(() => !processing.value && !errorMessage.value);

    return {
      TABLE_CELLS,
      TABLE_HEADERS,
      ROUTES_NAMES,

      countriesList,
      errorMessage,
      processing,

      isDataLoaded,

      openRouteByName,
    };
  },
};
</script>
