<template>
  <div class="container">
    <h1>
      <template v-if="state.processing">Import coupons</template>
      <template v-else>Import coupons for "{{ currentCouponsGroup.name }}"</template>
    </h1>
    <BreadCrumbs />
    <SpinnerAllsports v-if="state.processing" :wrapped="true" />
    <template v-else>
      <div class="forms-container">
        <form action="#" class="block" @submit.prevent="importCoupons">
          <h3>Import coupons</h3>
          <p>Import сoupons for chosen supplier with CSV file.</p>
          <div class="card">
            <div class="input_container">
              <label for="coupon_file">
                File to Import
                <br />
                <span>(first column should be `value`)</span>
              </label>
              <FileInputSimple
                v-model="state.import.file"
                ref="refFileInput"
                id="coupon_file"
                :fileTypes="['csv', 'txt']"
              />
            </div>
            <ErrorBanner :message="state.import.error" class="error_banner" />
            <div class="text">
              <router-link :to="{ name: ROUTES_NAMES.coupons.import.history }">
                {{ $t("View import history") }}
              </router-link>
            </div>
            <Button class="btn" type="submit" :processing="state.import.processing" :disabled="isImportButtonDisabled">
              Import coupon
            </Button>
          </div>
        </form>
        <form action="#" class="block" @submit.prevent="addCoupon">
          <h3>Add single coupon</h3>
          <p>Create a new сoupon if import is broken and we need to add new сoupons fast.</p>
          <div class="card">
            <BaseInput
              label="Coupon"
              v-model="state.coupon.value"
              autocomplete="off"
              class="input"
              :error="isCouponError ? state.coupon.error : null"
            />
            <Button class="btn" :processing="state.coupon.processing" type="submit" :disabled="isAddButtonDisabled">
              Add coupon
            </Button>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, reactive, ref, onBeforeMount, watch } from "@vue/composition-api";

import BaseInput from "@/components/inputs/BaseInput.vue";
import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import FileInputSimple from "@/components/inputs/FileInputSimple.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";

import router, { ROUTES_NAMES } from "@/router";
import store from "@/store";
import { COUPONS_ACTION_GET_COUPONS_GROUPS } from "@/store/modules/coupons";
import request, { upload } from "@/helpers/request";
import { VALIDATION_ERRORS } from "@/helpers/validators";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import URLS from "@/config/urls";

export default {
  components: {
    BaseInput,
    BreadCrumbs,
    Button,
    ErrorBanner,
    FileInputSimple,
    SpinnerAllsports,
  },

  setup() {
    const refFileInput = ref(null);

    const state = reactive({
      error: null,
      processing: false,

      import: {
        file: null,
        error: null,
        processing: false,
      },

      coupon: {
        value: "",
        error: null,
        processing: false,
      },
    });

    const isImportButtonDisabled = computed(() => refFileInput?.value?.isError || !state.import.file);
    const isAddButtonDisabled = computed(() => Boolean(state.coupon.value.length === 0));
    const isCouponError = computed(() => Boolean(state.coupon.error));
    const currentCouponsGroup = computed(() => {
      const { couponsGroupId } = router.currentRoute.params;
      const group = store.state.coupons.couponsGroupsList.find((group) => group.id == couponsGroupId);

      return group;
    });

    async function addCoupon() {
      if (state.coupon.processing) {
        return;
      }

      if (state.coupon.value.length === 0) {
        state.coupon.error = VALIDATION_ERRORS.requiredField;
        return;
      }

      state.coupon.processing = true;
      state.coupon.error = null;

      try {
        const couponsGroupId = router.currentRoute.params.couponsGroupId;
        const url = URLS.coupons.createOne(couponsGroupId);
        const data = {
          coupon_group_id: couponsGroupId,
          value: state.coupon.value,
        };

        await request({
          method: "POST",
          data,
          url,
        });

        state.coupon.value = "";
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.coupon.error = errorMessage;
      }

      state.coupon.processing = false;
    }

    async function importCoupons() {
      if (state.import.processing || refFileInput.value.isError) {
        return;
      }

      if (!state.import.file) {
        state.import.error = "Choose file to import";
        return;
      }

      state.import.processing = true;
      state.import.error = null;

      try {
        const data = new FormData();
        const couponsGroupId = router.currentRoute.params.couponsGroupId;
        const url = URLS.coupons.import(couponsGroupId);

        data.append("csv", state.import.file);

        await upload({
          method: "POST",
          data,
          url,
        });

        router.push({ name: ROUTES_NAMES.coupons.list });
      } catch (error) {
        const { errors, errorMessage } = prepareAxiosErrors(error);
        state.import.error = errors.csv || errorMessage;
      }

      state.import.processing = false;
    }

    onBeforeMount(async () => {
      state.processing = true;

      try {
        await store.dispatch(COUPONS_ACTION_GET_COUPONS_GROUPS);
      } catch (error) {
        state.error = error;
      }

      state.processing = false;
    });

    watch(
      () => state.import.file,
      () => {
        state.import.error = null;
      }
    );

    return {
      ROUTES_NAMES,

      refFileInput,

      state,

      currentCouponsGroup,
      isAddButtonDisabled,
      isCouponError,
      isImportButtonDisabled,

      addCoupon,
      importCoupons,
    };
  },
};
</script>

<style lang="scss" scoped>
.forms-container {
  display: flex;
  justify-content: space-between;

  .block {
    margin-top: 60px;

    h3 {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 20px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
    }

    a {
      font-weight: 400;
      font-size: 15px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .error_banner {
      margin-top: 16px;
    }

    .card {
      margin-top: 30px;

      h4 {
        font-weight: 500;
        font-size: 16px;
      }

      .input_container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .input {
        display: block;
        width: 178px;
      }

      label {
        font-weight: 500;
        font-size: 18px;
        color: #444545;

        & > span {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          line-height: 1em;
          color: #9c9c9c;
          display: block;
          margin-top: 12px;
        }
      }

      .btn {
        margin-top: 20px;
      }
    }
  }
}
</style>
