/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  load_act_list_by_range(started_at, finished_at) {
    return axios
      .get(
        `/api/suppliers/payment_list?started_at=${started_at}&finished_at=${finished_at}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  csv_act_list_by_range(started_at, finished_at, status) {
    return axios
      .get(
        `/api/suppliers/payment_list?file=csv&started_at=${started_at}&finished_at=${finished_at}&status=${status}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  sup_act_list_by_month_to1c(month) {
    return axios
      .get(`/api/suppliers/1c/act_list?month=${month}`, {
        responseType: "blob", //Force to receive data in a Blob Format
      })
      .then((response) => {
        return new Blob([response.data], { type: "application/vnd.ms-excel" });
      });
  },
  load_act_summary_by_range(started_at, finished_at, city) {
    let url = `/api/suppliers/payment_summary?started_at=${started_at}&finished_at=${finished_at}`;

    if (city) {
      url += `&city=${city}`;
    }

    return axios.get(url).then((response) => {
      return response.data.data;
    });
  },
  load_attractions_compare(started_at, finished_at) {
    return axios
      .get(
        `/api/suppliers/payment_compare_attractions?started_at=${started_at}&finished_at=${finished_at}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  bulk_act_add(acts) {
    return axios
      .post(`/api/supplieracts`, {
        acts,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  act_delete(supplier_id, id) {
    return axios
      .delete(`/api/suppliers/${supplier_id}/acts/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  act_validate(act) {
    return axios.patch(`/api/supplieracts/validate`, act).then((response) => {
      return response.data.data;
    });
  },
  act_upgrade(id) {
    return axios
      .patch(`/api/supplieracts/upgrade`, {
        patch: "upgrade",
        id,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  bulk_act_update(patch) {
    return axios.patch(`/api/supplieracts`, patch).then((response) => {
      return response.data.data;
    });
  },
  download(supplier_id, id) {
    return axios
      .get(`/api/suppliers/${supplier_id}/acts/${id}/download`, {
        responseType: "blob", //Force to receive data in a Blob Format
      })
      .then((response) => {
        return new Blob([response.data], { type: "application/pdf" });
      });
  },
  download_bank_order(started_at, finished_at) {
    return axios.get(
      `/api/suppliers/order_bank_list?started_at=${started_at}&finished_at=${finished_at}`,
      {
        responseType: "blob", //Force to receive data in a Blob Format
      }
    );
  },
  download_bank_order_xml(started_at, finished_at) {
    return axios.get(
      `/api/suppliers/order_bank_list_xml?started_at=${started_at}&finished_at=${finished_at}`,
      {
        responseType: "blob", //Force to receive data in a Blob Format
      }
    );
  },
  export_to_1c(ids) {
    return axios.get(`/api/suppliers/acts/to_1c?ids=${ids.join(",")}`, {
      responseType: "blob", //Force to receive data in a Blob Format
    });
  },
  download_acts(name, ids) {
    return axios
      .get(`/api/suppliers/downloader/?ids=${ids}&name=${name}`, {
        responseType: "blob", //Force to receive data in a Blob Format
      })
      .then((response) => {
        return new Blob([response.data], { type: "application/pdf" });
      });
  },
  by_order_generate(supplier_id, id) {
    return axios
      .post(`/api/suppliers/${supplier_id}/acts/${id}/paying`)
      .then((response) => {
        return response.data.data;
      });
  },
  ru_order_generate(supplier_id, act_id) {
    return axios
      .post(`/api/payments/tochka/store`, {
        supplier_id,
        act_id,
      })
      .then((response) => {
        return response.data.data;
      });
  },
  generate(supplier_id, id, no_download) {
    return axios
      .get(
        `/api/suppliers/${supplier_id}/acts/${id}/generate?no_download=${no_download}`,
        {
          responseType: "blob", //Force to receive data in a Blob Format
        }
      )
      .then((response) => {
        return new Blob([response.data], { type: "application/pdf" });
      });
  },
  act_list_by_supplier(supplier_id) {
    return axios.get(`/api/suppliers/${supplier_id}/acts`).then((response) => {
      return response.data.data;
    });
  },
};
