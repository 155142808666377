<template>
  <div class="container">
    <div class="row-header">
      <h1 v-if="isAccountant">Orders Accountant</h1>
      <h1 v-else>Orders</h1>
    </div>
    <ErrorBanner :message="errors.errorMessage" />
    <SpinnerBrand v-if="processingMethods.GET" centered />
    <div v-else-if="state.isDataLoaded" class="content">
      <div class="search">
        <DatePickerInput
          v-model="searchState.date"
          label="Date:"
          class="datepicker"
          placeholder="yyyy-mm"
          type="month"
          :clearable="false"
        />
        <NewSelectInput
          v-if="!isAccountant"
          v-model="searchState.manager"
          class="select_manager"
          :label="$t('Manager')"
          :options="managersOptions"
          @select="onManagerChange"
        />
        <BaseInput
          v-model="searchState.searchValue"
          class="search_input"
          label="Search"
          placeholder="Search"
          action-icon="search"
        />
      </div>
      <div v-if="isAccountant" class="buttons">
        <Button button-type="light" class="buttons_button" :disabled="isAnyItemSelected" @click="generateActs">
          Generate acts
        </Button>
        <Button button-type="light" class="buttons_button" :disabled="isAnyItemSelected" @click="reviewActs">
          Review
        </Button>
        <Button button-type="light" class="buttons_button" :disabled="isAnyItemSelected" @click="downloadSelectedActs">
          Download AS acts
        </Button>
        <Button button-type="light" class="buttons_button" :disabled="isAnyItemSelected" @click="sendEmails">
          Send email
        </Button>
      </div>

      <SimpleTable
        :actions="tableData.actions"
        :cells="tableData.cells"
        :clickable-cells="tableData.clickableCells"
        :headers="tableData.headers"
        :items="sortedOrdersList"
        :primary-key="tablePrimaryKey"
        :processing-items="state.processingItems"
        :show-checkbox="isAccountant"
        show-actions-button
        :sortable-headers="sortableTableHeaders"
        :summarized-columns="tableSummarizedColumnsByUserRole"
        @checkbox-change="onCheckboxChange"
        @download-act="downloadAct"
        @download-sole-act="downloadSoleAct"
        @open-order-acts-view="openOrderActsView"
        @open-order-invoices-view="openOrderInvoicesView"
        @open-cancel-order-modal="openCancelOrderModal"
        @click:clickable="onClickableCellClick"
        @sort-header="onSortHeader"
        :sortable-state="state.sortingState"
      />
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch, onBeforeMount, onMounted } from "@vue/composition-api";
import get from "lodash/get";

import Button from "@/components/buttons/Button.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";

import { useRoute } from "@/helpers/router/routeCompositionAPI";
import { camelCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";

import { ACT_TYPES } from "@/views/orders/OrderActsView.vue";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";
import { MODAL_TYPES, MODAL_ACTION_OPEN } from "@/store/modules/modal";
import router, { ROUTES_NAMES } from "@/router";
import store from "@/store";
import URLS from "@/config/urls";
import download from "@/helpers/request/download";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import useRequest from "@/composables/network/useRequest";
import formatCurrency from "@/helpers/formatters/formatCurrency";

const USER_ROLES = {
  accountant: "accountant",
  manager: "manager",
};

const SORTABLE_TABLE_ITEM_CELLS = {
  [USER_ROLES.manager]: {
    Company: "companyName",
    Date: "orderDate",
    ID: "id",
    Status: "orderStatus",
  },
  [USER_ROLES.accountant]: {
    Date: "orderDate",
    Company: "companyName",
    Status: "status",
    "Email sent": "mailStatus",
  },
};

const SORTABLE_TABLE_HEADERS = ["Company", "Date", "ID", "Status", "Email sent"];
const SUMMARIZED_COLUMNS_INITIAL_VALUES = {
  ["Amount"]: 0,
  ["Invoiced amount"]: 0,
  ["Sum + Corrections"]: 0,
};

const CLICKABLE_CELL_COMPANY_NAME = "companyName";

const TABLE_DATA = {
  [USER_ROLES.accountant]: {
    headers: [
      "Date",
      "Aggregator",
      "Company",
      "Dept",
      "Paid",
      "Summary",
      "Manager",
      "Invoice done after Act",
      "Status",
      "Email sent",
    ],
    cells: [
      "orderDate",
      "aggregatorName",
      CLICKABLE_CELL_COMPANY_NAME,
      getOrderDept,
      "orderPaid",
      getOrderSum,
      "manager.name",
      (item) => (item.invoiceDoneAfterAct ? "Yes" : "No"),
      getAccountantOrderStatus,
      getOrderEmailStatusIcon,
    ],
    clickableCells: [CLICKABLE_CELL_COMPANY_NAME],
    actions: [
      {
        title: "Invoice",
        key: "open-order-invoices-view",
        type: "link",
        attrs: (item) => ({
          to: {
            name: ROUTES_NAMES.orders.invoices.list,
            params: { orderId: item.id },
          },
        }),
      },
      {
        title: "Act",
        key: "open-order-acts-view",
        type: "link",
        attrs: (item) => ({
          to: {
            name: ROUTES_NAMES.orders.acts.list,
            params: { orderId: item.id },
          },
        }),
      },
      {
        title: "Client act version",
        key: "download-act",
        icon: {
          type: "download",
          color: "#346AED",
        },
      },
      {
        title: "AS act version",
        key: "download-sole-act",
        icon: {
          type: "download",
          color: "#346AED",
        },
      },
    ],
  },
  [USER_ROLES.manager]: {
    headers: [
      "Date",
      "ID",
      "Company",
      "Amount",
      "Invoiced amount",
      "Sum + Corrections",
      "Manager",
      "Status",
      "Start at",
    ],
    cells: [
      "orderDate",
      "orderId",
      CLICKABLE_CELL_COMPANY_NAME,
      "orderAmount",
      "invoicedAmount",
      getOrderSum,
      "manager.name",
      getOrderStatusIcon,
      getStartAt,
    ],
    clickableCells: [CLICKABLE_CELL_COMPANY_NAME],
    actions: [
      {
        title: "Invoice",
        key: "open-order-invoices-view",
        type: "link",
        attrs: (item) => ({
          to: {
            name: ROUTES_NAMES.orders.invoices.list,
            params: { orderId: item.id },
          },
        }),
      },
      {
        title: "Act",
        key: "open-order-acts-view",
        type: "link",
        attrs: (item) => ({
          to: {
            name: ROUTES_NAMES.orders.acts.list,
            params: { orderId: item.id },
          },
        }),
      },
      {
        title: "Edit company",
        key: "open-edit-company-view",
        type: "link",
        checkIsAvailable: (item) => !!item.companyId,
        attrs: (item) => ({
          to: {
            name: ROUTES_NAMES.companies.edit,
            params: { companyId: item.companyId },
          },
        }),
      },
    ],
  },
};

const ORDER_STATUSES = [
  { color: "red", text: "No subscriptions" },
  { color: "red", text: "Under limit" },
  { color: "yellow", text: "Subscriptions quantity changed" },
  { color: "yellow", text: "Invoice generated" },
  { color: "yellow", text: "Invoice downloaded" },
  { color: "black", text: "paid" },
  { color: "green", text: "Facture generated" },
  { color: "green", text: "Facture emailed" },
  { color: "green", text: "Facture downloaded" },
  { color: "red", text: "Canceled" },
];

const ORDER_ACCOUNTANT_STATUS = ["Reviewing", "Reviewed", "Act generated"];

const ORDER_EMAIL_STATUS = [
  { color: "red", text: "No" },
  { color: "green", text: "Yes" },
];

function getCurrentDate() {
  const dateObj = new Date();
  const day = `${dateObj.getMonth() + 1}`.padStart(2, "0");
  const year = dateObj.getFullYear();
  const currentDate = `${year}-${day}`;
  return currentDate;
}

function isDateValid(dateString) {
  if (dateString) {
    const [year, month] = dateString.split("-");
    const isYearValid = year && year.length === 4;
    const isMonthValid = month && month.length === 2;

    return isYearValid && isMonthValid;
  }

  return false;
}

function getOrderDept({ orderDept }) {
  if (!orderDept) {
    return "–";
  }

  const formattedDept = formatCurrency(orderDept);

  return formattedDept;
}

function getOrderSum({ orderSum }) {
  if (!orderSum) {
    return "–";
  }

  const { price = 0, corrections = 0 } = orderSum;
  const formattedSumCorrections = formatCurrency(price + corrections);

  return formattedSumCorrections;
}

function getOrderStatus(item) {
  const DEFAULT_ORDER_STATUS = { color: "none", text: item.orderStatus };
  return ORDER_STATUSES[item.orderStatus] || DEFAULT_ORDER_STATUS;
}

function getOrderStatusIcon(item) {
  const { color, text } = getOrderStatus(item);
  return `<span class="cell_status cell_status__${color}"></span>${text}`;
}

function getStartAt(item) {
  if (item.startAt) {
    return item.startAt;
  }

  return "—";
}

function getOrderEmailStatusIcon(item) {
  const DEFAULT_ORDER_EMAIL_STATUS = { color: "none", text: item.mailStatus };
  const { color, text } = ORDER_EMAIL_STATUS[item.mailStatus] || DEFAULT_ORDER_EMAIL_STATUS;
  return `<span class="cell_status cell_status__${color}"></span>${text}`;
}

function getAccountantOrderStatus({ status }) {
  return ORDER_ACCOUNTANT_STATUS[status] || "Unknown";
}

export default {
  components: {
    BaseInput,
    Button,
    DatePickerInput,
    ErrorBanner,
    NewSelectInput,
    SimpleTable,
    SpinnerBrand,
  },

  setup(props, { root }) {
    const route = useRoute(root);
    const { errors, fetch, processingMethods, data, setErrors } = useRequest({
      defaultValue: [],
      errorsFormat: "flat",
    });

    const state = reactive({
      downloadedActsDates: [],
      isDataLoaded: false,

      selectedItems: [],
      processingItems: [],

      sortingState: {
        header: "",
        direction: "asc",
      },
    });

    const searchState = reactive({
      date: route.value.query.date || null,
      manager: null,
      searchValue: "",
    });

    const country = computed(() => store.getters["auth/country"]);

    const isAccountant = computed(() => dataRole.value === USER_ROLES.accountant);
    const isManager = computed(() => store.getters["auth/isSaleManager"]);

    const isAnyItemSelected = computed(() => state.selectedItems.length === 0);

    const ordersList = computed(() => {
      const preparedData = data.value.map((item, id) => {
        const updatedItem = {
          ...camelCaseObjectKeys(item),
          id: item.order_id ?? `no_order_${id}`,
          __hideActionsButton: !isAccountant.value && !item.order_id,
          __hideDownloadButton: !item.act_id,
        };

        if (isAccountant.value) {
          updatedItem._id = `${updatedItem.id}*${id}`;
        }

        return updatedItem;
      });

      if (isAccountant.value) {
        preparedData.sort((a, b) => {
          const aValue = a[CLICKABLE_CELL_COMPANY_NAME] || "";
          const bValue = b[CLICKABLE_CELL_COMPANY_NAME] || "";
          return aValue.localeCompare(bValue);
        });
      }

      return preparedData;
    });

    const managersOptions = computed(() => {
      const managersList = store.state.config.managers.map(({ id, name }) => ({ text: name, value: id }));
      const managersOptions = [{ text: "All managers", value: null }, ...managersList];
      return managersOptions;
    });

    const filteredOrdersList = computed(() => {
      let filteredOrdersList = ordersList.value;

      const preparedSearchValue = searchState.searchValue.trim().toLowerCase();

      if (preparedSearchValue) {
        let searchFields = [];

        if (isAccountant.value) {
          searchFields = ["aggregatorName", CLICKABLE_CELL_COMPANY_NAME, "manager.name", "orderDate"];
        } else {
          searchFields = ["companyName", "manager.name"];
        }

        filteredOrdersList = filteredOrdersList.filter((item) => {
          const searchString = searchFields
            .map((field) => get(item, field))
            .join(" ")
            .toLowerCase();
          return searchString.includes(preparedSearchValue);
        });
      }

      if (searchState.manager) {
        filteredOrdersList = filteredOrdersList.filter(({ manager }) => manager?.id == searchState.manager);
      }

      return filteredOrdersList;
    });

    const sortedOrdersList = computed(() => {
      let ordersList = Array.from(filteredOrdersList.value);
      const sortingState = state.sortingState;
      const header = sortingState.header;

      if (header) {
        const propertyName = SORTABLE_TABLE_ITEM_CELLS[userRole.value][header];
        const isAscSorting = sortingState.direction === "asc";
        const invertionCompareIfDesc = isAscSorting ? 1 : -1;

        ordersList.sort((a, b) => {
          const valA = String(a[propertyName]).toLowerCase();
          const valB = String(b[propertyName]).toLowerCase();
          const compareResult = valA.localeCompare(valB, undefined, { numeric: true });

          return invertionCompareIfDesc * compareResult;
        });
      }

      return ordersList;
    });

    const tableSummarizedColumnsByUserRole = computed(() =>
      !isAccountant.value ? summarizeNecessaryColumnsValues(filteredOrdersList.value) : null
    );

    const tableData = computed(() => TABLE_DATA[dataRole.value]);

    const isTableSortable = computed(() => filteredOrdersList.value.length < 500);

    const sortableTableHeaders = computed(() => (isTableSortable.value ? SORTABLE_TABLE_HEADERS : []));

    const userRole = computed(() => (isManager.value ? USER_ROLES.manager : USER_ROLES.accountant));

    const dataRole = computed(() => route.value.query.role || userRole.value);

    const tablePrimaryKey = computed(() => (isAccountant.value ? "_id" : "id"));

    function openOrderInvoicesView(rawOrderId) {
      const orderId = String(rawOrderId).split("*")[0];

      router.push({
        name: ROUTES_NAMES.orders.invoices.list,
        params: { orderId },
      });
    }

    function openOrderActsView(rawOrderId) {
      const orderId = String(rawOrderId).split("*")[0];

      router.push({
        name: ROUTES_NAMES.orders.acts.list,
        params: { orderId },
      });
    }

    function openCompanyView(companyId) {
      router.push({
        name: "company_view",
        params: { country: country.value, company: `${companyId}` },
      });
    }

    async function getData() {
      state.isDataLoaded = false;

      const { date } = route.value.query;
      const url = URLS.orders[dataRole.value]["index"](date, state.sortingState);

      const { error } = await fetch({
        url,
      });

      if (!error) {
        state.isDataLoaded = true;
        state.selectedItems = [];
      }
    }

    function summarizeNecessaryColumnsValues(ordersList) {
      if (!ordersList.length) {
        return SUMMARIZED_COLUMNS_INITIAL_VALUES;
      }

      const result = ordersList.reduce(
        (accum, order) => {
          accum.amount += order.orderAmount;
          accum.invoice_amount += order.invoicedAmount;
          const corrections = order.orderSum?.corrections;
          const price = order.orderSum?.price;
          accum.sum += corrections + price;
          return accum;
        },
        { amount: 0, invoice_amount: 0, sum: 0 }
      );

      return {
        ["Amount"]: result.amount,
        ["Invoiced amount"]: result.invoice_amount,
        ["Sum + Corrections"]: formatCurrency(result.sum),
      };
    }

    function onCheckboxChange(newValue) {
      const selectedIds = newValue.map((item) => item.split("*")[0]);
      const uniqSelectedIds = [...new Set(selectedIds)];
      state.selectedItems = uniqSelectedIds;
    }

    function onClickableCellClick(field, item) {
      if (field === CLICKABLE_CELL_COMPANY_NAME) {
        openCompanyView(item.companyId);
      }
    }

    function getActIdForOrder(rawOrderId) {
      const order = ordersList.value.find((item) => item[tablePrimaryKey.value] === rawOrderId);
      return order.actId;
    }

    function downloadSoleAct(orderId) {
      downloadAct(orderId, ACT_TYPES.soleActAggregator);
    }

    async function downloadAct(orderId, actType = ACT_TYPES.act) {
      const originalOrderId = orderId.split("*")[0];
      const actId = getActIdForOrder(orderId);

      if (actId) {
        state.processingItems.push(orderId);

        try {
          const url = URLS.orders.acts.download(actId) + `?act_type=${actType}`;
          await download(url);
        } catch (xhrError) {
          const errors = prepareAxiosErrors(xhrError, { flatResult: true });
          setErrors(errors);
        }
      }

      state.processingItems = state.processingItems.filter((item) => !item.startsWith(`${originalOrderId}*`));
    }

    async function downloadSelectedActs() {
      const selectedOrders = filteredOrdersList.value
        .map((item) => item[tablePrimaryKey.value])
        .filter((id) => state.selectedItems.some((selectedItemId) => id.startsWith(`${selectedItemId}*`)));

      const downloadedActs = [];

      state.processingItems = [...selectedOrders];

      for (const orderId of selectedOrders) {
        const actId = getActIdForOrder(orderId);

        if (!downloadedActs.includes(actId)) {
          await downloadAct(orderId, ACT_TYPES.soleActAggregator);
          downloadedActs.push(actId);
        }
      }
    }

    async function generateActs() {
      const { error } = await fetch({
        url: URLS.orders.accountant.generateActs,
        method: "POST",
        data: {
          order_ids: state.selectedItems,
        },
        omitData: true,
      });

      if (!error) {
        await getData();
      }
    }

    async function reviewActs() {
      const { error } = await fetch({
        url: URLS.orders.accountant.reviewOrders,
        method: "PUT",
        data: {
          order_ids: state.selectedItems,
        },
        omitData: true,
      });

      if (!error) {
        await getData();
      }
    }

    async function sendEmails() {
      const { error } = await fetch({
        url: URLS.orders.accountant.sendEmails,
        method: "POST",
        data: {
          order_ids: state.selectedItems,
        },
        omitData: true,
      });

      if (!error) {
        store.dispatch(MODAL_ACTION_OPEN, {
          type: MODAL_TYPES.message,
          payload: {
            message: "All emails were successfully accepted for sending.",
          },
        });
      }
    }

    function setInitialDate(initialDate) {
      const currentDate = getCurrentDate();
      searchState.date = initialDate || currentDate;
    }

    async function onRouteUpdate() {
      const { manager: queryManager, ...routeQueryParams } = route.value.query;
      const routeDate = routeQueryParams.date;

      if (!routeDate || !isDateValid(routeDate)) {
        const initialDate = searchState.date;
        return setInitialDate(initialDate);
      }

      if (queryManager) {
        if (isAccountant.value) {
          searchState.manager = null;
          router.push({ query: routeQueryParams });
        } else {
          searchState.manager = queryManager;
        }
      }

      if (store.state.config.managers.length === 0) {
        await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, ["managers"]);
      }

      await getData();
    }

    function onSearchDateChange(newDate) {
      if (newDate !== route.value.query.date) {
        router.push({ query: { ...route.value.query, date: newDate } });
      } else {
        getData();
      }
    }

    function onManagerChange(managerId) {
      const { manager, ...routeQueryParams } = route.value.query;

      if (managerId) {
        routeQueryParams.manager = managerId;
      }

      router.push({ query: routeQueryParams });
    }

    function onSortHeader(header) {
      let direction = "asc";

      if (state.sortingState.header === header && state.sortingState.direction === "asc") {
        direction = "desc";
      }

      state.sortingState = {
        header: header,
        direction: direction,
      };
    }

    function resetSorting() {
      checkSortability();

      state.sortingState = {
        header: null,
        direction: "asc",
      };
    }

    function checkSortability() {
      if (!isTableSortable.value && filteredOrdersList.value.length > 0) {
        alert("Сортировка отключена в целях сохранения производительности");
      }
    }

    function getNextMonth() {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() + 1);
      return date.toJSON().substring(0, 7);
    }
    function addCancelOrderButton() {
      const action = {
        title: "Cancel order",
        key: "open-cancel-order-modal",
      };
      const index = TABLE_DATA[USER_ROLES.manager].actions.findIndex((e) => e.title === action.title);

      if (getNextMonth() === searchState.date) {
        if (index < 0) {
          TABLE_DATA[USER_ROLES.manager].actions.push(action);
        }
      } else if (index > -1) {
        TABLE_DATA[USER_ROLES.manager].actions.splice(index, 1);
      }
    }

    async function cancelOrder(orderId) {
      const url = URLS.orders.manager.cancelOrder(orderId);
      try {
        const result = await fetch({
          url,
          method: "POST",
        });
        await getData();
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }
    }

    function openCancelOrderModal(orderId) {
      const { orderStatus, companyName } = ordersList.value.find((e) => e.id === orderId);
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.orders.cancelOrder,
        payload: {
          orderId,
          orderStatus,
          companyName,
          onCancel: cancelOrder,
        },
      });
    }

    watch(
      () => searchState.date,
      () => {
        onSearchDateChange(searchState.date);
        addCancelOrderButton();
      }
    );
    watch(() => route.value.query.role, onRouteUpdate);
    watch(() => route.value.query.date, onRouteUpdate, { flush: "post", immediate: true });
    watch(
      () => filteredOrdersList.value.length,
      () => resetSorting()
    );

    onBeforeMount(async () => {
      if (dataRole.value === USER_ROLES.manager) {
        await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, ["managers"]);

        const { manager } = route.value.query;

        if (manager) {
          if (store.state.config.managers.find(({ id }) => manager === id)) {
            searchState.manager = manager;
          } else {
            searchState.manager = null;
            onManagerChange();
          }
        }
      }
    });

    onMounted(() => {
      addCancelOrderButton();
    });
    return {
      SORTABLE_TABLE_HEADERS,

      errors,

      state,
      searchState,

      processingMethods,
      isAccountant,
      tableSummarizedColumnsByUserRole,
      isManager,
      isAnyItemSelected,
      managersOptions,
      tableData,
      sortedOrdersList,
      sortableTableHeaders,
      userRole,
      tablePrimaryKey,

      checkSortability,
      downloadAct,
      downloadSelectedActs,
      downloadSoleAct,
      generateActs,
      onCheckboxChange,
      onClickableCellClick,
      openOrderActsView,
      openOrderInvoicesView,
      reviewActs,
      sendEmails,
      onManagerChange,
      onSortHeader,
      openCancelOrderModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  .datepicker,
  .select_manager {
    width: 178px;
  }

  .search {
    display: flex;
    gap: 24px;

    &_input {
      width: 278px;
    }
  }

  .buttons {
    display: flex;
    margin-top: 6px;
    margin-bottom: 30px;
    gap: 24px;

    &_button {
      width: 278px;
    }
  }
}

.switch-role-link {
  display: inline-block;
  margin-bottom: 2em;
}
</style>
