import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";

import { initSentry } from "@/helpers/dev/sentry";

import store from "./store";
import router from "./router";
import App from "./App.vue";
import SmallLayout from "./components/layouts/SmallLayout";
import axios from "axios";
import {
  AxiosInterceptorOnFullFilled,
  AxiosInterceptorOnRejected,
} from "./middleware/axios_interceptors";
import VueI18n from "vue-i18n";
import i18n from "./i18n";

import capitalizeFilter from "@/filters/capitalize";
import ProlongatingTypeLabel from "@/filters/prolongatingTypeLabel";

import clickOutsideDirective from "./directives/clickOutside";
import scrollTodirective from "./directives/scrollTo";
import clipboardDirective from "./directives/clipboard";

import "@/assets/styles/normalize.css";

import wysiwyg from "vue-wysiwyg";
import {
  MODAL_ACTION_CLOSE,
  MODAL_GETTER_IS_OPEN,
} from "./store/modules/modal";
Vue.use(wysiwyg, {}); // config is optional. more below

store.commit("auth/INIT_AUTH");

router.beforeEach((to, from, next) => {
  if (!store.getters["auth/loggedin"]) {
    if (
      ["/login", "/passport/forgot-password", "/sign/up"].indexOf(to.path) ===
        -1 &&
      String(to.path).indexOf("/passport/reset-password") === -1
    ) {
      next({ name: "login" });
    }
  }

  if (store.getters[MODAL_GETTER_IS_OPEN]) {
    store.dispatch(MODAL_ACTION_CLOSE);
  }

  next();
});

Vue.use(VueCompositionAPI);
Vue.use(VueI18n);

Vue.component("small-layout", SmallLayout);

Vue.filter(capitalizeFilter.name, capitalizeFilter.filter);
Vue.filter(ProlongatingTypeLabel.name, ProlongatingTypeLabel.filter);

Vue.directive("click-outside", clickOutsideDirective);
Vue.directive("scroll-to", scrollTodirective);
Vue.directive("clipboard", clipboardDirective);

axios.interceptors.response.use(
  AxiosInterceptorOnFullFilled,
  AxiosInterceptorOnRejected
);

initSentry(Vue, router);

const main = new Vue(
  Vue.util.extend(
    {
      router,
      store,
      i18n,
    },
    App
  )
).$mount("#app");
