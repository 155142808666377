/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_supplier from "../../api/supplier";

// initial state
// shape: [{ supplier }]
const state = {
  supplier_list: [],
};

// getters
const getters = {
  supplier_list(state) {
    return state.supplier_list;
  },
};

// actions
const actions = {
  async response_qrcode_supplier({ commit }, { id, format }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await api_supplier.supplier_image(id, format);
      return response;
    } catch (error) {
      commit("error/set_error", error, { root: true });
    }
  },
  /**
   * @deprecated load_supplier_list should be rewrited
   * @param commit
   * @param params
   * @returns {Promise<*>}
   */
  async load_supplier_list_printable({ commit }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_supplier.supplier_list(params);
    } catch (error) {
      commit("error/set_error", error, { root: true });
    }
  },
  async load_supplier_list({ commit }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      const supplier_list = await api_supplier.supplier_list(params);
      commit("supplier_list", supplier_list);
      return supplier_list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      if (error.response && error.response.data) {
        return error.response.data;
      }
    }
  },
  async delete_supplier({ commit }, supplier) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_supplier.supplier_delete(supplier.id);
      commit("supplier_delete", supplier);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async get_supplier({ commit }, id) {
    commit("error/clear", undefined, { root: true });
    const supplier = await api_supplier.supplier_get(id);
    return supplier;
  },
  async update_supplier({ commit }, { id, data }) {
    commit("error/clear", undefined, { root: true });
    const supplier = await api_supplier.supplier_update(id, data);
    return supplier;
  },
  async clone_supplier({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const supplier = await api_supplier.supplier_clone(data);
      commit("supplier_append", data);
      return supplier;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  supplier_list(state, list) {
    state.supplier_list = list;
  },
  supplier_delete(state, supplier) {
    state.supplier_list.splice(state.supplier_list.indexOf(supplier), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
